import React from 'react';
import { Box, Text, Heading, Accordion, AccordionPanel, ThemeContext } from 'grommet-v2';
import PropTypes from 'prop-types';
import { FormNext } from 'grommet-icons';

const propTypes = {
    about_data: PropTypes.string.isRequired,
};

const defaultProps = {
    about_data: '',
};

const resizeWidth = window.innerWidth || document.body.clientWidth
// const resizeHeight = window.innerHeight || document.body.clientHeight;
var resizer;
if (resizeWidth > 1000) {
    resizer = "200px";
} else {
    resizer = "0px";
}


export default function WWWH({ about_data }) {
    return (
        <React.Fragment>
            <Box pad={{ horizontal: resizer }} margin={{ top: "25px", bottom: "25px" }} background="light-1">
                <Box border={{ color: "rgba(204, 153, 102, 0.2)", size: "5px" }} margin="10px 0px">
                    {
                        about_data.map((item, index) => (
                            <Box key={index} background="light-1" round="05px" margin="20px" elevation="medium">
                                <ThemeContext.Extend
                                    value={{ global: { colors: { brand: '#c96', focus: '#c96' } } }}
                                >
                                    <Accordion>
                                        <AccordionPanel background="light-2" label={
                                            <Heading level={2} size="medium" margin="small">
                                                {item.highlighter}&nbsp;
                                            <Text size="20px" weight={500} style={{ letterSpacing: "-0.03em" }}>
                                                    {item.question}
                                                </Text>
                                            </Heading>
                                        }
                                        >
                                            <Box background="white">
                                                <Text size="18px" wordBreak="keep-all" margin="xsmall">{item.description}</Text>
                                                {
                                                    item.answer_in_steps && item.answer_in_steps.length > 0 && item.answer_in_steps.map((item) => (
                                                        <Text size="17px" wordBreak="keep-all" margin="xsmall" weight={390}><FormNext color="#c96" /> &nbsp; {item}</Text>
                                                    ))
                                                }
                                                {
                                                    item.advantages && item.advantages.length > 0 && item.advantages.map((item) => (
                                                        <Box direction="column" background="white" elevation="small">
                                                            <Text className="underline" size="18px" margin="xsmall" weight={600}><FormNext alignSelf="baseline" size="22px" color="#c96" /> {item.info}</Text>
                                                            <Box elevation="small" margin="small" pad="xsmall">
                                                                <Text size="16px" wordBreak="keep-all" margin="xsmall">{item.details}</Text>
                                                            </Box>
                                                        </Box>
                                                    ))
                                                }
                                                {
                                                    item.examples && item.examples.length > 0 && item.examples.map((item) => (
                                                        <Box direction="column" background="white" pad={{ bottom: "3px", left: "5px" }} elevation="small" margin="small">
                                                            <Box margin={{ left: "50px" }}>
                                                                <Text size="17px" wordBreak="keep-all" margin="xsmall">{item}</Text>
                                                            </Box>
                                                        </Box>
                                                    ))
                                                }
                                            </Box>
                                        </AccordionPanel>
                                    </Accordion>
                                </ThemeContext.Extend>
                            </Box>
                        ))}
                </Box>
            </Box>
        </React.Fragment>
    );
}


WWWH.propTypes = propTypes;
WWWH.defaultProps = defaultProps;