import React from 'react';
import { Tab, Tabs, TabPanel, TabList } from 'react-tabs';
import Link from 'react-router-dom/Link';
import { Box } from 'grommet-v2';
import { FormNext } from 'grommet-icons';
import PageHeader from './components/1_Header';
import WWWH from './components/0_WWWHAccordian';
import Abbreviation from './components/0_Abbreviations';

import StockTypes from './components/1.0_StockTypes';
import TradingTypes from './components/1.1_StockTradingTypes';
import OrderTypes from './components/1.2_StockOrderTypes';
import TraderTypes from './components/1.3_StockTradersTypes';
import BrokerTypes from './components/1.4_StockBrokerTypes';

import HowDoesItWork from './components/0_HowDoesItWork';
import { stocks_about, stocks_abbreviation, stocks_basic_glossary, stocks_tradingtypes, stocks_ordertypes, stocks_brokertypes, stocks_types, stocks_working, stocks_tradertypes, stocks_adv_glossary } from '../../../mock_data/data.jsx';
import { scrollToElement } from '../../../utils/utils';

const switch_panel = ["About", "How does it work?", "Types N Classifications", "Abbreviations", "Basic Glossary", "Advanced Glossary"];
const resizeWidth = window.innerWidth || document.body.clientWidth;
var tabStyle, gapValue, bigScreen;
if (resizeWidth > 1000) {
    bigScreen = true;
    tabStyle = "menu";
    gapValue = "none"
} else {
    bigScreen = false;
    tabStyle = "mobilemenu";
    gapValue = "medium"
}

const content = (bigScreen === true ? <ul className={tabStyle}>
    <li>
        <Link to="#" data-target=".section-stocktypes" onClick={scrollToElement}>Types of Stocks</Link>
    </li>
    <li>
        <Link to="#" data-target=".section-ordertypes" onClick={scrollToElement}>Types of Stock Orders</Link>
    </li>
    <li>
        <Link to="#" data-target=".section-tradingtypes" onClick={scrollToElement}>Types of Stock Trading</Link>
    </li>
    <li>
        <Link to="#" data-target=".section-tradertypes" onClick={scrollToElement}>Types of Stock Traders</Link>
    </li>
    <li>
        <Link to="#" data-target=".section-brokertypes" onClick={scrollToElement}>Types of Stock Brokers</Link>
    </li>
</ul> :
    <ul className={tabStyle}>
        <Box direction="column" gap="large" pad="small" border={{ color: "rgba(204, 153, 102, 0.2)", size: "5px" }} margin="10px 0px">
            <li>
                <Link to="#" data-target=".section-stocktypes" onClick={scrollToElement}><FormNext size="medium" color="#c96" />Types of Stocks</Link>
            </li>
            <li>
                <Link to="#" data-target=".section-ordertypes" onClick={scrollToElement}><FormNext size="medium" color="#c96" />Types of Stock Orders</Link>
            </li>
            <li>
                <Link to="#" data-target=".section-tradingtypes" onClick={scrollToElement}><FormNext size="medium" color="#c96" />Types of Stock Trading</Link>
            </li>
            <li>
                <Link to="#" data-target=".section-tradertypes" onClick={scrollToElement}><FormNext size="medium" color="#c96" />Types of Stock Traders</Link>
            </li>
            <li>
                <Link to="#" data-target=".section-brokertypes" onClick={scrollToElement}><FormNext size="medium" color="#c96" />Types of Stock Brokers</Link>
            </li>
        </Box>
    </ul>
);


export default function MultiTab_Stocks() {
    return (
        <React.Fragment>
            <Box direction="column" gap="large">
                <PageHeader title="Stocks" subTitle="a financial instrument" />
                <Tabs selectedTabClassName="show">
                    <TabList className="nav nav-pills nav-border-anim nav-big justify-content-center mb-3">
                        {
                            switch_panel.length > 0 && switch_panel.map((item) =>
                                <Tab className="nav-item">
                                    <span className="nav-link">{item}</span>
                                </Tab>
                            )
                        }
                    </TabList>
                    <TabPanel>
                        <WWWH
                            about_data={stocks_about} />
                    </TabPanel>
                    <TabPanel>
                        <HowDoesItWork
                            working_data={stocks_working} 
                            image="assets/images/demos/demo-15/assets/stock_market.png"/>
                    </TabPanel>
                    <TabPanel>
                        <Box align="center" justify="center">
                            {content}
                        </Box>
                        {/* </Box> */}
                        <Box background="light-1">
                            <section className="section-stocktypes">
                                <StockTypes
                                    stocks_types_data={stocks_types} />
                            </section>
                        </Box>
                        <Box background="light-1">
                            <section className="section-ordertypes">
                                <OrderTypes
                                    stocks_types_data={stocks_ordertypes} />
                            </section>
                        </Box>
                        <Box background="light-1">
                            <section className="section-tradingtypes">
                                <TradingTypes
                                    stocks_types_data={stocks_tradingtypes} />
                            </section>
                        </Box>

                        {/* pending */}
                        <Box background="light-1">
                            <section className="section-tradertypes">
                                <TraderTypes
                                    stocks_types_data={stocks_tradertypes} />
                            </section>
                        </Box>
                        <Box background="light-1">
                            <section className="section-brokertypes">
                                <BrokerTypes
                                    stocks_types_data={stocks_brokertypes} />
                            </section>
                        </Box>
                    </TabPanel>
                    <TabPanel>
                        <Abbreviation
                            stocks_abbreviation_data={stocks_abbreviation} />
                    </TabPanel>
                    <TabPanel>
                        <Abbreviation
                            stocks_abbreviation_data={stocks_basic_glossary} />
                    </TabPanel>
                    <TabPanel>
                        <Abbreviation
                            stocks_abbreviation_data={stocks_adv_glossary} />
                    </TabPanel>
                </Tabs>
            </Box>
        </React.Fragment>
    )
}