import React, { useState, useRef } from 'react';
import { Avatar, RadioButton, Grid, Select, Button, Grommet, Header, Heading, Box, Menu, Text, Form, FormField, TextInput, grommet } from 'grommet-v2';
import { CodeSandbox, Grommet as GrommetIcon, Menu as MenuIcon } from 'grommet-icons';
import MainTable from './MainTable';
import PageHeader from '../../common/page-header';


// 7 Arrays
// 1. years,
// 2. obalance,
// 3. cbalance,
// 4. amount,
// 5. interest,
// ............Options
// 6. loan ,
// 7. withdraw,

const resizeWidth = window.innerWidth || document.body.clientWidth
var directionDisplay;
if (resizeWidth > 1000) {
    directionDisplay = "row";
} else {
    directionDisplay = "column";
}

var chart_data = [];
export default function PPFCalculator({headerdisplay}) {
    const SerialArray = [];
    const OpeningBalance = [];
    const AmountDeposited = [];
    const InterestEarned = [];
    const ClosingBalance = [];
    const Loanlimit = [];
    const WithdrawalLimit = [];
    const DATA = [];
    // Creating an Array
    const ref = useRef(null);
    const [tenure, setTenure] = useState(15);
    const [frequency, setFrequency] = useState('Monthly');
    const [amount, setAmount] = useState(0);
    const [interest, setInterest] = useState('7.1');
    console.log("test <>", ClosingBalance[ClosingBalance.length - 1]);

    const columns = [
        { property: 'SerialArray', header: 'Years', align: 'center', border: true, footer: 'Total' },
        { property: 'OpeningBalance', header: 'Opening Balance', align: 'center' },
        { property: 'AmountDeposited', header: 'Amount Deposited', align: 'center', aggregate: 'sum', footer: { aggregate: true } },
        { property: 'InterestEarned', header: 'Interest Earned', align: 'center', aggregate: 'sum', footer: { aggregate: true } },
        { property: 'ClosingBalance', header: 'Closing Balance', align: 'center', aggregate: '', footer: { aggregate: true } },
        // { property: 'loan', header: 'Loan Limit', align: 'center' },
        // { property: 'withdraw', header: 'Withdrawal Limit', align: 'center' },
    ]

    const CalculateData = () => {
        // Code Clean Up
        while (DATA.length > 0) {
            DATA.pop();
            SerialArray.pop();
            OpeningBalance.pop();
            AmountDeposited.pop();
            InterestEarned.pop();
            ClosingBalance.pop();
        }
        const currentIntRate = Number(interest);
        // const SerialArray = []; // change to array (so that we can store multiple numbers - not just one multiple)
        for (var i = 1; i <= tenure; i++) {
            let int_amount = Number(amount);
            OpeningBalance[0] = 0;
            //   if (numbers[i] % number === 0) { // divide by the number
            SerialArray.push(i); // add the current multiple found to the multiples array
            if (OpeningBalance[i - 1] === 0) {
                AmountDeposited.push(Math.round(int_amount));
                // OpeningBalance.push(0);
                var value = (AmountDeposited[i - 1]) + (OpeningBalance[i - 1]);
                InterestEarned.push(Math.round(value * (currentIntRate / 100)));
                var CB = (AmountDeposited[i - 1]) + (InterestEarned[i - 1]);
                ClosingBalance.push(Math.round(CB));
            }
            else {
                AmountDeposited.push(int_amount);
                // var value = (AmountDeposited[i-1] )+ (InterestEarned[i- 2])
                var OB = (AmountDeposited[i - 2]) + (InterestEarned[i - 2]) + (OpeningBalance[i - 2]);
                OpeningBalance.push(Math.round(OB));
                var int = (AmountDeposited[i - 1]) + (OpeningBalance[i - 1]);
                InterestEarned.push(Math.round(int * (currentIntRate / 100)));
                CB = (AmountDeposited[i - 1]) + (InterestEarned[i - 1]) + (OpeningBalance[i - 1]);
                ClosingBalance.push(Math.round(CB));
            }
        }
        for (let i = 0; i < tenure; i++) {
            DATA.push({
                SerialArray: SerialArray[i],
                OpeningBalance: OpeningBalance[i],
                AmountDeposited: AmountDeposited[i],
                InterestEarned: InterestEarned[i],
                ClosingBalance: ClosingBalance[i]
            });
        }

        let totalInterestEarned = 0;
        let totalClosingBalance = 0;
        let totalAmountDeposited = 0;

        DATA.forEach((data) => {
            totalInterestEarned += data["InterestEarned"];
            totalAmountDeposited += data["AmountDeposited"];
        });

        totalClosingBalance = DATA[DATA.length - 1]["ClosingBalance"];
        ref.current.refreshData(DATA, columns);

        chart_data.push(totalAmountDeposited);
        chart_data.push(totalInterestEarned);
        chart_data.push(totalClosingBalance);

    }

    return (
        <React.Fragment>
            {
                headerdisplay === false ? null :
                    (<PageHeader title="Public Provident Fund Calculator" subTitle="Calculate your future investments" />)
            }

            <Box align="center" responsive pad={{ horizontal: "200px" }} gap="large" pad="xlarge">
                <Box align="center" responsive background="light-1" pad="small" margin={{ bottom: "15px" }}>
                    <Text size="18px" weight="normal" color="#c96" s>PPF Current Interest Rate is 7.1%</Text>
                </Box>

                <Form gridArea="calc">
                    <Box gap="medium" direction={directionDisplay}>
                        <FormField name="one" label="Tenure in Years" background="white" color="white">
                            <Select
                                options={[15, 20, 25, 30]}
                                value={tenure}
                                onChange={({ option }) => setTenure(option)}
                            />
                        </FormField>
                        <FormField htmlFor="select" name="three" label="Investment Amount" background="white" color="white">
                            <TextInput
                                id="amount"
                                name="amount"
                                type="number"
                                onChange={e => setAmount(e.target.value)}
                                placeholder="Amount to be deposited"
                            />
                        </FormField>
                        <FormField htmlFor="interest" name="four" label="Current Interest Rate" background="white" color="white">
                            <TextInput
                                id="interest"
                                name="interest"
                                type="number"
                                placeholder="Current Interest Rate"
                                value={interest}
                                onChange={e => setInterest(e.target.value)}
                            />
                        </FormField>
                    </Box>
                    <Box direction="row" align="center" justify="center" gap="medium" margin={{ top: 'medium', bottom: 'small' }}>
                        <Button color="#c96" label="Calculate" style={{ color: '#fff' }} primary size="large" onClick={() => CalculateData(tenure, frequency, amount, interest)} />
                        <Button color="#c96" label="Clear" type="reset" size="large" />
                    </Box>
                </Form>
                <Box gridArea="chart" background="light-2" responsive grow="flex" border />
                {/* </Grid> */}
            </Box>
            <MainTable
                ref={ref}
                passed_data={chart_data}
            />
        </React.Fragment>
    );
}

