import React from 'react';
import { Box, Grid, RangeInput, TextInput, Heading, Text, Meter, Stack } from 'grommet-v2';
import PageHeader from '../../../common/page-header';
import convertNumberToWords from '../calculators/0_To_Words';
import { formatter } from './0_fomatter';

export default function NPS_Calculator({ headerdisplay }) { // done
    const [investmentPerMonth, setinvestmentPerMonth] = React.useState(5000);
    const [expectedReturns, setexpectedReturns] = React.useState(12);
    const [ageValue, setageValue] = React.useState(26);
    const [active, setActive] = React.useState(0);
    const [label, setLabel] = React.useState('');

    if (investmentPerMonth > 500) {
        var finalTotalInvestment = Math.round(investmentPerMonth * 12 * (60 - ageValue) / 100) * 100;
        var finalexpectedReturns = parseInt(Math.round(investmentPerMonth * (Math.pow((1 + expectedReturns / 12 / 100), (60 - ageValue) * 12) - 1) / (expectedReturns / 12 / 100) * (1 + expectedReturns / 12 / 100) / 100) * 100 - Math.round(investmentPerMonth * 12 * (60 - ageValue) / 100) * 100);
        var finalMaturityAmount = Math.round((investmentPerMonth * (Math.pow((1 + expectedReturns / 12 / 100), (60 - ageValue) * 12) - 1) / (expectedReturns / 12 / 100) * (1 + expectedReturns / 12 / 100)) * 0.6 / 100) * 100;
        var finalAnnuityAmount = Math.round((investmentPerMonth * (Math.pow((1 + expectedReturns / 12 / 100), (60 - ageValue) * 12) - 1) / (expectedReturns / 12 / 100) * (1 + expectedReturns / 12 / 100)) * 0.4 / 100) * 100;
    }
    // resizer
    const resizeWidth = window.innerWidth || document.body.clientWidth;
    var resizer, headingWidth, bigScreen, textSize;
    if (resizeWidth > 1000) {
        resizer = "15%";
        headingWidth = "500px"
        bigScreen = true;
        textSize = "20px";
    } else {
        resizer = "0px";
        bigScreen = false;
        headingWidth = "600px";
        textSize = "16px";
    }
    const grid1 = (<Box pad="small" background="light-1" elevation="small">
        <Box pad="large" gap="xlarge">

            <Box direction="column" align="baseline" gap="medium">
                <Box direction="row" align="baseline" gap="small" >
                    <Heading style={{ width: headingWidth }} level={5}> Investment Per Month </Heading>
                    <TextInput
                        size="small"
                        placeholder="type here"
                        value={investmentPerMonth}
                        onChange={event => setinvestmentPerMonth(event.target.value)}
                    />
                </Box>
                <RangeInput
                    min={501}
                    max={150000}
                    step={1}
                    value={investmentPerMonth}
                    onChange={event => setinvestmentPerMonth(event.target.value)}
                />
            </Box>

            <Box direction="column" align="baseline" gap="medium">
                <Box direction="row" align="baseline" gap="small">
                    <Heading style={{ width: headingWidth }} level={5}> Expected Returns in % </Heading>
                    <TextInput
                        size="xsmall"
                        placeholder="type here"
                        value={expectedReturns}
                        onChange={event => setexpectedReturns(event.target.value)}
                    />
                </Box>
                <RangeInput
                    min={1}
                    max={15}
                    step={0.5}
                    value={expectedReturns}
                    onChange={event => setexpectedReturns(event.target.value)}
                />
            </Box>

            <Box direction="column" align="baseline" gap="medium">
                <Box direction="row" align="baseline" gap="small">
                    <Heading style={{ width: headingWidth }} level={5}> Your Current Age</Heading>
                    <TextInput
                        size="xsmall"
                        placeholder="type here"
                        value={ageValue}
                        onChange={event => setageValue(event.target.value)}
                    />
                </Box>
                <RangeInput
                    min={1}
                    max={60}
                    step={1}
                    value={ageValue}
                    onChange={event => setageValue(event.target.value)}
                />
            </Box>

        </Box>
    </Box>)

    const grid2 = (
        <Box background="light-1" elevation="small">
            {
                (finalMaturityAmount >= 500) ? (
                    <Box background="light-1" margin="small" align="center" justify="center" gap="medium" pad="medium">
                        <Stack anchor="center">
                            <Meter
                                values={[{
                                    value: finalexpectedReturns,
                                    label: 'finalexpectedReturns',
                                    "color": "dark-3",
                                    onHover: over => {
                                        setActive(over ? formatter(finalexpectedReturns) : 0);
                                        setLabel(over ? 'Interest Earned' : undefined);
                                    },
                                },
                                {
                                    value: finalTotalInvestment,
                                    label: 'finalTotalInvestment',
                                    "color": "#c96",
                                    onHover: over => {
                                        setActive(over ? formatter(finalTotalInvestment) : 0);
                                        setLabel(over ? 'Invested' : undefined);
                                    },
                                }]}
                                type="circle"
                                aria-label="meter"
                                size="250px"
                                thickness="60px"
                            />
                            <Box align="center">
                                <Box direction="row" pad={{ bottom: 'xsmall' }}>
                                    <Text size="16px" weight={500} alignSelf="center" style={{ textAlign: "center" }}>
                                        {label || undefined} <br />
                                        {active || undefined}
                                    </Text>
                                </Box>
                            </Box>
                        </Stack>
                        {/* Legends */}
                        <Box gap="medium" direction="row" margin={{ top: "15px" }}>
                            <Box direction="row" justify="center" align="baseline" gap="small">
                                <Box pad={{ top: "5px", bottom: "5px", left: "5px", right: "5px" }} background="dark-3" width="01px"></Box>
                                <Heading level={5}>Total Interest</Heading>
                            </Box>
                            <Box direction="row" justify="center" align="baseline" gap="small">
                                <Box pad={{ top: "5px", bottom: "5px", left: "5px", right: "5px" }} background="#c96" width="01px"></Box>
                                <Heading level={5}>Total Investment</Heading>
                            </Box>
                        </Box>
                        <Text size="24px " weight={550}>Here's your data !</Text>
                        <Text color="#c96" weight={550} size={textSize} weight={550} style={{ textAlign: "center" }}>Total Investment - {formatter(finalTotalInvestment)} </Text>
                        <Text color="#c96" weight={550} size={textSize} weight={550} style={{ textAlign: "center" }}>Interest  Earned - {formatter(finalexpectedReturns)} </Text>
                        <Text color="#c96" weight={550} size={textSize} weight={550} style={{ textAlign: "center" }}>Maturity Vaue - {formatter(finalMaturityAmount)} </Text>
                        <Box background="white" align="center" justify="center" elevation="small" margin="xsmall" gap="small" pad="small">
                            <Text size="20px" weight={550} alignSelf="center" style={{ textAlign: "center" }}>
                                Annuity Investment - {formatter(finalAnnuityAmount)} <br />
                            </Text>
                            <Text size="16px" weight={550} alignSelf="center" style={{ textAlign: "center" }}>
                                {convertNumberToWords(finalAnnuityAmount)}
                            </Text>
                        </Box>
                    </Box>
                ) :
                    (
                        null
                    )
            }
        </Box>)
    return (
        <React.Fragment>
            {
                headerdisplay === false ? null :
                    (<PageHeader title="National Pension Scheme Calculator" subTitle="Calculate your future investments" />)
            }
            <Box pad={{ horizontal: resizer }} margin={{ top: "25px", bottom: "25px" }} align="center" justify="center" margin="large">
                {bigScreen === true ?
                    <Grid
                        columns={{
                            count: 2,
                            size: 'auto',
                        }}
                        gap="large"
                    >
                        {grid1}
                        {grid2}
                    </Grid>
                    :
                    < Grid
                        rows={{
                            count: 2,
                            size: 'auto',
                        }}
                        gap="medium"
                    >
                        {grid1}
                        {grid2}
                    </Grid>
                }
            </Box >
        </React.Fragment>
    );
}