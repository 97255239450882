import React from 'react';
import { Tab, Tabs, TabPanel, TabList } from 'react-tabs';
import { Box } from 'grommet-v2';
import PageHeader from '../../common/page-header';
import WWWH from './components/0_WWWHAccordian';
import { mutualfunds_about, mf_featfact, mutualfunds_categories_1, mutualfunds_categories_2 } from '../../../mock_data/data.jsx';
import MF_Calculator from './calculators/MF_Calculator';
import FF from './components/0_FeaturesFactors';

const switch_panel = ["About", "features & Factors", "Calculator"];

export default function MultiTab_MF() {
    return (
        <React.Fragment>
            <Box direction="column" gap="large">
                <PageHeader title="Mutual Funds" subTitle="a good investment" />
                <Tabs selectedTabClassName="show">
                    <TabList className="nav nav-pills nav-border-anim nav-big justify-content-center mb-3">
                        {
                            switch_panel.length > 0 && switch_panel.map((item) =>
                                <Tab className="nav-item">
                                    <span className="nav-link">{item}</span>
                                </Tab>
                            )
                        }
                    </TabList>
                    <TabPanel>
                        <WWWH
                            about_data={mutualfunds_about} />
                    </TabPanel>
                    <TabPanel>
                        <FF featfact_data={mf_featfact}
                            feat_name="Features and Benefits of Mutual Funds"
                            fact_name="Factors to consider before buying Mutual Funds" />
                    </TabPanel>
                    <TabPanel>
                        <MF_Calculator headerdisplay={false} />
                    </TabPanel>
                </Tabs>
            </Box>
        </React.Fragment>
    )
}