import React from 'react';
import { Tab, Tabs, TabPanel, TabList } from 'react-tabs';
import { Box } from 'grommet-v2';
import PageHeader from '../../common/page-header';
import WWWH from './components/0_WWWHAccordian';
import { tfd_about, tfd_FeatFact, tfd_types } from '../../../mock_data/data.jsx';
import FD_Calc from './calculators/FD_Calculator';
import FF from './components/0_FeaturesFactors';
import Types from './components/0_Types';

const switch_panel = ["About", "Features & Factors", "Types", "Calculate"];

export default function MultiTab_TFD() {
    return (
        <React.Fragment>
            <Box direction="column" gap="large">
                <PageHeader title="Fixed Deposits" subTitle="a Government Scheme" />
                <Tabs selectedTabClassName="show">
                    <TabList className="nav nav-pills nav-border-anim nav-big justify-content-center mb-3">
                        {
                            switch_panel.length > 0 && switch_panel.map((item) =>
                                <Tab className="nav-item">
                                    <span className="nav-link">{item}</span>
                                </Tab>
                            )
                        }
                    </TabList>
                    <TabPanel>
                        <WWWH
                            about_data={tfd_about} />
                    </TabPanel>
                    <TabPanel>
                        <FF featfact_data={tfd_FeatFact}
                            feat_name="Features and Benefits of Fixed Deposits"
                            fact_name="Factors to consider before buying Fixed Deposits" />
                    </TabPanel>
                    <TabPanel>
                        <Types types_data={tfd_types}
                            name="Fixed Deposits" />
                    </TabPanel>
                    <TabPanel>
                         <FD_Calc headerdisplay={false} />
                    </TabPanel>
                </Tabs>
            </Box>
        </React.Fragment>
    )
}