import React from 'react';
import { Box, Grid, RangeInput, TextInput, Heading, Text, Meter, Stack } from 'grommet-v2';
import PageHeader from '../../../common/page-header';
import convertNumberToWords from '../calculators/0_To_Words';
import { formatter } from './0_fomatter';

export default function FD_Calculator({ headerdisplay }) { // done
    const [investvalue, setinvestvalue] = React.useState(100000);
    const [interest, setinterest] = React.useState(7);
    const [time, settime] = React.useState(2);
    const [active, setActive] = React.useState(0);
    const [label, setLabel] = React.useState('');

    var monthlyIncome, totalInterest, initialInvest, maturityValue, totalValue;
    if (investvalue > 0) {
        monthlyIncome = (investvalue * ((interest) / 100)) / 12;
        totalInterest = monthlyIncome * (time * 12);
        initialInvest = Number(investvalue);
        maturityValue = parseInt(totalInterest) + parseInt(investvalue);
        totalValue = (totalInterest + initialInvest);
    }
    // resizer
    const resizeWidth = window.innerWidth || document.body.clientWidth;
    var resizer, headingWidth, bigScreen, textSize;
    if (resizeWidth > 1000) {
        resizer = "15%";
        headingWidth = "500px"
        bigScreen = true;
        textSize = "20px";
    } else {
        resizer = "0px";
        bigScreen = false;
        headingWidth = "600px";
        textSize = "16px";
    }

    const grid1 = (<Box pad="small" background="light-1" elevation="small">
        <Box pad="large" gap="xlarge">

            <Box direction="column" align="baseline" gap="medium">
                <Box direction="row" align="baseline" gap="small" >
                    <Heading style={{ width: headingWidth }} level={5}> Total Investment </Heading>
                    <TextInput
                        size="small"
                        placeholder="type here"
                        value={investvalue}
                        onChange={event => setinvestvalue(event.target.value)}
                    />
                </Box>
                <RangeInput
                    min={500}
                    max={1000000}
                    step={1}
                    value={investvalue}
                    onChange={event => setinvestvalue(event.target.value)}
                />
            </Box>

            <Box direction="column" align="baseline" gap="medium">
                <Box direction="row" align="baseline" gap="small">
                    <Heading style={{ width: headingWidth }} level={5}> Rate of Interest (P.A) </Heading>
                    <TextInput
                        size="xsmall"
                        placeholder="type here"
                        value={interest}
                        onChange={event => setinterest(event.target.value)}
                    />
                </Box>
                <RangeInput
                    min={1}
                    max={30}
                    step={0.5}
                    value={interest}
                    onChange={event => setinterest(event.target.value)}
                />
            </Box>

            <Box direction="column" align="baseline" gap="medium">
                <Box direction="row" align="baseline" gap="small">
                    <Heading style={{ width: headingWidth }} level={5}> Time Period in Years</Heading>
                    <TextInput
                        size="xsmall"
                        placeholder="type here"
                        value={time}
                        onChange={event => settime(event.target.value)}
                    />
                </Box>
                <RangeInput
                    min={1}
                    max={30}
                    step={1}
                    value={time}
                    onChange={event => settime(event.target.value)}
                />
            </Box>

        </Box>
    </Box>)

    const grid2 = (
        <Box background="light-1" elevation="small">
            {
                (maturityValue >= 500) ? (
                    <Box background="light-1" margin="small" align="center" justify="center" gap="medium" pad="medium">
                        <Stack anchor="center">
                            <Meter
                                values={[{
                                    value: totalInterest,
                                    label: 'totalInterest',
                                    "color": "dark-3",
                                    onHover: over => {
                                        setActive(over ? formatter(totalInterest) : 0);
                                        setLabel(over ? 'Interest' : undefined);
                                    },
                                },
                                {
                                    value: initialInvest,
                                    label: 'investvalue',
                                    "color": "#c96",
                                    onHover: over => {
                                        setActive(over ? formatter(initialInvest) : 0);
                                        setLabel(over ? 'Invested' : undefined);
                                    },
                                }]}
                                type="circle"
                                aria-label="meter"
                                size="250px"
                                thickness="60px"
                            />
                            <Box align="center">
                                <Box direction="row" pad={{ bottom: 'xsmall' }}>
                                    <Text size="16px" weight={500} alignSelf="center" style={{ textAlign: "center" }}>
                                        {label || undefined} <br />
                                        {active || undefined}
                                    </Text>
                                </Box>
                            </Box>
                        </Stack>
                        {/* Legends */}
                        <Box gap="medium" direction="row" margin={{ top: "15px" }}>
                            <Box direction="row" justify="center" align="baseline" gap="small">
                                <Box pad={{ top: "5px", bottom: "5px", left: "5px", right: "5px" }} background="dark-3" width="01px"></Box>
                                <Heading level={5}>Total Interest</Heading>
                            </Box>
                            <Box direction="row" justify="center" align="baseline" gap="small">
                                <Box pad={{ top: "5px", bottom: "5px", left: "5px", right: "5px" }} background="#c96" width="01px"></Box>
                                <Heading level={5}>Total Investment</Heading>
                            </Box>
                        </Box>
                        <Text size="24px " weight={550}>Here's your data !</Text>
                        <Text color="#c96" size={textSize} weight={550} style={{ textAlign: "center" }}>Total Investment - {formatter(investvalue)} </Text>
                        <Text color="#c96" size={textSize} weight={550} style={{ textAlign: "center" }}>Interest Value - {formatter(totalInterest)} </Text>
                        <Text color="#c96" size={textSize} weight={550} style={{ textAlign: "center" }}>Maturity Value - {formatter(maturityValue)} </Text>

                        <Box background="white" align="center" justify="center" elevation="small" margin="xsmall" gap="small" pad="small">
                            <Text size="20px" weight={550} alignSelf="center" style={{ textAlign: "center" }}>
                                Montly Income - {formatter(monthlyIncome)}<br />
                            </Text>
                            <Text size="16px" weight={550} alignSelf="center" style={{ textAlign: "center" }}>
                                {convertNumberToWords(monthlyIncome)}
                            </Text>
                        </Box>
                    </Box>
                ) :
                    (
                        null
                    )
            }
        </Box>)
    return (
        <React.Fragment>
            {
                headerdisplay === false ? null :
                    (<PageHeader title="Post Office Monthly Income Scheme Calculator" subTitle="Calculate your future investments" />)
            }
            <Box pad={{ horizontal: resizer }} margin={{ top: "25px", bottom: "25px" }} align="center" justify="center" margin="large">
                {bigScreen === true ?
                    <Grid
                        columns={{
                            count: 2,
                            size: 'auto',
                        }}
                        gap="large"
                    >
                        {grid1}
                        {grid2}
                    </Grid>
                    :
                    < Grid
                        rows={{
                            count: 2,
                            size: 'auto',
                        }}
                        gap="medium"
                    >
                        {grid1}
                        {grid2}
                    </Grid>
                }
            </Box >
        </React.Fragment>
    );
}