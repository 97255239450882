import React from 'react';
import { Box, Heading, Text, Image } from 'grommet-v2';
import { FormNext } from 'grommet-icons';
import PropTypes from 'prop-types';

const propTypes = {
    working_data: PropTypes.string.isRequired,
    image: PropTypes.string,
};

const defaultProps = {
    working_data: '',
    image: ' ',
};

const resizeWidth = window.innerWidth || document.body.clientWidth;
var resizer;
if (resizeWidth > 1000) {
    resizer = "15%";
} else {
    resizer = "0px";
}

export default function HowDoesItWork({ working_data, image }) {
    console.log("image <>", image);
    return (
        <React.Fragment>
            <Box direction="column" gap="medium" pad={{ horizontal: resizer, vertical: "10px" }} margin={{ top: "25px", bottom: "25px" }} background="light-1">
                <Heading level={2} alignSelf="center" margin="small" style={{ textAlign: "center"}}>{working_data[0].question}</Heading>
                <Image
                    a11yTitle="Imagecredit: Investor Academy"
                    fit="contain"
                    src={image}
                />
                {/* <Text alignSelf="center" size="12px"> Imagecredit: Investor Academy </Text> */}
                <Text size="28px" weight={600} alignself="center" style={{ textAlign: "center" }}>{working_data[0].title_heading}</Text>
                {
                    working_data.map((item) => (
                        <Box background="white" responsive elevation="small" round="5px">
                            <Text size="18px" wordBreak="keep-all" margin="small" >{item.point}</Text>
                            {
                                item.sub_points && item.sub_points.length > 0 && item.sub_points.map((item) => (
                                    <Text size="17px" wordBreak="keep-all" margin={{ left: "20px", top: "10px", bottom: "5px", right:"5px" }}><FormNext color="#c96" /> &nbsp; {item}</Text>
                                ))
                            }
                        </Box>
                    ))}
            </Box>
        </React.Fragment>
    );
}

HowDoesItWork.propTypes = propTypes;
HowDoesItWork.defaultProps = defaultProps;
