import React from 'react';
import { Tab, Tabs, TabPanel, TabList } from 'react-tabs';
import { Box } from 'grommet-v2';
import PageHeader from '../../common/page-header';
import WWWH from './components/0_WWWHAccordian';
import { sip_about, sip_featfact } from '../../../mock_data/data.jsx';
import SIP_Calculator from './calculators/SIP_Calculator';
import FF from './components/0_FeaturesFactors';

const switch_panel = ["About", "Features & Factors", "Calculator"];

export default function MultiTab_SIP() {
    return (
        <React.Fragment>
            <Box direction="column" gap="large">
                <PageHeader title="Systematic Investment Plan" subTitle="a good investment" />
                <Tabs selectedTabClassName="show">
                    <TabList className="nav nav-pills nav-border-anim nav-big justify-content-center mb-3">
                        {
                            switch_panel.length > 0 && switch_panel.map((item) =>
                                <Tab className="nav-item">
                                    <span className="nav-link">{item}</span>
                                </Tab>
                            )
                        }
                    </TabList>
                    <TabPanel>
                        <WWWH
                            about_data={sip_about} />
                    </TabPanel>
                    <TabPanel>
                        <FF featfact_data={sip_featfact}
                            feat_name="Features and Benefits of SIP"
                            fact_name="Factors to consider before buying SIP" />
                    </TabPanel>
                    <TabPanel>
                        <SIP_Calculator headerdisplay={false} />
                    </TabPanel>
                </Tabs>
            </Box>
        </React.Fragment>
    )
}