import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import isotope from 'isotope-layout';
import imagesLoaded from 'imagesloaded';

import store from '../store';

import MobileMenu from './common/header/common/mobile-menus/menu-3';
import OuterOverlay from './common/overlay/outer-overlay';
import { Box, Text, Heading, Image } from 'grommet-v2';
import { Info, Book } from 'grommet-icons';

// import Utils
import { isotopeLoad, scrollToElement, mobileMenu } from '../utils/utils';
import { outerLoading } from '../actions';
// import from json
import _data from '../mock_data/data';
import style from './style.scss';

const resizeWidth = window.innerWidth || document.body.clientWidth
// const resizeHeight = window.innerHeight || document.body.clientHeight;
var resizer, headerfontSize, flexDisplay, bigScreen, learnModuleText;
if (resizeWidth > 1000) {
    resizer = "200px";
    headerfontSize = "22px"
    flexDisplay = "flex";
    bigScreen = true;
    learnModuleText = "22px";
} else {
    resizer = "0px";
    headerfontSize = "18px"
    flexDisplay = "flow"
    bigScreen = false;
    learnModuleText = "18px";
}

class Landing extends Component {
    componentDidMount() {
        mobileMenu();
        isotopeLoad(isotope, imagesLoaded, '.demos', '.demo-elem', '.demo-filter');
        document.getElementById('scroll-top').style.display = 'none';
    }

    componentWillMount() {
        store.dispatch(outerLoading());
        style.use();
    }

    componentDidUpdate() {
        document.querySelector(".demo-filter a").click();
    }

    componentWillUnmount() {
        style.unuse();
    }

    render() {
        return (
            <React.Fragment>
                <OuterOverlay />
                <div className="page-wrapper">
                    <header id="header">
                        <div className="container-lg">
                            {/* 3 main modules */}
                            <div className="header-main">
                                <ul className="menu">
                                    <li>
                                        <Link to="#" className="goto-demos" data-target=".section-learn" onClick={scrollToElement}>Learn</Link>
                                    </li>
                                    <li>
                                        <Link to="#" className="goto-features" data-target=".section-calculate" onClick={scrollToElement}>Calculate</Link>
                                    </li>
                                    <li>
                                        <Link to="#" className="goto-elements" data-target=".section-invest" onClick={scrollToElement}>Invest</Link>
                                    </li>
                                </ul>
                            </div>
                            <div className="header-right">
                                {bigScreen === true ?
                                    (<Box direction="row" align="baseline" pad={{ left: "1000px" }}>
                                        <Book size="medium" color="green" fill /><Text size="34px" color="#c96" weight={550}>She</Text>
                                        <Text size="16px" color="black" weight={450}>finances</Text>
                                    </Box>) :
                                    (<Box direction="row" align="baseline" pad={{ left: "180px" }}>
                                        <Book size="medium" color="green" fill /><Text size="34px" color="#c96" weight={550}>She</Text>
                                        <Text size="16px" color="black" weight={450}>finances</Text>
                                    </Box>)
                                }
                            </div>
                        </div>
                    </header>

                    <div id="main">
                        <section className="banner section-dark mb-0" style={{ backgroundColor: "#222" }}>
                            <img src={`${process.env.PUBLIC_URL}/assets/images/demos-img/header_splash.jpg`} alt="splash" width="1920" height="1120" />
                            {bigScreen === true ?
                                (<div className="banner-text text-center">
                                    <Text color="black" size="46px" weight={500}>Getting started</Text>
                                    <Heading level={5}>Here's your first step towards Financial Independence !! </Heading>
                                    <p className="mb-0"><Link to="#" className="btn btn-primary btn-outline goto-demos" data-target=".section-learn" onClick={scrollToElement}><Text color="black" size="16px" weight={650}>Explore Our First Module</Text></Link></p>
                                </div>) :
                                (

                                    <div className="banner-text text-center">
                                        <Box pad={{ bottom: "550px" }}>
                                            <Heading level={6}>Here's your first step towards Financial Independence!! <Text margin="none" size="13px" weight={600}>Learn. Calculate. Invest.</Text> </Heading>
                                            <p className="topStyle"><Link to="#" className="btn btn-primary btn-outline goto-demos" data-target=".section-learn" onClick={scrollToElement}><Text color="black" size="12px" weight={650}>Explore Our First Module</Text></Link></p>
                                        </Box>
                                    </div>
                                )
                            }
                        </section>

                        {/* Starting of - First Module margin={{ top: "25px", bottom: "25px" }} */}
                        <Box pad={{ horizontal: resizer }} background="light-1">
                            <section className="section section-learn text-center container-lg">
                                <h2>Here goes our very first Module on Learning</h2>
                                <Text size="14px" color="grey">In this Section , you will learn about most popular saving and investment options available</Text>
                                <div className="demo-filter menu"
                                    style={{
                                        display: flexDisplay,
                                        flexDirection: 'row',
                                        justifyContent: 'space-between',
                                    }}
                                >
                                    <Link to="#taxexemption" className="active" data-filter=".taxexemption" style={{ margin: '10px', fontSize: headerfontSize }}>Tax Exemption Schemes</Link>
                                    <Link to="#popularinvests" data-filter=".popularinvests" style={{ margin: '10px', fontSize: headerfontSize }}>Popular Investment Options</Link>
                                    <Link to="#govtschemes" data-filter=".govtschemes" style={{ margin: '10px', fontSize: headerfontSize }}>Government Schemes</Link>
                                    <Link to="#others" data-filter=".others" style={{ margin: '10px', fontSize: headerfontSize }}>Others</Link>
                                </div>
                                <div className="row demos" id="scroll-to-content">
                                    {/* Section - A */}
                                    <div className="row">
                                        {_data.taxExemp.map(item => (
                                            <div className="demo-elem col-sm-6 col-md-3 col-lg-1-5 taxexemption" key={item.name}>
                                                <Link to={process.env.PUBLIC_URL + item.url}>
                                                    <div className="img-box all">
                                                        <Box pad={{ top: "20px", bottom: "20px" }} elevation="medium" background="white">
                                                            <Text size={learnModuleText} weight={500} alignSelf="center"> {item.name} </Text>
                                                        </Box>
                                                    </div>
                                                </Link>
                                            </div>
                                        ))}
                                    </div>

                                    {/* Section - B */}
                                    <div className="row">
                                        {_data.popInvest.map(item => (
                                            <div className="demo-elem col-sm-6 col-md-3 col-lg-1-5 popularinvests" key={item.name}>
                                                <Link to={process.env.PUBLIC_URL + item.url}>
                                                    <div className="img-box all">
                                                        <Box pad={{ top: "20px", bottom: "20px" }} elevation="medium" background="white">
                                                            <Text size={learnModuleText} weight={500} alignSelf="center"> {item.name} </Text>
                                                        </Box>
                                                    </div>
                                                </Link>
                                            </div>
                                        ))}
                                    </div>

                                    {/* Section - C */}
                                    <div className="row">
                                        {_data.govtSchemes.map(item => (
                                            <div className="demo-elem col-sm-6 col-md-3 col-lg-1-5 govtschemes" key={item.name}>
                                                <Link to={process.env.PUBLIC_URL + item.url}>
                                                    <div className="img-box all">
                                                        <Box pad={{ top: "20px", bottom: "20px" }} elevation="medium" background="white">
                                                            <Text size={learnModuleText} weight={500} alignSelf="center"> {item.name} </Text>
                                                        </Box>
                                                    </div>
                                                </Link>
                                            </div>
                                        ))}
                                    </div>
                                    {/* </section> */}

                                    {/* Section - D */}
                                    <div className="row">
                                        {_data.others.map(item => (
                                            <div className="demo-elem col-sm-6 col-md-3 col-lg-1-5 others" key={item.name}>
                                                <Link to={process.env.PUBLIC_URL + item.url} >
                                                    <div className="img-box all">
                                                        <Box pad={{ top: "20px", bottom: "20px" }} elevation="medium" background="white">
                                                            <Text size={learnModuleText} weight={500} alignSelf="center"> {item.name} </Text>
                                                        </Box>
                                                    </div>
                                                </Link>
                                            </div>
                                        ))}
                                    </div>
                                    {/* </section> */}
                                </div>
                                <h5 className="text-load-more">Many more learning modules are coming Soon ...</h5>
                            </section>
                        </Box>
                        {/* Staring of Second Module */}
                        <Box pad={{ horizontal: resizer }} background="light-1">
                            <section className="section section-calculate">
                                <h2 className="text-center">Calculators for you</h2>
                                <p className="text-center"><Text size="14px">We bring you some of the trending investment calculators to do your calculation. Lets start calculating and investing</Text></p>
                                <div className="divider-line">
                                    <div className="container-lg">
                                        <div className="overflow-hidden">
                                            <div className="row">
                                                <div className="col-sm-6 col-lg-3">
                                                    {/* 1st calc */}
                                                    <Link to={process.env.PUBLIC_URL + '/calculate/ppf'}>
                                                        <div className="icon-box">
                                                            <Image margin={{ bottom: "20px" }} src={process.env.PUBLIC_URL + "/assets/images/icons/1.png"} width="60px" height="60px"/>
                                                            <Heading level={4}>Public Provident Fund Calc<br/></Heading>
                                                            <Text size="14px">PPF is a long-term investment scheme for those who want to earn high but stable returns. It comes under the small savings instruments categories.</Text>
                                                        </div>
                                                    </Link>
                                                </div>
                                                {/* 2nd calc */}
                                                <div className="col-sm-6 col-lg-3">
                                                    <Link to={process.env.PUBLIC_URL + '/calculate/nps'}>
                                                        <div className="icon-box">
                                                            <Image margin={{ bottom: "20px" }} src={process.env.PUBLIC_URL + "/assets/images/icons/2.png"} width="60px" height="60px"/>
                                                            <Heading level={4}>National Pension Scheme Calc</Heading>
                                                            <Text size="14px">NPS is a voluntary contribution pension system of India.It's the go-to way to create a corpus for retirement as an important part of financial planning.</Text>
                                                        </div>
                                                    </Link>
                                                </div>
                                                {/* 3rd calc */}
                                                <div className="col-sm-6 col-lg-3">
                                                    <Link to={process.env.PUBLIC_URL + '/calculate/sip'}>
                                                        <div className="icon-box">
                                                            <Image margin={{ bottom: "20px" }} src={process.env.PUBLIC_URL + "/assets/images/icons/3.png"} width="60px" height="60px"/>
                                                            <Heading level={4}>Systematic Investment Plan Calc</Heading>
                                                            <Text size="14px">SIP is a facility offered by mutual funds to the investors to invest in a disciplined manner. SIP facility allows an investor to invest a fixed amount of money at pre-defined intervals in the selected mutual fund scheme.</Text>
                                                        </div>
                                                    </Link>
                                                </div>
                                                {/* 4th calc */}
                                                <div className="col-sm-6 col-lg-3">
                                                    <Link to={process.env.PUBLIC_URL + '/calculate/mf'}>
                                                        <div className="icon-box">
                                                            <Image margin={{ bottom: "20px" }} src={process.env.PUBLIC_URL + "/assets/images/icons/4.png"} width="60px" height="60px"/>
                                                            <Heading level={4}>Mutual Funds Calc</Heading>
                                                            <Text size="14px">Mutual Fund is a vehicle to mobilize funds to invest in various securities.it's a pool of funds collected from investors like you and me. This money is invested in various securities to earn profits and those profits are distributed to the investors. </Text>
                                                        </div>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="container-lg">
                                        <div className="overflow-hidden">
                                            <div className="row">
                                                {/* 5th calc */}
                                                <div className="col-sm-6 col-lg-3">
                                                    <Link to={process.env.PUBLIC_URL + '/calculate/emi'}>
                                                        <div className="icon-box">
                                                            <Image margin={{ bottom: "20px" }} src={process.env.PUBLIC_URL + "/assets/images/icons/5.png"} width="60px" height="60px"/>
                                                            <Heading level={4}>EMI Calc</Heading>
                                                            <Text size="14px">Loan repayments include EMIs and borrower should consider the EMI amount to accurately plan their current and future finances.</Text>
                                                        </div>
                                                    </Link>
                                                </div>
                                                {/* 6th calc */}
                                                <div className="col-sm-6 col-lg-3">
                                                    <Link to={process.env.PUBLIC_URL + '/calculate/ssy'}>
                                                        <div className="icon-box">
                                                            <Image margin={{ bottom: "20px" }} src={process.env.PUBLIC_URL + "/assets/images/icons/6.png"} width="60px" height="60px"/>
                                                            <Heading level={4}>Sukanya Samridhi Yojana Calc</Heading>
                                                            <Text size="14px">SSY is a government backed scheme targeted at the parents of a young girl.It is a small deposit scheme launched under the “Beti Bachao Beti Padhao” scheme. </Text>
                                                        </div>
                                                    </Link>
                                                </div>
                                                {/* 7th calc */}
                                                <div className="col-sm-6 col-lg-3">
                                                    <Link to={process.env.PUBLIC_URL + '/calculate/fd'}>
                                                        <div className="icon-box">
                                                            <Image margin={{ bottom: "20px" }} src={process.env.PUBLIC_URL + "/assets/images/icons/7.png"} width="60px" height="60px"/>
                                                            <Heading level={4}>Fixed Deposit Calc</Heading>
                                                            <Text size="14px"> Fixed Deposits are financial instruments offered by NBFCs or Banks which provide investors higher interest rates than a regular savings account until the given date of maturity</Text>
                                                        </div>
                                                    </Link>
                                                </div>
                                                {/* 8th calc */}
                                                <div className="col-sm-6 col-lg-3">
                                                    <Link to={process.env.PUBLIC_URL + '/calculate/rd'}>
                                                        <div className="icon-box">
                                                            <Image margin={{ bottom: "20px" }} src={process.env.PUBLIC_URL + "/assets/images/icons/8.png"} width="60px" height="60px"/>
                                                            <Heading level={4}>Recurring Deposit Calc</Heading>
                                                            <Text size="14px">RDs create a habit of regular investment among earning individuals ie., you have to make fixed monthly deposits in RDs.</Text>
                                                        </div>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="container-lg">
                                        <div className="overflow-hidden">
                                            <div className="row">
                                                {/* 9th Calc */}
                                                <div className="col-sm-6 col-lg-3">
                                                    <Link to={process.env.PUBLIC_URL + '/calculate/potd'}>
                                                        <div className="icon-box">
                                                            <Image margin={{ bottom: "20px" }} src={process.env.PUBLIC_URL + "/assets/images/icons/9.png"} width="60px" height="60px"/>
                                                            <Heading level={4}>Post Office Term Deposit Calc</Heading>
                                                            <Text size="14px"> POFD or POTD is the oldest and preferred form of investment offered by the Indian Postal Services. They are considered as safe as the Government of India backs them. Hence it was a famous investment avenue for the previous generations.</Text>
                                                        </div>
                                                    </Link>
                                                </div>

                                                {/* 10th Calc */}
                                                <div className="col-sm-6 col-lg-3">
                                                    <Link to={process.env.PUBLIC_URL + '/calculate/pord'}>
                                                        <div className="icon-box">
                                                            <Image margin={{ bottom: "20px" }} src={process.env.PUBLIC_URL + "/assets/images/icons/10.png"} width="60px" height="60px"/>
                                                            <Heading level={4}>Post Office Recurring Deposit Calc</Heading>
                                                            <Text size="14px">Post Office Recurring Deposits are deposits that allow you to save regularly on a monthly basis.</Text>
                                                        </div>
                                                    </Link>
                                                </div>
                                                {/* 11th Calc */}
                                                <div className="col-sm-6 col-lg-3">
                                                    <Link to={process.env.PUBLIC_URL + '/calculate/pomis'}>
                                                        <div className="icon-box">
                                                            <Image margin={{ bottom: "20px" }} src={process.env.PUBLIC_URL + "/assets/images/icons/11.png"} width="60px" height="60px"/>
                                                            <Heading level={4}>Post Office Monthly Investment Calc</Heading>
                                                            <Text size="14px">The Post Office Monthly Income Scheme (POMIS) is a Government of India backed small savings scheme that allows the investor to set aside a specific amount every month</Text>
                                                        </div>
                                                    </Link>
                                                </div>
                                                {/* 12th Calc */}
                                                <div className="col-sm-6 col-lg-3">
                                                    <Link to={process.env.PUBLIC_URL + '/pages/404'}>
                                                        <div className="icon-box">
                                                        <Image margin={{ bottom: "20px" }} src={process.env.PUBLIC_URL + "/assets/images/icons/12.png"} width="60px" height="60px"/>
                                                        <Heading level={4}>We are building more calculators ... wait up !</Heading>
                                                            {/* <p>NSC is one of best savings bond provided by Indian Government Savings Bond. As NSC is proving tax benefit up to 150000 rupees it is mainly used for income tax saving.</p> */}
                                                        </div>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </Box>

                        <section className="section section-support section-dark section-invest">
                            <div className="text-center">
                                <Box margin="medium" pad="small" justify="center" align="center">
                                    <Info color="#c96" size="large" />
                                </Box>
                                <h2>Are you ready to Invest?</h2>
                                <p>We bring you MoneyPlanned to help you with the investment<br />500+ women will get free subscripton for 3 months to pull off her investment goals.</p>
                                <p><Link className="btn btn-primary" to={process.env.PUBLIC_URL + '/invest/moneyplanned'}>Go to Investment Module</Link></p>
                            </div>
                        </section>
                        {/* <section className="section section-light section-ready container text-center">
                            <h2 className="mb-1">Molla Is Ready To Use. Get It Now!</h2>
                            <p>DON'T FORGET TO APPRECIATE OUR WORK. RATE US NOW!</p>
                            <div className="star-rating pb-1" style={{ marginBottom: '1.5rem' }}>
                                <i className="icon-star"></i><i className="icon-star"></i><i className="icon-star"></i><i className="icon-star"></i><i className="icon-star"></i>
                                <Link className="btn btn-primary btn-outline" to="#"><p>Go to Investment Module</p></Link>
                            </div>
                        </section> */}
                    </div>

                    <footer id="footer" className="container-lg">
                        <div className="row">
                            <div className="col-md-6 text-center text-md-left mb-1 mb-md-0">
                                <p className="copyright mb-0">Copyright © {(new Date()).getFullYear()} Shefinances. All Rights Reserved.
                                {/* <Link to="#">Terms Of Use</Link>  | <Link to="#">Privacy Policy</Link> */}
                                </p>
                            </div>
                            <div className="col-md-6 text-center text-md-right social-icons">
                                {/* <label className="mr-3">Social Media</label> */}
                                {/* <Link to="#" title="Facebook"><i className="icon-facebook-f"></i></Link>
                                <Link to="#" title="Twitter"><i className="icon-twitter"></i></Link>
                                <Link to="#" title="Instagram"><i className="icon-instagram"></i></Link>
                                <Link to="#" title="Youtube"><i className="icon-youtube"></i></Link>
                                <Link to="#" title="Pinterest"><i className="icon-pinterest"></i></Link> */}
                                <p className="copyright mb-0"> Contact Us - contact@shefinances.in
                                {/* <Link to="#">Terms Of Use</Link>  | <Link to="#">Privacy Policy</Link> */}
                                </p>
                            </div>
                        </div>
                    </footer>

                    <MobileMenu />
                </div>
            </React.Fragment>
        )
    }
}

// target="_blank" in Link will open the link in new tab

export default Landing;