
// Here goes the About Section

export const mutualfunds_about = [
    {
        highlighter: "What",
        question: "is a Mutual Fund? ",
        description: "Mutual Fund is a vehicle to mobilize funds to invest in various securities. Essentially, it is a pool of funds collected from investors like you and me. This money is invested in various securities to earn profits and those profits are distributed to the investors. ",
        answer_in_steps: ["A Mutual Fund is managed by an organization called the Asset Management Company. ", "The Asset Management Company appoints a Fund Manager for strategizing and making these investments. The Fund Manager plays a crucial role in deciding how the investment performs."]
    },
    {
        highlighter: "Why",
        question: "should you opt for Mutual Funds?",
        description: "There are a myriad of options and there is a mutual fund for everyone and for every financial goal you may have. Invest in Mutual Funds if you want to grow your wealth and get higher returns for the money you saved up for meeting your goals. ",
        answer_in_steps: ["Mutual funds are an indirect way to invest in the stock market equity securities without having to take the burden of doing all the work yourself."]
    },
    {
        highlighter: "How",
        question: "can you open a Mutual Fund account?",
        description: "A Mutual Fund account can be created very easily using several investment platforms. You may opt to purchase a Mutual Fund scheme directly on the website of an AMC. However, multiple Investment Management and Planning platforms have made it a hundred times simpler. ",
        answer_in_steps: ["A KYC is mandatory for first time investors.", "A PAN Card needs to be shown as an identity proof."]
    },
    {
        highlighter: "Styles",
        question: "of investing in mutual funds? ",
        description: "Investors mostly have two majorly different styles of investments. They are either value investors and growth investors. ",
        answer_in_steps: ["Value investors are those who invest in companies with great potential to succeed in the future although they may be undervalued at present", "Growth investors are those that invest in companies with growing revenues and returns in the present."]
    },
    {
        highlighter: "Approaches",
        question: "of investing in mutual funds? ",
        description: "Investors may use two broad approaches for making their investment decisions.  ",
        answer_in_steps: ["A top-down approach is one where the macro-economic factors (market situation) are analyzed first and then the industry is selected, following which company-specific matters are looked at", "A bottom-up approach first considers the company-related matters and then evaluates the industry, following which the macro-economic factors are considered."]
    },
    {
        highlighter: "Working",
        question: "of mutual funds? ",
        answer_in_steps: ["Investors pool their Money in a fund", "A Professional Manager invests in a diverse set of securities", "Investment returns generated (gains or losses are shared by investors)"],
    },
];


export const mutualfunds_categories_1 = [
    {
        //   title: "Various ways of classifying Mutual funds. ",
        title: "Based on the time period during which they are operational, they are classified into three types",
        info: 'Open-ended funds',
        details: 'These are open for investment round the year. Essentially, one can buy or sell units of mutual funds at any time.',
    },
    {
        info: 'Close-ended funds',
        details: 'These are open for investment for a limited period of time during which investors need to purchase the units. In order to provide liquidity, these units are then traded on stock exchange for the investors to redeem.',
    },
    {
        info: 'Interval funds',
        details: 'They are a combination of open-ended and closed-ended funds. They are not traded on the stock exchange as they open periodically for investors to buy back. The period when they are open-ended is called transaction period.',
    }
];

export const mutualfunds_categories_2 = [
    {
        title: "Broadly speaking they can be classified into three types depending on the asset class they invest in.",
        title_info: 'Equity Mutual Funds',
        title_details: 'These are the funds that invest in equity securities. Equity means a piece of ownership in a company. Risk is higher and so is the return. ',
        title_details_steps: ["Equity means a piece of ownership or stake in a company or organization. When an investor buys equity units of a mutual fund, he is essentially investing in a publicly traded or listed company in exchange for a portion of ownership. ", "As the company grows, he can either grow his money for the long-term or receive dividend payout from the profits.", "If the company doesn’t perform well, there may be losses incurred. There is no obligation for payback of the money invested in case of equity units. Hence, these are generally considered riskier than the others.", "However, the returns are much higher than the other schemes with a high potential for wealth creation."],
        sub_title: "In a descending order of risk and return, here are the most common types of equity funds",
        sub_types_details: "Different types of Equity Funds are as follows,",
        sub_types: [{
            sub_title_info: 'Thematic Funds',
            sub_title_details: 'Invest in a theme for instance Pharma, IT, etc.',
        },
        {
            sub_title_info: 'Focused Funds',
            sub_title_details: 'Comprise a smaller variety with focus on just a few sectors.',
        },
        {
            sub_title_info: 'Multi-cap funds',
            sub_title_details: 'Diversified funds that invest across the market.',
        },
        {
            sub_title_info: 'Dividend Yield Funds',
            sub_title_details: 'Invest in stocks with high dividend declarations.',
        },
        {
            sub_title_info: 'Index funds',
            sub_title_details: 'Funds in-line with the market index.',
        },
        {
            sub_title_info: 'ELSS',
            sub_title_details: 'Equity Linked Saving Scheme for tax benefits and wealth growth.',
        }]
    },
    {
        title_info: 'Debt Mutual Funds ',
        title_details: 'Debt securities offer interest rate on the investment and also generate fixed income. Risk is lower and so is the return.',
        title_details_steps: ["Debt literally means loan. Similarly, debt mutual funds are similar to loans lent by the investor where a fixed income/interest is earned back.", "By virtue of their nature, debt funds are safer to invest as the investor is assured to get a fixed income or at the least the principal he paid for investment. ", "Debt funds are preferred if income generation is a necessity , Most of the Government or corporate bonds fall under this category. "],
        sub_title: "In a descending order of risk and return, here are the most common types of balanced funds:",
        sub_types: [{
            sub_title_info: 'Credit Risk Fund',
            sub_title_details: 'Junk bonds with high-yield and high-risk',
        },
        {
            sub_title_info: 'Monthly Income Plans',
            sub_title_details: 'Monthly Income Plans for regular fixed income options  ',
        },
        {
            sub_title_info: 'Diversified Debt Funds',
            sub_title_details: 'Give a broad market exposure ',
        },
        {
            sub_title_info: 'Gilt Funds',
            sub_title_details: 'Invest in Government Securities with zero credit risk',
        },
        {
            sub_title_info: 'Liquid Funds',
            sub_title_details: 'Can be liquidated easily and have a duration of less than 90 days',
        },
        {
            sub_title_info: 'Arbitrage Funds',
            sub_title_details: 'Low risk and high return schemes ',
        }]
    },
    {
        title_info: 'Hybrid Mutual Funds or Balanced Mutual funds ',
        title_details: 'Hybrid Mutual Funds are a mixture of both Equity and Debt schemes. To offer the advantages in both and hence balance an investor’s portfolio.',
        title_details_steps: ["Balanced funds could be equity-oriented or debt-oriented, i.e. have a greater share of their portfolio be invested in equity or debt respectively. "],
        sub_title: "In a descending order of risk and return, here are the most common types of balanced funds:",
        sub_types: [{
            sub_title_info: 'Aggressive Balanced Funds: ',
            sub_title_details: 'Equity exposure is 65% to 80%; Debt exposure is 20 to 35%',
        },
        {
            sub_title_info: 'Dynamic Asset Allocation',
            sub_title_details: 'Units shifted from equity to debt and vice-versa based on market and other factors',
        },
        {
            sub_title_info: 'Conservative balanced fund',
            sub_title_details: 'Equity exposure is 10 to 25% and debt exposure is 75 to 90%',
        },
        {
            sub_title_info: 'Multi asset allocation',
            sub_title_details: 'Invested in more than 3 asset classes',
        },]
    },
];


export const stocks_about = [
    {
        highlighter: "What",
        question: "are stocks?",
        answer_in_steps: ["Stock or a share is a financial instrument that represents ownership in a company.", "A single share of stock represents fractional ownership of the corporation in proportion to the total number of shares.", "Even while investing the now-popular Mutual Funds, you are indirectly investing in stocks of a company. There is a direct provision of investing in stocks and that is facilitated by a Stock Exchange, a marketplace for selling and buying stocks."]
    },
    {
        highlighter: "Difference",
        question: "between stocks and shares?",
        answer_in_steps: ["Stocks is the more general, generic term. It is often used to describe a slice of ownership of one or more companies.", "In contrast, in common parlance, shares has a more specific meaning: It often refers to the ownership of a particular company,", "Simply put, stocks are a generic, market-level term while shares are a company-specific term used to denote the same thing - ownership in a company."],
    },
    {
        highlighter: "How",
        question: "does the Stock Market work? a quick look",
        description: "To put it simply and succinctly this is how the stock market works.",
        answer_in_steps: ["A company goes public and opens up for investors to buy its shares. ", "The shareholders can earn money in two ways: Trade these stocks in the stock market or earn dividends from the company’s profits. Stock Market provides a platform for buyers and sellers to transact through Stock exchanges."]
    },
    {
        highlighter: "Learn",
        question: "about Trading and Demat accounts?",
        answer_in_steps: ["Trading account is the account used for buying or selling the stocks.", " Demat account is where those are dematerialized or held in digital form for redemption.", "Difference between Demat and trading Account is that a Demat Account holds the shares and securities (Bonds, ETFs, Mutual Fund units, etc.) in digital mode, while a Trading Account provides the interface to buy and sell shares in the stock market. We can trade stocks using a demat account. ", "A demat account can be created with a bank or on other online platforms like Zerodha, etc.", "A trading account is created with a stock broker or firm."]
    },
    {
        highlighter: "Start",
        question: "trading on stock exchange?",
        description: "The basic three steps involved in starting trading on the stock market are as follows:",
        answer_in_steps: ["Open a Trading account with a stock broker or firm", "Open a Demat account with a bank or online platforms", "Decide which stocks to buy and the number of stocks to invest in."]
    },
    {
        highlighter: "Tax",
        question: "implications of trading?",
        description: "Gain or loss generated through stock trading will need to be classified as short term (STCG for shares held up to an year) or long term (LTCG for shares held for more than an year.",
        answer_in_steps: ["The STCG shall be taxed at 15% and LTCG shall be taxed at 10%. However, LTCG up to Rs 1 lakh a year will be tax free. ", "STT is yet another kind of financial transaction tax which is similar to tax collected at source (TCS). ", "STT is a direct tax levied on every purchase and sale of securities that are listed on the recognized stock exchanges in India.",]
    },
    {
        highlighter: "Documents",
        question: "required to open a Demat or Trading account?",
        description: "These are the documents required for a Demat or Trading account(throgh online portal)",
        answer_in_steps: ["PAN Card", "Proof of Identity", "Proof of Address", "Proof of Income", "Proof of Bank account (cancelled cheque)"]
    },
    {
        highlighter: "Procedures",
        question: "for opening Trading and Demat account?",
        description: "These are the procedure for opening Trading and Demat account ",
        answer_in_steps: ["Go to the selected broking firm or banks website", "Submit all the required documents with proper personal details", "Complete KYC process and await confirmation"]
    },
    {
        highlighter: "Pros",
        question: "of investing in Stocks?",
        description: "here are some advantages listed below",
        answer_in_steps: ["Build Wealth - Long-term equity securities have better returns than most fixed-income deposits. Investments help build wealth for the long-term. If one decides to delve deep and acquire in-depth knowledge about how to invest", "Dividend Income - Some stocks allow investors to earn dividends and they are called dividend-paying stocks. These cater to people that prefer a regular stream of cash. ", "Voting rights - Companies Act provides that every member of a company limited by shares and holding equity share capital therein, shall have a right to vote on crucial decisions of the company. Anyone who owns stock in a company has a voting right to the decisions that the company makes.The fewer shares someone owns, the less voting power they have.Voting has a significant impact on the price of the shares someone owns. For this reason, education about a company's proposed decisions is of the utmost importance.", "The stock market allows you to sell your stock at any time. Economists use the term 'liquid' to mean you can turn your shares into cash quickly and with low transaction costs. That's important if you suddenly need your money in a hurry and stocks provide you that. ", "Helps beat Inflation - Historically, stocks have averaged an annualized return of 10%.﻿ That's better than the average annualized inflation rate. It does mean you must have a longer time horizon. That way, you can buy and hold even if the value temporarily drops."],
    },

    {
        highlighter: "Cons",
        question: "of investing in Stocks?",
        description: "here are some disadvantages listed below",
        answer_in_steps: ["Risky - In stock markets, timing is everything. A wrong move at the wrong time can make your hard-earned money go down the drain. ", "Risk in investments is basically the possibility that you might lose all your money.", "The most important types of risks to be aware of is the Market risk. There are two main types of Market risks involved: Equity risk and Interest rate risk. Equity risk applies to investments in shares. As the share prices are always fluctuating due to demand and supply, it is the risk that the share price might drop. Interest rate risk applies to debt bonds and is the risk of losing money due to the interest rate change. If the interest rate goes up, the market value of the bond will fall. ", "Expertise needed - Stock market investments, especially in large amounts, need knowledge and expertise. They are suitable only for people with a high risk appetite and tolerance. ", "It takes a seasoned investor to make strategic investments and grow wealth massively. The expertise doesn’t come easy. It requires you to study the markets thoroughly and efficiently."]
    },
];

export const stocks_working = [
    {
        question: "Working of Stock Market",
        point: "Lets try to understand How did stocks come into existence? When does a company list its shares? Who decides the price of a share?.. Before we delve deeper, let’s understand how stocks are formed, companies are classified and the role economy plays. There are few major contributors to the Indian Economy. Banking Sector, Securities Market, and Foreign Exchange Market.",
    },
    {
        point: "The Financial Market in India is classified into two broad categories.",
        sub_points: ["Money Market", "Securities Market"],
    },
    {
        point: "Money Market is a component of the economy that deals with short-term funds. The money market deals in short-term funds that have a maturity of less than a year. ",
    },
    {
        point: "Securities Market, on the other hand, a component of the wider financial market where securities can be bought and sold between subjects of the economy, on the basis of demand and supply. It is essentially the place where equity or debt instruments are bought or sold. ",
    },
    {
        point: "The Securities Market is further divided into Primary Market and Secondary Market.",
        sub_points: ["Primary Markets are where these securities are created whereas secondary markets are where these securities are sold. The first issue of shares of a company is done in the primary market. ", "Secondary market offers liquidity to those investments as well as a platform to transact. "],
    },
    {
        point: "There are two types of companies broadly speaking; Public companies and private companies. ",
        sub_points: ["Public companies are those that are traded/listed on the Stock market. While private companies aren’t listed on the Stock Exchange or unlisted companies. ", "When a private company “goes public” through an IPO or Initial Public Offering, it gets listed on the Stock Market. "]
    },
    {
        point: "By going public, a company opens its shares to commoners. After an IPO, anyone can buy shares of the company and claim ownership in the same at a certain price at which it is available in the market. ",
    },
    {
        point: "These shares are listed on the Stock exchanges.Stock exchanges are a marketplace where the buyers and sellers meet. In India, there are several stock exchange platforms. Like Bombay Stock Exchange, National Stock Exchange, and so on.",
    },
    {
        point: "Market price of a share is the value at which the stock is being bought or sold / traded. The price is determined by various factors.",
        sub_points: [" Few of the major ones are the performance of the company", "revenue being generated", "brand image or value of the company", "reputation of the company and so on.", " The better a company performs, the more will be the share price of the company."]
    },
    {
        point: "For having a stake of ownership in a company, few companies give voting rights and share dividends with their companies"
    }
];

// verified
export const elss_about = [
    {
        highlighter: "What",
        question: "is an Equity Linked Savings Scheme(ELSS)?",
        answer_in_steps: ["ELSS is a type of mutual fund that invests a large portion of its corpus in equity or equity-linked instruments.", "ELSS are also called tax-saving schemes as they offer a tax deduction of upto Rs. 1,50,000 under Section 80C.", "Further, the income that you earn under this scheme at the end of the three-year tenure will be considered as Long Term Capital Gain (LTCG) and will be taxed at 10% (if the income is above Rs. 1 lakh)."],
        examples: ["Example: Here's how it will work. Say, one invests Rs 8,000 monthly in ELSS (Rs 1 lakh annually) for 20 years of one's working life towards retirement. Assuming a growth rate of 12 percent a year, the corpus could be nearly Rs 80 lakh, which could be part of one's retirement portfolio in addition to other investments earmarked for retirement."]
    },
    {
        highlighter: "Why",
        question: "should we opt for the ELSS-Mutual Fund?",
        answer_in_steps: ["ELSS is the best investment for salaried employees as well as first-time investors.", "ELSS has many benefits over the traditional tax saving deposits like FD, NPS, etc. It has the lowest lock-in period for only 3 years. ", "Investments of up to 1.5 Lac done in ELSS Mutual Funds are eligible for tax deduction under section 80C of the Income Tax Act."],
    },
    {
        highlighter: "What",
        question: "are some things to keep in mind before starting ELSS-Mutual Fund?",
        answer_in_steps: ["ELSS takes 5-7 years to stabilize. So not keeping a short time horizon could be beneficial for the investors. Instead of making it a short-term investment and making a withdrawal during maturity, It's advisable to let it grow as it is.", "As there is equity exposure, higher returns can be expected but however there is risk involved. Timing is important and one must ensure that it is utilized. "]
    },
    {
        highlighter: "How",
        question: "can you create an ELSS-Mutual Fund?",
        description: "To create an ELSS MF account, you can either purchase units directly from the AMC website or use the services of an online distributor like MoneyPlanned, etc that simplify the process for you. ",
    },
    {
        highlighter: "Tax",
        question: "Implication explained with an example?",
        examples: ["Example: Let’s say, an individual has ₹2 lakh disposable taxable income in a given financial year, and s/he decides to invest the amount in a MF Scheme. Only ₹1.5 lakh out of this amount would be eligible for deductions, reducing your taxable income in that year. Keep in mind that is applicable if you do not have any other tax saving investments allowed for deduction under Section 80(C) of the IT Act.", "You should also be aware of the capital gains tax that is applicable to returns generated by equity mutual funds. The returns from this fund are taxed like that from any other equity mutual fund scheme. However, since the units can’t be redeemed before 3 years of investment, only Long Term Capital Gains Tax (LTCG) of 10% on gains above ₹1 lakh will be levied.", "Suppose an investor has made a capital gain of ₹1.5 lakh on investment in this scheme at the time of redemption, LTCG of 10% would be levied on ₹50,000 in that financial year. ₹1Lakh in capital gains is exempted from taxation. The payable tax would be ₹5,000."]
    },
    {
        highlighter: "Who",
        question: "Should Invest in ELSS-Mutual Fund?",
        answer_in_steps: [" If you’re looking for equity investment avenues that will deliver significant returns in the long run with a tax advantage, you can opt for this fund.", "This scheme is suitable for investors with a long term investment horizon (preferably more than 3 years), as the fund has a minimum lock-in period of 3 years. Also, it has been observed that equity securities perform well in the long run, and this mandatory lock-in period ensures that the investors remain invested.", "As the underlying assets mostly comprise equity securities, which are quite volatile, it is important that the investor has a high risk appetite to invest in ELSS and a long term wealth creation goal.", "If you have already invested ₹1.5 lakh in various tax saving instruments under Section 80(C), it is advisable to opt for other equity funds that don’t have any lock-in period. Or you could even consider other tax saving instruments that can come under other sections to save tax for eg: health insurance for self/spouse/parents or National Pension System"]
    },
    {
        highlighter: "Quick Overview",
        question: "section",
        advantages:
            [
                {
                    info: 'What is the risk involved?',
                    details: 'Due to 80% equity exposure, they come with moderate to high risk.',
                },
                {
                    info: 'What returns can I expect?',
                    details: 'The returns are high and an added advantage is the tax benefits.',
                },
                {
                    info: 'What are the tax exemptions applicable?',
                    details: 'Under Section 80C, upto 1.5 Lakh INR tax can be exempted. ',
                },
                {
                    info: 'Time limit for investment?',
                    details: 'Lock-in period or maturity period is for 3 years',
                },
                {
                    info: 'How much can I invest?',
                    details: 'Investment upto Rs. 1,50,000 is possible',
                },
            ],
    },
];

export const elss_featfact = [{
    features:
        [
            {
                f_highlighter: 'High Returns',
                f_details: 'Despite the risk factor involved die to equity exposure, ELSS can earn greater returns compared to traditional savings instruments.',
            },
            {
                f_highlighter: 'Tax Examption',
                f_details: ' ELSS allows you to save taxes, as investment upto ₹1.5 lakh in these schemes is eligible for tax exemption',
            },
            {
                f_highlighter: 'Diversification',
                f_details: 'Investment portfolio of ELSS consists of balanced allocation to different asset classes such as equity and debt securities. Besides this, numerous funds diversify within the equity category as well, allocating assets to large cap, mid cap, small cap equity stocks.',
            },
            {
                f_highlighter: 'Professional Management of Investment',
                f_details: 'Being a mutual fund, it is managed by a Fund Manager and doesnt need an investor to possess any expertise.',
            },
            {
                f_highlighter: 'Disciplined Investment',
                f_details: 'Investment in ELSS requires a minimum lock-in of 3 years, which instills investment discipline amongst consumers either through the SIP route or lumpsum. ',
            },
        ],
},
{
    factors:
        [
            {
                ff_highlighter: 'Investment + Tax Planning',
                ff_details: 'Being the only type of mutual fund which invests in the equity market and offers tax benefits, many investors look at ELSS funds only for tax planning purposes. If saving tax is your sole purpose, then there are several options available under Section 80C of the Income Tax Act. Before investing in ELSS, you must ensure that you primarily create an investment plan to help you achieve your financial goals. This plan will naturally include tax planning. ELSS funds can be used to achieve your long-term goals.',
            },
            {
                ff_highlighter: 'SIP or Lumpsum',
                ff_details: 'Most resort to ELSS as a last-minute saving option. However, to invest lumpsum can be risky especially if you happen to invest at a time when the market is high. Since ELSS investments are usually linked with long-term financial goals, starting a systematic investment plan (SIP) can help you average your buying cost per unit.',
            },
            {
                ff_highlighter: 'Recommended Investment Horizon',
                ff_details: 'Many young investors prefer ELSS funds to PPF or NSC due to its short lock-in period of 3 years. This can be a counterproductive strategy since equity investments are known to take around 5-7 years to stabilize. Being inherently volatile, keeping a short-term investment horizon with ELSS funds should be avoided.',
            },
        ],
}
];
//done

//verified
export const ulip_about = [
    {
        highlighter: "What",
        question: "is a Unit Linked Savings Plan(ULIP)?",
        answer_in_steps: ["Unit Linked Savings Plan is a type of insurance which combines benefits of protection and savings in one plan.", "Units linked in the name ULIP means that there is exposure to the units of Mutual Fund. ", "In a Unit Linked Insurance Plan (ULIP), the premiums you pay are invested in the funds chosen by you after deducting allocation charges and charges including those for managing funds,policy administration and for providing insurance cover are deducted from the funds by cancelling certain units.The value of each unit of a fund is determined by dividing the total value of the fund's investments by the total number of units. "]
    },
    {
        highlighter: "Why",
        question: "should we opt for ULIP Scheme?",
        answer_in_steps: ["ULIP is both an insurance policy and investment at the same time. If the policy holder passes away during the tenure of the ULIP, the nominees can avail the death benefits of the insurance policy.  It gives the best of both worlds; returns of investments and life coverage of insurance. ", "It also has tax benefits under EEE tax exemption."],
    },
    {
        highlighter: "Things",
        question: "to keep in mind before starting ULIP? ",
        answer_in_steps: ["ULIP is meant for long-term investment. The real benefit of ULIP can be reaped after 15 to 20 years.", "Hence, it is recommended to stay invested for a longer time in order to beat market fluctuations and charges deducted will be compensated and a higher portion of the premium will be diverted into investments. ", "It is important to gauge the performance of the ULIP that you are investing in before you dive in. "]
    },
    {
        highlighter: "How",
        question: "can you open ULIP account? ",
        description: "To create a ULIP account, either go through an insurance agent or online through the insurance company website. ",
    },
    {
        highlighter: "Working",
        question: "of ULIP? ",
        answer_in_steps: ["ULIPs are products that provide you a combination of a life insurance policy and also an investment opportunity though a mutual fund in a single plan. Ulips are provided by life insurers so your payments to these companies when you buy a Ulip plan are called ‘premiums’ as primarily Ulips are more similar to insurance plans.", "A portion of your premium is diverted towards the investment bit, which is the mutual fund portion: equity, debt, hybrid or as the case may be. There are fund managers who look after your investments.", "You are also allowed to switch between different types of funds to make the best ULIP plan for yourself."]
    },
    {
        highlighter: "Lock-in-Period",
        question: "for ULIP",
        description: "A ULIP insurance plan comes with a lock-in period of five years. However, ULIP being a combination of a life insurance policy and a mutual fund, both of which are long term investments, should be held for 15 years or more.",
    },
    {
        highlighter: "Quick Overview",
        question: "section",
        advantages:
            [
                {
                    info: 'What is the risk involved?',
                    details: 'Equity or market exposure brings some risk with it but is compensated by the insurance life coverage.',
                },
                {
                    info: 'What returns can I expect?',
                    details: 'The returns are expected to be moderate',
                },
                {
                    info: 'What are the tax exemptions applicable on ELSS?',
                    details: 'EEE Tax Exemption',
                },
                {
                    info: 'Time limit for investment?',
                    details: '5 Years',
                },
                {
                    info: 'How much can I invest?',
                    details: 'Investment upto Rs. 1,50,000 is possible',
                },
            ],
    },
];

export const ulip_featfact = [{
    features:
        [
            {
                f_highlighter: 'Double Benefits – ULIP Plans Returns And Insurance Cover',
                f_details: 'When you choose to opt for a ULIP online or otherwise you get an insurance cover plus investment option. Through this you can avail family protection as well as good returns due to equity exposure. Its a great Investment for long-term goals.',
            },
            {
                f_highlighter: 'Lock-in period ensures discipline',
                f_details: 'A ULIP policy can come with lock-in periods of around five years, meaning that the investor must continue to add money into the policy for that time frame. The lock-in period encourages investors to save consistently and build their savings. After the lock-in period, investors can withdraw a part of their money if they want to or even discontinue the ULIP plan.',
            },
            {
                f_highlighter: 'ULIPs have a top-up option',
                f_details: 'In some cases, a policyholder can change the premium amount they’re putting into the ULIP plan and are not obligated to put a fixed amount each time. ULIP policies allow investors to “top up” or add extra funds to their existing investment amount.',
            },
            {
                f_highlighter: 'Newer ULIP plans don’t have transaction charges',
                f_details: 'ULIP plans are a smart and secure first step into investing. However, older policies still come with a number of transaction charges related to premium allocation, mortality change, and fund management. Before investing, ensure that the policy is newer and eliminates these charges after a few years. For example, the Future Generali Easy Invest Online Plan starts with a 5% premium allocation charge that reduces to 2.5% after two to five years. After six years, the charge is fully eliminated.',
            },
            {
                f_highlighter: 'Tax deductible under Section 80C',
                f_details: 'A ULIP policy is a tax deductible investment under Section 80C of the Income Tax Act 1961. This means that premiums of up to Rs 1 lakh are tax-free for the investor, making ULIP plans an attractive investor for first-timers. Even when the plan matures, the final amount is tax-deductible under Section 10 (10D) of the Income Tax Act 1961.',
            },
            {
                f_highlighter: 'ULIP plans have flexible investment options',
                f_details: 'A ULIP policy holder can choose to invest their premium in either equity options or debt or even a mixture of both, depending on their risk appetite. Someone who doesn’t mind high risk can choose ULIP investment options in equity funds while someone who is more cautious can invest in mutual funds. ',
            },
            {
                f_highlighter: 'strong long-term investments',
                f_details: 'Despite lock-in periods and transaction charges, ULIP policies are popular long-term investments. They require regular payments to remain active, teaching investors to be more disciplined while also increasing wealth. The lock-in period motivates investors to keep money in the market and ride out fluctuations with high returns, as well. A ULIP plan also allows investors to mix and match assets, making a diverse portfolio— Future Generali Future Opportunity Fund is one such plan.',
            },
            {
                f_highlighter: 'Different premium payment options',
                f_details: 'ULIP policies are famous for their flexibility that also includes the payment structure. Investors have three different choices when it comes to paying premiums: single premium plan where the full investment is paid in a lump sum, regular premium plan where a fixed amount can be deposited for the duration of the ULIP policy, and limited premium plan where the amount is paid for a certain number of years.',
            },
            {
                f_highlighter: 'potential for high returns',
                f_details: 'One of the best parts of ULIP plans is that the return on investment can be potentially very high— even in double digits. When the premiums are invested smartly, in different types of assets and in tax-saving funds, the investor reaps huge benefits. A ULIP policy can be profitable, tax-savvy investment. ',
            },
            {
                f_highlighter: 'Maturity dates can be deferred',
                f_details: 'Some ULIP plans allow the investor to defer their maturity date, meaning that the date at which the policy matures and the money can be fully withdrawn is extended to the future. The main benefit of having a policy that allows the extension of the maturity date is that an investor can minimise risk in case the date falls in a market slump or decline. If the ULIP policy matures when the markets improve, the investor will see higher returns in the end.',
            },
            {
                f_highlighter: 'ULIP policies help family planning',
                f_details: 'One of the most attractive aspects of a ULIP policy is that it offers insurance coverage and death benefits. So if the investor dies suddenly, their family can fall back on the ULIP and get financial security. ULIP plans are also good for family planning like retirement and children’s education, and any emergencies.',
            },
        ],
},
{
    factors:
        [
            {
                ff_highlighter: 'Dont let tax planning be the only motivator.',
                ff_details: 'A decision that arises solely from the need to save taxes often results in the purchase of an incorrect or unsuitable product. Have a clear purpose in mind before you make an investment.',
            },
            {
                ff_highlighter: 'Go in for a long-term investment horizon',
                ff_details: 'Once you stop paying the premium, the life cover lapses.  So if anything untoward happens to you only the residual value of the fund will be paid to your nominee, not the sum assured. Moreover, various charges continue to be deducted from the residual fund value until it ends and the policy expires.',
            },
            {
                ff_highlighter: 'Always opt for the maximum sum assured',
                ff_details: 'Most agents will tell you to go for the minimum sum assured, ostensibly to push up the amount that is invested and, hence, earn better returns. Instead, opt for the highest amount possible as the mortality charges are normally low and, in the long term, this move will not affect your corpus adversely. Remember that the policy should be able to support your family after your demise.',
            },
            {
                ff_highlighter: 'Choose investment options based on your risk appetite',
                ff_details: 'Most people choose the equity option indiscriminately without understanding the risks involved. Remember, ULIP is a market-linked instrument and a fixed return is not guaranteed. Look at the past performance of the various funds that are on offer before you make your selection. This information is usually available on the websites of the company. Also, stay clear of the plan if its funds have consistently underperformed the market.',
            },
            {
                ff_highlighter: 'Check the charges. These could derail your planning',
                ff_details: 'All Ulips come with a set of charges, such as the premium allocation charge, mortality charge, policy administration charge and fund management charge. It is critical to understand the quantum of each of these charges as they can substantially reduce the amount available for investment. For instance, the premium allocation charge is normally front-ended or higher in the first few years of the policy tenure.',
            },
        ],
}
];
// done

//verified
export const ssy_about = [
    {
        highlighter: "What",
        question: "is  Sukanya Samriddhi Yojana(SSY)?",
        answer_in_steps: ["SSY is a government backed scheme targeted at the parents of a young girl. It encourages them to build a fund for a girl’s future education and marriage expenses. ", "It is a small deposit scheme launched under the “Beti Bachao Beti Padhao” scheme. "]
    },
    {
        highlighter: "Why ",
        question: "should we opt Sukanya Samriddhi Yojana scheme?",
        answer_in_steps: ["It offers a high interest rate of 7.6% and the interest is tax-free.", "The interest is announced every year and is compounded annually.", "If you are the parent of a girl child below the age of 18, it would be a great savings option for you as it offers tax benefits as well as gives a high return", "It is a EEE investment and is eligible for deduction under Section 80C. "],
    },

    {
        highlighter: "Is Premature closure",
        question: "of the Sukanya Samriddhi account permitted?",
        description: "Premature closures are only allowed in the following circumstances:",
        answer_in_steps: ["Change in residence or citizenship status of the girl child.", "For marriage or higher education purposes of the girl child.", "Any circumstance in which the continuation of the account becomes difficult for the girl child", "Death of the girl child"],
    },
    {
        highlighter: "How",
        question: "can you open a SSY account?",
        description: "SSY accounts can be created with any bank. It can be created online or at a bank. If you are creating offline , then you will have to fill up the application form and submit it along with the following documents such as:",
        answer_in_steps: ["An account opening form duly filled in", "Birth certificate of the girl child.", "ID and address proofs of the depositor.", "Medical certificates as proof of birth of multiple girl children at once.", "Any other document as requested by the bank or post office.", "You will have to make an initial investment of at least ₹1,000 through cash, cheque, demand draft or online payment."],
    },
    {
        highlighter: "Eligibility Criteria",
        question: "to open a SSY account",
        description: "Eligibility criteria for the beneficiary (the girl child) and for those who will open and operate the account are listed as follow:",
        answer_in_steps: ["Only girl children can claim the benefits of this scheme.", "The girl child cannot be more than 10 years of age, although a grace period of one year is allowed. This means you can open an account for your daughter within a year of her turning ten years of age.", "You will have to submit your daughter's age proof documents.", "You can only open an account on behalf of your daughter if you are a biological parent or her legal guardian.", "Each parent or legal guardian can only open a maximum of two accounts."]
    },
    {
        highlighter: "Difference",
        question: "between SSY and PPF schemes",
        answer_in_steps: ["PPF and SSY are quite similar in many ways, yet different. So, would it be better if you open a PPF account for a minor child than an SSY account? The only major difference between the two is the interest rates, which is higher for SSY than that on PPF. SSY also has a slightly longer lock-in period of 21 years, unless the female beneficiary gets married before that.", "Tax benefits on both schemes are the same. You get an exemption up to Rs. 1.5 lakh under Section 80C in a year and interest earned, and final corpus too are exempt from tax. In short, both PPF and SSY have the same EEE (exempt, exempt, exempt) benefit.", "Can you use SSY as a substitute for PPF for your retirement savings? No, you cannot do this since the funds in the SSY account belong to the beneficiary after the age of 18.", "One thing to remember about SSY is that since the maturity period is 21 years, you might want to invest when the girl child is very young. If you start SSY at 10, your child will get the benefit only at 31, unless she’s married "]
    },
    {
        highlighter: "Quick Overview",
        question: "section",
        advantages:
            [
                {
                    info: 'What is the risk involved?',
                    details: 'No risk as it is a government backed scheme.',
                },
                {
                    info: 'What returns can I expect?',
                    details: 'The interest rate on the savings deposit is 7.6% and is compounded annually. The returns are good. ',
                },
                {
                    info: 'What are the tax exemptions applicable on ELSS?',
                    details: 'Tax benefits under Section 80 C as EEE investment and also the interest on your investment is also exempted from tax i.e, you do not have to pay any taxes on maturity or withdrawal.',
                },
                {
                    info: 'Time limit for investment?',
                    details: 'Lock-in period till 21 years or wedding; whichever is earlier.',
                },
                {
                    info: 'How much can I invest?',
                    details: 'Rs. 1,000 to Rs. 1,50,000 per year(lump sum or SIPs)',
                },
            ],
    },
];

//verified
export const ssy_featfact = [{
    // feature_name: ["Equity Linked Savings Scheme(ELSS)"],
    features:
        [
            {
                f_details: 'The account can be opened on behalf of the girl child by her parents or legal guardians.',
            },
            {
                f_details: 'A maximum of 2 accounts can be opened, and in the case of twins, a total of 3 accounts can be opened.',
            },
            {
                f_details: 'Minimum deposit amount = Rs.250 per year; Maximum deposit amount = Rs.1,50,000 per year.',
            },
            {
                f_details: 'High interest rate of 8.1% p.a. Note : Interest rates differ, year to year.',
            },
            {
                f_details: 'Tax benefits and advantages: Tax rebate under Section 80C of the Income Tax Act, 1961 for all deposits made under this scheme.',
            },
            {
                f_details: 'The account can be prematurely closed in the event of death of the depositor.',
            },
            {
                f_details: 'Revival of the account costs Rs.50, which has to be paid towards the end of the year in addition to the minimum amount for that year.',
            },
            {
                f_details: 'Deposits can be made through cash, cheque, and demand drafts.',
            },
            {
                f_details: 'Upon attaining the age of 18, the girl child in whose name the account has been created, can withdraw up to 50% of the balance',
            },
            {
                f_details: 'Account will mature after 21 years from the date on which the account was created, or on the date of marriage of the girl child in whose name the account was created.',
            },
            {
                f_details: 'All the accrued interest will be paid to the account holder (girl child) on maturity.',
            },
            {
                f_details: 'Minimum deposit of Rs.250 must be made each year to prevent the account from being deactivated.',
            },
            {
                f_details: 'In case of Transfer of accounts, you can transfer your Sukanya Samriddhi account balance to any post office or bank branch in the country or from a post office to a bank free of cost. You will need to submit address proof in this case. For transfers under other circumstances, you will be charged a fee of ₹100.',
            },
            {
                f_details: 'Only one account can be held for one girl child, and a maximum of two accounts can be opened in one family. You can open more than two accounts in case you have triplets (all girls) or in case the first child is a girl and twin girl babies are born later.',
            },
        ],
},
{
    factors:
        [
            {
                ff_details: 'SSY accounts should be opened by a natural or legal guardian of a girl child while she is below the age of 10 years. ',
            },
            {
                ff_details: 'The depositor can open only one account in the name of the girl child. Duplicate accounts cannot exist. ',
            },
            {
                ff_details: 'Natural or legal guardians are allowed to open an account for a maximum of 2 girl children. ',
            },
            {
                ff_details: 'Maturity of the investment is either when the girl attains the age of 21 years or when she gets married whichever is earlier. ',
            },
            {
                ff_details: 'Partial withdrawal is allowed upto 50% after the girl child attains 18 years',
            },
        ],
}
];
// done

// verified
export const nps_about = [
    {
        highlighter: "What",
        question: "is a National Pension Scheme(NPS)?",
        answer_in_steps: ["Like EPF and PPF, NPS is a voluntary contribution pension system of India. It is a EEE investment instrument where the entire investment escapes tax at maturity and the entire pension withdrawal is tax-free", "NPS is the go-to way to create a corpus for retirement as an important part of financial planning. It not only allows individuals to fulfil their expenditure requirements but also allows them to sail through their post-retirement life with the least hassles.", "To address this concern of the growing senior citizen demography in the country, the Indian Government thus introduced schemes like the National Pension System or NPS. The scheme allows for systemised savings during one’s working years, thus inculcating a financial discipline among individuals to save for the future."]
    },
    {
        highlighter: "Why",
        question: "should we opt for NPS?",
        answer_in_steps: ["Assured pension for life: Post-retirement, without a regular stream of income, handling daily expenses and dealing with inflation could be taxing. When you invest in NPS, you are ensuring that you keep getting a monthly income in the form of a pension for your entire retirement.As you turn 60, NPS matures, and 60 percent of the corpus is automatically transferred to your bank account. And you earn a monthly pension from the 40 percent of the corpus.", "In the unfortunate event of loss of life, NPS gives you the option of picking a pension plan that continues paying the pension amount to your spouse after your death. The pension will continue till they are alive.", "Automated risk reduction with age : NPS shields you against market volatility. Through this auto choice of asset allocation, the equity-debt allocation is adjusted to lessen risk. Then, as you cross, 35, every year, a part of your investments are moved from stocks to FD-like instruments.", "No commitment in investing: The minimum requirement is just Rs 1,000 per year. The best thing about NPS is flexibility. You can gradually increase the amount as it , this also acts as an advantage for the self-employed who don’t have steady income. They can put money in NPS whenever they have the extra money in hand."]
    },
    {
        highlighter: "Documents",
        question: "required to open NPS bank account?",
        answer_in_steps: ["Identity proof – PAN card mandatory", "Proof of residence – Aadhaar card/Passport/Voter ID/Ration card/Driving licence/Telephone bills", "Passport size photograph"],
    },
    {
        highlighter: "Tax Implications",
        question: "on NPS",
        answer_in_steps: ["Tax savings beyond Section 80C: Most taxpayers use the avenues available under Section 80C to save taxes. NPS provides an option to save tax over and above Section 80c.You can claim tax deductions for your NPS investments of up to Rs 50,000 under Section 80CCD (1B). This is over and above the deduction that you can claim under Section 80C for investing Rs 1.5 lakh. So you can save up to  Rs 15,600 additional tax every year. All of this is valid only for Tier 1 accounts.", "Tax benefits on the maturity amount:As a EEE investment, The three exemptions you get are – no tax on the amount invested, no tax on the returns generated and no tax on the maturity amount.In India, only a handful of products give you this EEE tax benefit and NPS is one of them", "Example : After 60 years of age if the total corpus created through National Pension System amounts to Rs. 20 Lakh, a lump sum withdrawal of 40%, i.e., Rs.8 lakh will not attract any tax. Further, if you utilise the remaining 60% of funds for annuity purchase, the entire corpus will be tax-free. Only that, income generated from the annuity will be taxable."],
    },
    {
        highlighter: "Quick Overview",
        question: "section",
        advantages:
            [
                {
                    info: 'What is the risk involved?',
                    details: 'Zero as it is regulated by the government',
                },
                {
                    info: 'What returns can I expect?',
                    details: 'The returns are moderate',
                },
                {
                    info: 'What are the tax exemptions applicable on NPS?',
                    details: 'EEE Investment, tax-free on maturity',
                },
                {
                    info: 'Time limit for investment?',
                    details: 'Up till retirement',
                },
                {
                    info: 'How much can I invest?',
                    details: 'Investment upto Rs. 1,50,000 is possible',
                },
            ],
    },
];
//done 

export const nps_featfact = [{
    features:
        [

            {
                f_highlighter: 'Provides Liquidity ',
                f_details: 'Account opening for NPS requires you to obtain a Permanent Retirement Account Number.There are two types of accounts: Tier-I account & Tier-II account ,As for Tier-II accounts, they are voluntary accounts providing liquidity of funds via investments and withdrawals. The minimum deposit one needs to make for a Tier II account is Rs. 250. However, investments in Tier-II accounts are allowed only when an active Tier I account in the subscriber’s name exists.Thus, as per the National Pension System architecture, individuals can subscribe to the National Pensions Scheme with PFRDA-appointed intermediaries via the two accounts mentioned above.',
            },
            {
                f_highlighter: 'Flexibility of investment via two different options',
                f_details: 'There are two modes that we invest in NPS: Auto choice , This is the default option for subscribers as per the system. Fund investments under this option are managed automatically by an appointed fund manager as per an investor’s age profile. Active choice , Under this option individuals are free to decide among available asset classes in which to invest their funds,  they can allocate different percentages of contributed funds to be invested in with a maximum cap of 50% for Asset Class E or Equities. Other Asset Classes include Class C, i.e., Corporate Debt Securities and Class G or Government Securities',
            },
            {
                f_highlighter: 'Option to make a partial withdrawal',
                f_details: 'Another of NPS scheme benefits includes an option to withdraw or access their contributions partially for emergencies. As per the rules regarding partial withdrawal, a subscriber can make withdrawals of their Tier I scheme contribution up to a maximum limit of 25%.Contributions up to a minimum of 10 years must be made for the partial withdrawal facility to apply. Also, there should be a minimum gap of 5 years between two consecutive withdrawals.',
            },
            {
                f_highlighter: 'Tax benefits',
                f_details: 'Up to 25% of Tier I contributions withdrawn by a subscriber are exempt from tax.Annuity purchase from National Pension Scheme corpus is tax-exempt. However, income generated from such annuity in the following years is taxable.Lump-sum withdrawal of up to 40% of an NPS corpus after a subscriber turns 60 is exempt from tax',
            },

        ],
},
{
    factors:
        [
            {
                ff_highlighter: 'Maturity period',
                ff_details: 'NPS is a long term investment instrument which means the maturity period is long. If an individual starts NPS contributions from age 30, they will have to keep contributing for another 30 years till age 60 which is a long-term commitment. ',
            },
            {
                ff_highlighter: 'Corpus only partially tax-free',
                ff_details: 'Under NPS, the maturity proceeds are only partially tax-free which means that it does not fall under EEE category. What you invest in NPS along with the interest earned on the amount, the corpus gets accumulated on maturity at the age of 60. As per recent changes, while a maximum of 60 per cent is allowed to be withdrawn tax-free on maturity, one has to pay tax on the balance compulsory pension.',
            },
            {
                ff_highlighter: 'Compulsory pension',
                ff_details: 'Since NPS is an investment scheme designed keeping retirement in focus, its rules do not allow full withdrawal of the corpus on maturity. Only 60 per cent can be withdrawn at the time of maturity and the rest 40 per cent of the corpus has to be invested in an Immediate Annuity scheme of a life insurance company. This pension will be paid by the life insurer depending on which pension option is chosen by the subscriber.',
            },
            {
                ff_highlighter: 'Equity allocation limit',
                ff_details: 'As per the existing guidelines, the maximum that NPS allows subscribers to invest in equities is 75 per cent of one’s contribution. This is also only till the age of 50. As one ages, the allocation to equities tapers off. If you are someone who wishes to allocate a higher percentage of your savings into equities for good but risky returns, you need to also consider the fact that the equity allocation is only allowed till the age of 50.',
            },
            {
                ff_highlighter: 'Passive investment',
                ff_details: 'In NPS, there is more of passive investment approach and the pension fund managers are to replicate the index in fund management. While this is not risky and does offer decent return, it does not have the potential to outperform the index, unlike active fund management which has the potential to outperform the index (it also carries an element of risk to underperform)',
            },
        ],
}
];
//done

// verified
export const scss_about = [
    {
        highlighter: "What",
        question: "is a Senior Citizen Savings Scheme(SCSS)?",
        answer_in_steps: ["Senior Citizens Savings Scheme is a government scheme for senior citizens , this scheme has been introduced to provide a steady stream of income post-retirement.", "It is a government-sponsored savings instrument for individuals above the age of 60. The Government of India introduced this scheme in 2004 intending to provide senior citizens with a steady and secure source of income for their post-retirement phase.", "It is one of the most lucrative savings schemes in India and offers comparatively substantial returns to its subscribers. The SCSS interest rate for April to June 2020 will be provided at 7.4%"]
    },
    {
        highlighter: "Why",
        question: "should we opt for Senior Citizen Savings Scheme",
        answer_in_steps: ["Investing in SCSS is a good opportunity for senior citizens above 60 years to make money. This is an effective and long-term saving option which offers security and added features that are usually associated with any government-sponsored savings or investment scheme."],
    },
    {
        highlighter: "How",
        question: "can you create a SCSS?",
        description: "SCSS accounts can be created with any bank. It can be created online or at a bank. ",
    },
    {
        highlighter: "Eligibility criteria",
        question: "to opt for SCSS?",
        description: "The following people/groups are eligible to opt for SCSS,",
        answer_in_steps: ["Senior citizens of India aged 60 years or above.", "Retirees who have opted for the Voluntary Retirement Scheme (VRS) or Superannuation in the age bracket 55-60. Here the investment has to be done within a month of receiving the retirement benefits.", "Retired defense personnel with a minimum age of 50 years.", "HUFs and NRIs are not allowed to invest in this scheme."]
    },
    {
        highlighter: "How",
        question: "can you open a SCSS account?",
        description: "To create a SCSS account, visit the nearest bank or post office with following documents.",
        answer_in_steps: ["Form A  has to be filled for opening an SCSS Account.", "Identity proof like PAN card, Passport to be presented.", "Address proof such as Telephone bill, Aadhar card is mandatory.", "Document for proof of age is required. This could be in the form of a Passport, Senior Citizen Card, a Birth certificate issued by the Corporation or Registrar of Births and Deaths, Voter ID card, PAN card etc.", "Passport size photographs", "All the above documents must be self-attested."]
    },
    {
        highlighter: "Quick Overview",
        question: "section",
        advantages:
            [
                {
                    info: 'What is the risk involved?',
                    details: 'No risk as it is a government backed scheme.',
                },
                {
                    info: 'What returns can I expect?',
                    details: 'The returns are high and stable ',
                },
                {
                    info: 'What are the tax exemptions applicable on ELSS?',
                    details: 'EEE investment and under Section 80C, tax benefits are available',
                },
                {
                    info: 'Time limit for investment?',
                    details: 'Lock-in period or maturity period is for 15 years and block for 5 years',
                },
                {
                    info: 'How much can I invest?',
                    details: 'A minimum of Rs. 500 and a maximum of Rs. 1.5 Lakhs can be invested per annum in lump sum or installments ',
                },
            ],
    },
];

export const scss_featfact = [{
    features:
        [
            {
                f_highlighter: 'Safe and Reliable',
                f_details: 'This is an Indian government-sponsored investment scheme and hence is safe. Minimum and maximum investment: You can invest a minimum of Rs. 1,000 and in multiples thereof. The maximum amount that can be invested is Rs. 15 lakh.',
            },
            {
                f_highlighter: 'Simple and easy process',
                f_details: 'The process to open an SCSS account is simple and can be opened at any authorized bank or any post office in India. It is also transferable across India.',
            },
            {
                f_highlighter: 'Good returns',
                f_details: 'At 7.4% the return rate is very good as compared to a savings or FD account',
            },
            {
                f_highlighter: 'Nomination',
                f_details: 'Nomination facility is available at the time of opening an SCSS account by means of submitting an application as part of Form C. This submission is also accompanied by the passbook to the Branch.',
            },
            {
                f_highlighter: 'Good returns',
                f_details: 'At 7.4% the return rate is very good as compared to a savings or FD account',
            },
            {
                f_highlighter: 'Tax benefits',
                f_details: 'Tax deduction of up to Rs.1.5 lakh can be claimed under Section 80C of the Indian Tax Act, 1961.',
            },

            {
                f_highlighter: 'Flexible',
                f_details: 'The tenure of this investment scheme is flexible with an average tenure of 5 years which can be extended up to 3 additional years.',
            },
            {
                f_highlighter: 'Tenure',
                f_details: 'The tenure of SCSS is five years, comparable to NSC and five-year bank fixed deposits. It can be extended by another three years.',
            },
            {
                f_highlighter: 'Premature withdrawal',
                f_details: 'You can withdraw the amount prematurely after a period of one year, on which you will have to pay a charge of 1.5 per cent of the deposit. If the withdrawal is done after two years, you will have to pay 1 per cent. Please note that you will forfeit your tax benefit if you close the account prematurely. The amount withdrawn will be added to your taxable income, and you will pay tax according to your income slab.',
            },
        ],
},
{
    factors:
        [

            {
                ff_details: 'The term of the scheme is 5 years and an extension of 3 years is allowed.',
            },

            {
                ff_details: 'Minimum age required is 55 years for those retiring on VRS or 60 years.',
            },
            {
                ff_highlighter: 'Offers Regular income',
                ff_details: 'The scheme pays interest quarterly at the rate of 8.3 percent. Assured pay-out at the end of each quarter is the biggest attraction of the scheme.the interest paid by the scheme is taxable in the hands of the investor based on the income tax slab. If the individual receives pension or some other annuity from his employer or an insurer and interest on other bonds and fixed deposits, he will see a rise in income if he opts for this scheme. This is especially true for government servants who receive pension.',
            },
            {
                ff_highlighter: 'Tax benefits and laddering',
                ff_details: 'Investments up to Rs 1.5 lakh is eligible for deduction under section 80C of the Income Tax Act. ',
            },
            {
                ff_highlighter: 'Tax deductible at source',
                ff_details: 'Since the interest income is taxable, an investor must take reasonable care about the tax deducted at source (TDS). If the total income is within the prescribed limit, one may choose to provide a 15H declaration to avoid TDS.'
            },
            {
                ff_highlighter: 'Penalty for premature withdrawal',
                ff_details: 'Since the deposit tenure is for five years, investors have to take a long term view. However, the same is not possible in old age. There are situations such as planned treatments, expenses on relocation or house renovation.In such a situation, if one decides to withdraw the proceeds, he/she will face penal charges. Withdrawals are allowed only after a year. After a year but before two years, if one decides to withdraw, the retiree will have to forgo 1.5 percent of the withdrawn amount. In case of withdrawal after two years, the penal charges are a percent of the amount withdrawn. If one decides to follow the staggered investment approach as mentioned above, the retiree sees an amount maturing every year.'
            },
        ],
}
];
//done

// verified
export const ppf_about = [
    {
        highlighter: "What",
        question: "is Public Provident Fund(PPF)?",
        answer_in_steps: ["PPF is a long-term investment scheme for those who want to earn high but stable returns. It comes under the small savings instruments categories. ", "It was initiated by the National Savings Institute of the Ministry of Finance in 1968. The aim of the scheme is to mobilize small savings by offering an investment with reasonable returns combined with income tax benefits. ", "Public provident fund is a popular investment scheme among investors courtesy its multiple investor-friendly features and associated benefits. It is a long-term investment scheme popular among individuals who want to earn high but stable returns. Proper safekeeping of the principal amount is the prime target of individuals opening a PPF account."]
    },
    {
        highlighter: "Why",
        question: "should we opt for PPF?",
        answer_in_steps: ["PPF is the best option for those with a low risk appetite. It is an EEE investment and has tax benefits under 80C. ", "Interest is announced every year and is compounded annually. The interest is tax free. The withdrawal is tax-free upto Rs. 1,50,000. ", "A minimum amount of Rs. 500 and a maximum of Rs. 1,50,000 every year is permitted either in lump sum or in installments. "],
    },
    {
        highlighter: "Eligibility Criteria",
        question: "to open a PPF account?",
        answer_in_steps: ["Any Indian citizen can invest in PPF.", "One citizen can have only one PPF account unless the second account is in the name of a minor.", "NRIs and HUFs are not eligible to open a PPF account. But however can continue their PPF accounts. "],
    },
    {
        highlighter: "How",
        question: "can you open a PPF account?",
        description: "One can create a PPF account with any bank. ",
    },
    {
        highlighter: "Documents",
        question: "required to open a PPF account offline?",
        answer_in_steps: ["An Identity proof (Voter ID/PAN Card/ Aadhar Card)", "A proof of residence.", "Passport size photographs.", "Pay-in-slip (available at the bank branch/post office)", "Nomination form."]
    },
    {
        highlighter: "Quick Overview",
        question: "section",
        advantages:
            [
                {
                    info: 'What is the risk involved?',
                    details: 'No risk as it is a government backed scheme.',
                },
                {
                    info: 'What returns can I expect?',
                    details: 'The returns are high and an added advantage is the tax benefits.',
                },
                {
                    info: 'What are the tax exemptions applicable on PPF?',
                    details: 'Under Section 80C, upto 1.5 Lakh INR tax can be exempted.',
                },
                {
                    info: 'Time limit for investment?',
                    details: 'Lock-in period or maturity period is for 15 years minimum',
                },
                {
                    info: 'How much can I invest?',
                    details: 'Rs. 500 to Rs. 1,50,000 per year',
                },
            ],
    },
];

export const ppf_FeatFact = [{
    features:
        [
            {
                f_highlighter: 'Easy to open an account ',
                f_details: 'You can open a PPF account at a bank branch or post office. The account can be transferred from post office to bank or vice versa. ',
            },
            {
                f_highlighter: 'Easy to maintain an account ',
                f_details: 'The PPF account can be maintained very easily. The minimum annual contribution is Rs. 500 and the maximum annual contribution is Rs. 1.5 lakh. ',
            },
            {
                f_highlighter: 'Transfer of account',
                f_details: 'The account can be transferred from one location to another location. You will be required to provide the necessary documentation to accomplish the change. If you have been transferred to another place by your employer, you should provide a copy of the transfer order and a written request to the bank branch to initiate the transfer.',
            },
            {
                f_highlighter: 'Investment tenure',
                f_details: 'A PPF account has a lock-in period of 15 years on investment, before which funds cannot be withdrawn completely. An investor can choose to extend this tenure by 5 years after lock-in period is over if required.',
            },
            {
                f_highlighter: 'Principal amount',
                f_details: 'A minimum of Rs. 500 and a maximum of Rs. 1.5 Lakh can be invested in a provident fund scheme annually. This investment can be undertaken in a lumpsum or installment basis. However, an individual is eligible for only 12 yearly instalment payments into a PPF account. Investment in a PPF account has to be made every year to ensure that the account remains active.',
            },
            {
                f_highlighter: 'Loan against investment',
                f_details: 'Public provident funds provide the benefit of availing loans against the investment amount. However, the loan will only be granted if it is taken at any time from the beginning of 3rd year till the end of the 6th year from the date of activation of account. The maximum tenure of such loans against PPF is 36 months. Only 25% or less of the total amount available in the account can be claimed for this purpose.',
            },

            {
                f_highlighter: 'Opening Balance',
                f_details: 'The account can be opened with just Rs 100. Annual investments above Rs 1.5 lakh will not earn interest and will not be eligible for tax saving.',
            },
            {
                f_highlighter: 'Deposit Frequency',
                f_details: 'Deposits into a PPF account has to be made at least once every year for 15 years.',
            },
            {
                f_highlighter: 'Mode of deposit',
                f_details: 'The deposit into a PPF account can be made either by way of cash, cheque, Demand Draft or through an online fund transfer.',
            },

            {
                f_highlighter: 'Nomination',
                f_details: 'A PPF account holder can designate a nominee for his account either at the time of opening the account or subsequently.',
            },
            {
                f_highlighter: 'Joint accounts',
                f_details: 'A PPF account can be held only in the name of one individual. Opening an account in joint names is not allowed.',
            },
            {
                f_highlighter: 'Risk factor',
                f_details: 'Since PPF is backed by the Indian government, it offers guaranteed, risk-free returns as well as complete capital protection. The element of risk involved in holding a PPF account is minimal.',
            },
        ],
},
{
    factors:
        [

            {
                ff_details: 'There is no joint account facility. ',
            },

            {
                ff_details: 'The maturity of the PPF is 15 years. Withdrawal can happen once in a financial year from the 7th year onwards. ',
            },
            {
                ff_details: ' PPF interest rates have been consistently on a downtrend. Although the rate has now been revised to 8% – effective from October 1 this year — from the earlier 7.6%, but this too is not very attractive currently. So, the Public Provident Fund is not the right product for people looking for higher returns. ',
            },
            {
                ff_details: 'PPF is not a great idea if you are looking at liquidity. The PPF account has a tenure of 15 years and the first withdrawal is permitted only after 7 years with limitations. Even loans can be availed against PPF only at the end of 3 years and that too partially. Therefore, PPF may not be for you if you are looking for an investment for a shorter term, say 2 to 3 years.',
            },
            {
                ff_details: 'PPF has limited utility as a family saving tool. PPF cannot be jointly owned. For example, you and your spouse cannot be joint owners of a PPF account. It has to be either in the name of the individual or it has to be held with minors for a joint account facility if at all. Two adults cannot be the joint holders of a PPF account. '
            },
            {
                ff_details: 'unlike most of the other investment avenues like equities and mutual funds, PPF is not open to NRIs. Of course, if you had a PPF when you were a resident Indian, then you can continue to contribute to the PPF account even after you become an NRI. But NRIs are not permitted to open a fresh PPF account. Also, trusts and HUFs are not permitted to invest in the PPFs.'
            },
            {
                ff_details: 'You cannot invest more than Rs 1.50 lakh in any financial year in the PPF account. Thus, if you want to invest more than Rs 150, 000 in any financial year in a single product, then PPF is not for you.'
            },
            {
                ff_details: ' Last, but not the least, if you are working in an organization and are already contributing to an EPF account as per statutory requirements, then your tax benefit will be limited on your PPF investment, but can continue with the investment.'
            },
        ],
}
];
//done

//verified
export const epf_about = [
    {
        highlighter: "What",
        question: "is Employee Provident Fund(EPF)?",
        answer_in_steps: ["The Employee Provident Fund (EPF) is a scheme that helps people save up a sufficient corpus for retirement. ", "The plan was introduced with the Employees' Provident Funds Act in 1952 and is today managed by the Employees' Provident Fund Organisation (EPFO).", "EPF is a kind of provident fund, like PPF. It helps employees save up enough corpus for their retirement. ", "It is most usually provided by the employer, in most corporates as well as PSUs. However, one may opt for it independently as well. ", "It is a great investment option for those looking to accumulate enough wealth for enjoying a plush retirement.", "In this scheme, an employee has to contribute 12% of their basic income towards the fund every month. The employer matches this amount with an equal contribution. When you retire, you receive the total amount (personal as well as the employer's contribution) as a lump sum along with interest. The EPF is regarded as a low-risk investment as the Government of India manages it and assures a fixed rate of return."]
    },
    {
        highlighter: "Why",
        question: "should we opt for EPF?",
        answer_in_steps: ["ELSS is the best investment for salaried employees as well as first-time investors. ", "ELSS has many benefits over the traditional tax saving deposits like FD, NPS, etc. It has the lowest lock-in period for only 3 years. ", "Investments of up to 1.5 Lac done in ELSS Mutual Funds are eligible for tax deduction under section 80C of the Income Tax Act."],
    },
    {
        highlighter: "Eligibility Criteria",
        question: "to open an EPF Account?",
        answer_in_steps: ["The Employee Provident Fund is open for employees of both the Public and Private Sectors, which means all employees can apply to become a member of EPF India.", "Additionally, any organisation that employs at least 20 individuals is deemed liable to extend benefits of EPF to its employees.", "When an employee becomes an active member of the scheme, they are considered eligible to avail several benefits in the form of Employees Provident Fund benefits, insurance benefits and pension benefits."],
    },
    {
        highlighter: "Voluntary Provident Fund (VPF)",
        question: "and its significance",
        description: "is a voluntary fund contribution you make towards your provident fund account. This contribution is beyond the 12% contribution you make towards your EPF. You can make a maximum contribution up to 100% of your Basic Salary and Dearness Allowance and earn interest at the same rate as that of the EPF. However, the employer does not have to match this voluntary contribution."
    },
    {
        highlighter: "Calculating interest",
        question: "on your EPF account",
        description: "The interest extended on EPF schemes is calculated each month and is calculated by dividing the rate p.a. by 12,  Such a method helps to calculate the specific interest that is offered to member employees for a given month.",
        examples: ["If the rate of interest is 8.55% p.a. the rate for each month would be (8.55/12) %, i.e. 0.7125%.", "Now, 12% of an individual’s salary is directed towards their EPF account.",
            "Assuming that the salary of an individual is Rs. 15,000 per month-12% of Rs. 15,000 would accrue Rs. 18, 00 by month-end which would be transferred to the individual’s EPF account.",
            "Now, employers contribute 3.67% towards their EPF account, while 8.33% is contributed towards their EPS account.", "The contribution towards EPF account would be –3.67% of Rs. 15,000 = Rs. 550.", "The total contribution towards the EPF account would stand at Rs. (1800+550) = Rs.2350", "The interest accrued in one month would be Rs. 2350 x 0.7125% = Rs. 16.75", "It is to be noted that the interest accrued in a given month would only be credited to the account at the end of a current financial year."],
    },
    {
        highlighter: "Rate of Interest",
        question: "offered in EPF Scheme",
        description: "For the Financial year 2019-2020, the pre-fixed rate of interest offered by the EPF scheme is 8.55%. The interest amount accrued on the investments in a PF online account is tax-free."
    },
    {
        highlighter: "How",
        question: "can you open an EPF account",
        answer_in_steps: ["It is usually created by the employer. A UAN is generated at the time of account creation. UAN stands for Universal Account Number to be allotted by EPFO. The UAN will act as an umbrella for the multiple Member Ids allotted to an individual by different establishments.", "When an employee changes his job, his EPF account number and member ID changes but UAN remains the same. In the event of allotment of two UANs, the member should inform his employer as well as EPFO instantly so that old UAN can be deactivated and the previous EPF corpus is transferred to the new PF account."]
    },
    {
        highlighter: "Checking Balance ",
        question: "of Employee Provident Fund",
        description: "A member can check the EPF balance accumulated in the account online by following ways:",
        answer_in_steps: ["Missed call - EPF balance can be checked through a missed call on the number- 011-22901406.", "Text Message - The member can also check his EPF balance by sending an SMS to 7738299899 in the format EPFOHO <UAN> ENG.", "Visit EPF’s website at www.epfindia.gov.in", "Go to “For Members” in the “Our Services” section", "Click on the - Member Passbook option", "Now enter your - UAN, password and captcha code and login to your EPF account", "Select the - Member ID to view your passbook", "Your passbook will be displayed with complete details in the document.", "or Login to https://passbook.epfindia.gov.in/MemberPassBook/Login , Enter the Login details and check the Balance"]

    },
    {
        highlighter: "Quick Overview",
        question: "section",
        advantages:
            [
                {
                    info: 'What is the risk involved?',
                    details: 'Due to 80% equity exposure, they come with moderate to high risk. ',
                },
                {
                    info: 'What returns can I expect?',
                    details: 'The returns are high and an added advantage is the tax benefits. ',
                },
                {
                    info: 'What are the tax exemptions applicable on EPF?',
                    details: 'Under Section 80C, upto 1.5 Lakh INR tax can be exempted. ',
                },
                {
                    info: 'Time limit for investment?',
                    details: 'Lock-in period or maturity period is for 3 years',
                },
                {
                    info: 'How much can I invest?',
                    details: 'Investment upto Rs. 1,50,000 is possible',
                },
            ],
    },
];

export const epf_FeatFact = [{
    features:
        [
            {
                f_highlighter: 'Capital appreciation',
                f_details: 'The PF online scheme offers a pre-fixed interest on the deposit held with the EPF India. Additionally, rewards extended at maturity further ensure growth in the employees’ funds and accelerate capital appreciation.',
            },
            {
                f_highlighter: 'Corpus for Retirement',
                f_details: 'Around 8.33% of an employer’s contribution is directed towards the Employee Pension Scheme. In the long run, the sum deposited towards the employee provident fund helps to build a healthy retirement corpus. Such a corpus would extend a sense of financial security and independence to them after retirement.',
            },
            {
                f_highlighter: 'Emergency Corpus',
                f_details: 'An EPF fund acts as an emergency corpus when an individual requires emergency funds during uncertainties.',
            },
            {
                f_highlighter: 'Tax-saving',
                f_details: 'Under Section 80C of the Indian Income Tax Act, en employee’s contribution towards their PF account is deemed eligible for tax exemption. Moreover, earnings generated through EPF scheme are exempted from taxes. Such exemption can be availed up to a limit of Rs. 1.5 Lakh.',
            },
            {
                f_highlighter: 'Easy premature withdrawal',
                f_details: 'Members of EPF India are entitled to avail benefits of partial withdrawal. Individuals can withdraw funds from their PF account to meet their specific requirements like pursuing higher education, constructing a house, bearing wedding expenses or for availing medical treatment',
            },
            {
                f_highlighter: 'Long-Term Financial Security',
                f_details: 'Funds that are deposited in this kind of account cannot be easily withdrawn by the person, and therefore, it helps to ensure savings.',
            },
            {
                f_highlighter: 'Resignation/ Quitting the Job',
                f_details: 'The employee can withdraw 75 per cent of the EPF fund after resignation after a month of the date he/she quit the job. The remaining 25 per cent can be taken 2 months after being unemployed.',
            },
            {
                f_highlighter: 'Death',
                f_details: 'In case the employee passed away, the collected amount including the interest is given to the nominee of the employee. These epf benefits after death will help the family get through the difficult times.',
            },
            {
                f_highlighter: 'Retirement Period',
                f_details: 'The fund that is accumulated under the scheme can be used by the employee at the time of his retirement. In the form of monetary security, this scheme will provide relief to the employee that is retired. Almost everybody will find the epf retirement benefits very useful.',
            },
            {
                f_highlighter: 'Pension Scheme',
                f_details: 'The employer also makes necessary contributions towards the pension of the employee and contributes towards the PF fund which can be used by the employee after retirement. Epf pension benefits are very useful.',
            },
            {
                f_highlighter: 'Accessibility',
                f_details: 'When you have the Universal Account Number (UAN), the employees will be able to access their PF account easily via the EPF member portal. This way they will be able to transfer their accounts every time they want to shift their current jobs.',
            },
            {
                f_highlighter: 'Insurance scheme',
                f_details: 'This act provides for some provisions where the employer of the organization is required to contribute towards the life insurance of the employee, but the group insurance over will not be present. This scheme thus makes sure that all the employees are insured properly.',
            },
            {
                f_highlighter: 'Special Occasions',
                f_details: 'When you need money for special occasions like for a wedding, education for children, self, or sibling, then you can withdraw up to 50 per cent contributions from your account. The member is allowed these benefits for three times. To access these benefits, the member must have served for at least a time period of seven years. The members should also have the proper documents for the events and also members can choose from their account for maintenance of a house, house repair, or for the repayment of loans you might have taken to get the house.',
            },
        ],
},];

export const epf_working = [
    {
        question: "Employee Provident Fund",
        point: "The Employees’ Provident Fund is a fund where both the employer as well as the employee contributes a part of the salary. These contributions are made regularly on a monthly basis. The interest rate fixed depends upon the employee’s basic pay along with the dearness allowance in his salary. Here is a breakup of the EPF Contributions, ",
    },
    {
        point: "**12% Employer’s contribution includes 3.67% EPF and 8.33% EPS For Example, If the monthly salary of a person is Rs.30,000. The contributions calculated are as follows -",
        sub_points: ["12% of Rs.30,000 (Employee share)= Rs.3,600", "3.67% (in EPF) of Rs.30,000 (Employer’s share)= Rs.1,101", "8.33% (in EPS) of Rs.30,000 (Employer’s share)= Rs.2,499", "Which is a Total of Rs.7200/- "],
    },
    {
        point: "Let's understand Employee’s Contribution towards EPF. In general, the contribution rate for the employee is fixed at 12%. However, the rate is fixed at 10% for the below-mentioned organizations -> ",
        sub_points: ["Organizations or firms employing a maximum of 19 workers.", "Industries declared as sick industries by the BIFR", "Organizations suffering annual loss much more as compared to their net value.", "Coir, guar gum, beedi, brick and jute industries.", "Organizations operating under wage limit of ₹ 6,500."]
    },
    {
        point: "Now Let's understand Employer’s Contribution towards EPF. The minimum amount of contribution to be made by the employer is set at a rate of 12% of ₹ 15,000 (although they can voluntarily contribute more). This amount equals to ₹ 1800 per month. It means that both the employer as well as the employee has to contribute ₹ 1800 each per month towards this scheme. Initially, this amount was set at 12% of ₹ 6,500 which would equal to ₹ 780 to be contributed both by the employer and the employee.",
        sub_points: ["The contribution from both the parties is deposited into the EPFO (Employees Provident Fund Organisation)", "This is a long-term investment fund for the contributors which helps them continue an independent life after retirement", "EPF also provides its contributors the loan facility in need"]
    },
];

export const epf_withdraw = [
    {
        question: "Under what circumstances can EPF be withdrawn & the process of EPF withdrawal?",
        point: "Individuals may opt for either partial or complete withdrawal of EPF. But such withdrawals can be made only under specific circumstances.Here is a list of a few such circumstances under which individuals can withdraw EPF completely –",
        sub_points: ["On retirement.", "If their unemployment period extends more than two months.", "While switching from one profession to another or in between jobs. But the duration without a job should be more than two months."]
    },
    {
        point: "Here is a list of a few such circumstances under which individuals can withdraw EPF partially –",
        sub_points: ["For a wedding.", "For higher education.", "For purchasing land or constructing a house.", "Repayment of home loan.", "Renovating a housing property."]
    },
    {
        point: "EPF India members can withdraw EPF by submitting a withdrawal application either offline or through EPF online portal.For offline submission –",
        sub_points: ["Individuals need to fill up a ‘new composite claim form’ or a ‘composite claim form” and submit the same to the EPFO office under their jurisdiction.", "A composite claim form needs to be attested by their employer."],
    },
    {
        point: "For online submission –",
        sub_points: ["Individuals must have an active Universal Account Number (UAN).", "The mobile number used to activate the UAN should be active as well.", "UAN should be linked to be linked with Aadhaar. They would also need the PAN and respective bank details with its IFSC code.", "After ensuring all the prerequisites are in place, they need to login into the UAN online portal.", "Individuals need to verify their KYC details and then proceed as per instructions."],
    },

];
// verified
export const nsc_about = [
    {
        highlighter: "What",
        question: "is National Savings Certificate(NSC)?",
        description: "A Government of India initiative, the National Savings Certificate is a fixed income investment scheme that you can open easily with any post office. It is a savings bond scheme that encourages subscribers primarily small to mid-income investors to invest while saving on income tax under Section 80C.However, they cannot deliver inflation-beating returns like tax saving Mutual Funds and National Pension Systems. "
    },
    {
        highlighter: "Why",
        question: "should we opt for NSC?",
        answer_in_steps: ["The interest rate is 7.9% per annum and compounded annually, accumulated and paid on maturity. ", "As a government-backed tax-saving scheme, the principal invested in NSC qualifies for tax savings under Section 80C of the Income Tax Act up to Rs. 1.5 lakhs annually."]
    },
    {
        highlighter: "Who",
        question: "should consider investing in NSC?",
        description: "This investment scheme, with its stable return on NSC interest rates, is best for low and average income earners. Individuals who do not prefer their investments to be subjected to market fluctuations and yet generate substantial returns should opt for this scheme. With a low investment amount for savings, any disposable amount over Rs. 100 that an earner has can be invested in these certificates."
    },
    {
        highlighter: "Eligibility Criteria",
        question: "to open for NSC?",
        description: "While eligibility criteria for NSC simply require an investor to be resident Indian without any age limit, the necessary documents to save in NSC must be noted. Also, the scheme does not outline age requirement as eligibility, thus allowing individuals from all age groups to benefit from the plan.",
    },
    {
        highlighter: "How",
        question: "can you create NSC",
        description: "Purchased from all Public Sector Banks and top three Private Banks (ICICI, HDFC & Axis)"
    },
    {
        highlighter: "Documents",
        question: "required to create NSC",
        answer_in_steps: ["Filled NSC application form.", "Investors to provide an original identification proof such as Passport, Permanent Account Number (PAN) Card, Voter ID, Driving licence, Senior Citizen ID, or Government ID for verification,", " Photograph", "Address proof like electricity bill, Passport, telephone bill,, bank statement along with a cheque."]

    },
    {
        highlighter: "Withdrawal",
        question: "of NSC investments",
        description: "According to the withdrawal rules, the NSC investments cannot be withdrawn before the maturity period. The maturity period can 5 years or 10 years as the case may be. If the amount invested is withdrawn within a year, interest will not be paid. Plus, a penalty will be also be charged to the certificate holder. However, premature withdrawal is allowed in the following cases:",
        answer_in_steps: ["The certificate holder passes away", "On the forfeiture of the certificate, only if the pledgee is a Gazetted Government Officer.", "The entire amount invested can be withdrawn if ordered by the court of law"]

    },
    {
        highlighter: "Quick Overview",
        question: "section",
        advantages:
            [
                {
                    info: 'What is the risk involved?',
                    details: 'Being a government backed scheme, the risk is zero.',
                },
                {
                    info: 'What returns can I expect?',
                    details: 'The returns are moderate but there is no hedge against inflation. ',
                },
                {
                    info: 'What are the tax exemptions applicable on EPF?',
                    details: 'Investment eligible for deduction under 80C and interest is taxable. ',
                },
                {
                    info: 'Time limit for investment?',
                    details: 'Lock-in period or maturity period is for 3 years',
                },
                {
                    info: 'How much can I invest?',
                    details: 'Investment amount is Rs. 100, 500, 1000, 5000, 10000',
                },
            ],
    },
];

export const nsc_FeatFact = [{
    features:
        [
            {
                f_highlighter: 'Interest Rates',
                f_details: 'The certificates earn an annual fixed interest, which is currently at a rate of 7.9% per annum (revised every quarter by the government), thus guaranteeing a regular income for the investor.',
            },
            {
                f_highlighter: 'Maturity period',
                f_details: 'The scheme originally had two types of certificates – NSC VIII Issue (5 year tenure) and NSC IX Issue (10 year tenure). With the discontinuation of the latter one in December 2015, only the former issue is available for subscription.',
            },
            {
                f_highlighter: 'Tax saver',
                f_details: 'As a government-backed tax-saving scheme, the principal invested in NSC qualifies for tax savings under under Section 80C of the Income Tax Act up to Rs. 1.5 lakhs annually.'
                ,
            },
            {
                f_highlighter: 'Investment Flexibility',
                f_details: 'You can invest as small as Rs. 100 as an initial investment with no maximum limit.',
            },
            {
                f_highlighter: 'Accessible',
                f_details: 'It can be easily bought from any post office on submission of required KYC documents. Also, it is easy to transfer the certificate from one PO to another as well as from one person to another without impacting interest accrual/maturity of the original certificate.',
            },
            {
                f_highlighter: 'Loan collateral’s',
                f_details: 'NSC certificates are accepted as collateral or security for secured loans in Banks and NBFCs. In such a case, a transfer stamp is put on the certificate and transferred to the bank while disbursing loans',
            },
            {
                f_highlighter: 'Power of compounding',
                f_details: 'Interest earned gets compounded annually and reinvested by default but will be payable only at maturity.',
            },
            {
                f_highlighter: 'Nomination',
                f_details: 'Investor can nominate any family member (even a minor) so that they can inherit it in the case of an unfortunate event of the investor’s demise.',
            },
            {
                f_highlighter: 'Corpus on maturity',
                f_details: 'The investor will receive the entire corpus value on maturity. As there is no TDS on NSC payouts, the subscriber should pay the applicable tax on it while filing his Income tax returns or paying his advance tax.',
            },
            {
                f_highlighter: 'Premature withdrawal',
                f_details: 'Generally, one cannot exit the scheme early except on the death of investor, or on a court order, or on forfeiture by a pledgee who is a Gazetted Government Officer for it.',
            },
        ],
    factors:
        [

            {
                ff_details: 'The NSC offers guaranteed interest and complete capital protection. However, they cannot deliver inflation-beating returns. Only Indian Individuals can invest in NSC. ',
            },

            {
                ff_details: 'Amounts need to be invested in Rs. 100, 500, 1000, 5000, 10000. Premature withdrawal is not accepted unless in the event of death or order of court.  ',
            },
            {
                ff_details: 'They are available for a maturity period of 5 years and 10 years'
            }
        ],
},];

export const potd_about = [
    {
        highlighter: "What",
        question: "is Post Office Term Deposits(POTD)?",
        description: "India Post, the trade name of the Department of Posts, has been operational since 1854. With more than 1.55 lakh offices throughout India, it is the most widely distributed post network in the world.",
        answer_in_steps: ["Apart from delivering mails, India Post offers the following – Small savings schemes, Life insurance , Instant money order, Electronic money order ,Mutual funds, Money transfer services", "Under small savings schemes, it offers the post office time deposit or National Savings Time Deposit Account. It is similar to a bank fixed deposit with a few elemental differences.", "Individuals can open a time deposit account by visiting the nearest post office or by using the official website or app of India Post.", "Indian Posts offer term deposits that are similar to bank deposits. Term deposit is a deposit with a fixed maturity and hence the word “term”. "]
    },
    {
        highlighter: "Why",
        question: "should we opt for POTD?",
        answer_in_steps: ["POTD is a safe investment and the interest is payable annually although calculated quarterly.", "Under Section 80C, the investment has tax benefits. The interest is taxable.", "The term could be 1, 2, 3, or 5 years and hence the choice of the term lies in the hands of the investors."]
    },
    {
        highlighter: "Who",
        question: "should invest in POTD?",
        answer_in_steps: ["Investors who are looking for alternatives to Bank Fixed Deposits may consider investing in Post Office Time Deposit Schemes as the latter offers higher interest rates than the ones offered in fixed deposits.", "Additionally, if you are an ultra conservative investor with zero tolerance of risk and assured returns, you may choose to invest in these schemes."]
    },
    {
        highlighter: "Eligibility Criteria",
        question: "to open a POTD account",
        description: "POTD Account may be opened by",
        answer_in_steps: ["A single adult", "Joint Account with a maximum of three adults.", "A minor aged 10 years or more."]
    },
    {
        highlighter: "How",
        question: "can you open POTD account",
        answer_in_steps: ["To create a Post Office Term Deposit, either visit a Post office nearby or you may either try the website of the Indian Postal System.", "Post office savings account holders with internet banking facility can also open the term deposits online. "]
    },
    {
        highlighter: "Documents",
        question: "required to open an POTD account",
        answer_in_steps: ["A deposit-opening form provided by the post office", "Address and identity proof such as the Aadhaar card; copy of the passport; PAN (permanent account number) card or declaration in the Form 60 or 61 as per the Income Tax Act, 1961; driving licence; voter's ID; or ration card", "Carry original identity proof for verification at the time of account opening", "Choose a nominee and get a witness signature to complete the formalities"]
    },
    {
        highlighter: "Premature Withdrawal",
        question: "of POTD Funds",
        description: "Post office time deposit account permits its account holders to withdraw funds even before its maturity. The only applicable condition is that a minimum of 6 months must have been passed from the date of first deposit in order to qualify for premature withdrawal. The following are key terms and conditions in case of premature withdrawal of a Time Deposit :",
        answer_in_steps: ["If premature withdrawal of 1/2/3 or 5 year POTD is made after completion of 6 months but before completion of 1 year from date of time deposit account opening, simple interest is payable as per Post Office Savings Account interest rate", "If premature withdrawal of 1/2/3 or 5 year TD account is done after 1 year from date of account opening, the applicable interest rate is 1% lower than the interest rate corresponding to the tenure the account was originally booked for"]
    },
    {
        highlighter: "Quick Overview",
        question: "section",
        advantages:
            [
                {
                    info: 'What is the risk involved?',
                    details: 'Being govt-backed, the risk is zero.',
                },
                {
                    info: 'What returns can I expect?',
                    details: 'The returns are moderate.',
                },
                {
                    info: 'What are the tax exemptions applicable on EPF?',
                    details: 'Under Section 80C, tax benefits can be availed',
                },
                {
                    info: 'Time limit for investment?',
                    details: 'Lock-in period or maturity period could be 1, 2,3 or 5 years',
                },
                {
                    info: 'How much can I invest?',
                    details: 'Minimum of Rs. 200 and maximum is unlimited',
                },
            ],
    },
];
// done

export const potd_FeatFact = [{
    features:
        [
            {
                f_highlighter: 'Eligibility and joint accounts',
                f_details: ['Any individual above the age of 10 can open a time deposit account at any post office. Further, guardians can open an account on behalf of a minor. However, the minor has to apply for ownership of the account after he or she reaches the requisite age.', 'Accounts can also be held jointly by up to 3 individuals. Depositors can also nominate a person before or after opening an account.', 'One of the primary benefits of post office time deposits is that individuals can create multiple accounts without any restriction. Depositors also have the freedom to transfer their accounts from one post office to another.']
            },
            {
                f_highlighter: 'Multiple lock-in periods',
                f_details: 'Depositors get the option to open a time deposit account for 1, 2, 3, and 5 years. However, account tenure can be extended by giving a formal application to the post office.',
            },
            {
                f_highlighter: 'Income tax benefits',
                f_details: 'Income tax benefits are available only for a 5-year post office time deposit account. Depositors will be able to claim income tax exemptions of up to Rs.1.5 lakh under Section 80C of the Income Tax Act, 1961.',
            },
            {
                f_highlighter: 'Transfer of rates to other accounts',
                f_details: ['The interest can be transferred to the depositor’s post office savings account with the same post office.', 'It can also be transferred to the depositor’s National Savings Recurring Deposit Account or 5-year post office recurring deposit account as the entire year’s deposit. RD account holders will have to make a formal application every year before the time deposit interest is credited for this transfer to process.'],
            },
            {
                f_highlighter: 'Payment of interest ',
                f_details: 'The interest, along with the principal, will be paid either in cash or cheque. However, payments higher than Rs.20,000 will only be made with a cheque.',
            },
            {
                f_highlighter: 'Applicability of interest post maturity ',
                f_details: 'Those who do not withdraw the sum after the maturity of a time deposit account will not receive any additional interest. However, in the case of post offices with core banking solutions, the time deposit will get renewed for the same period it was originally created. The post office time deposit interest rate at the time of maturity will be applicable after renewal.',
            },
            {
                f_highlighter: 'Low minimum deposit amount',
                f_details: ['The minimum amount required to open a National Savings Time Deposit Account is Rs.1,000. Individuals can deposit in multiples of Rs.100 thereof. There is no limit for maximum investment', 'Individuals can make the initial deposit in cash or cheque. In case the payment is made by cheque, the date of encashment will be set as the account opening date.',]
            },
            {
                f_highlighter: 'Premature withdrawal ',
                f_details: 'A National Savings Time Deposit Account does not allow premature withdrawal within the first 6 months. The post office term deposit rate if the corpus is prematurely withdrawn between 6 months and 12 months will be in adherence to the rate prescribed for a savings account.',
            },
        ],
},];
// verified

// verified
export const pord_about = [
    {
        highlighter: "What",
        question: "is Post Office Recurring Deposits(PORD)?",
        description: "Post Office Recurring Deposits are deposits that allow you to save regularly on a monthly basis. ",
        answer_in_steps: ["Typically, a post office RD is among the most popular savings alternative to traditional fixed deposits and other long-term schemes that are offered by post offices.", "Post Office RD Scheme is one among the 9 small savings schemes which are backed by the Government of India. The said scheme serves as mid-term investment options; wherein, investors are required to keep their deposits active for at least 5 years.", "Being risk-free, the scheme serves as an opportunity for both conservative and inexperienced investors to park a fixed sum of funds at regular intervals. Individuals accrue interest on their deposit which is compounded quarterly."]
    },
    {
        highlighter: "Why",
        question: "should we opt for Post Office RD?",
        answer_in_steps: ["The 5 year Post Office Recurring Deposit (PORD) scheme allows you to save on a regular monthly basis for 5 years i.e. 60 monthly installments. These deposits earn interest as per applicable rate compounded on a quarterly basis. It is ideal for individuals with regular income who wish to stock up their savings through regular monthly deposits of a fixed amount for a certain period of time.", "The security that comes with investing in an RD account in post office and the opportunity to earn compounding interest on the same makes this scheme feasible.", "Individuals who earn a fixed income but want to generate wealth over time may consider the Post Office RD Scheme effective and suitable."]
    },
    {
        highlighter: "Interest rate",
        question: "offered for Post Office RD?",
        answer_in_steps: ["The rate of interest offered on the RD scheme in post office in 2019 is 7.2% p.a. Such interest rate makes it one of the most preferred investment options available.", "Additionally, the compound interest extended at each quarter is a much-desired perk of the scheme. It ensures that individuals would have a strong corpus at their disposal by the time it matures.Such a feature would directly help in wealth creation and would ensure financial stability for later years."],
        examples: ["Example, Mr. G invests Rs. 6,000 into his Post Office RD at the rate of 7.2% p.a. for 60 months. The sum accrued at maturity would stand at, A= P x (1+R/N) ^ (Nt)", "Here, A= Maturity Amount, P= Recurring deposit, N= Number of times the interest is compounded, R= Rate of interest, t= Tenure", "At the end of 60th month,  He would had earned A= P x (1+R/N) ^ (Nt) = Rs. 4,34,379."]
    },
    {
        highlighter: "Taxation applicable",
        question: "on Post Office RD account",
        answer_in_steps: ["An RD account in the post office falls under the tax exemptions umbrella as per Section 80C.", "Individuals can claim up to Rs. 1.5 Lakh as per annum tax exemption under this section.", "However, the interest generated through the post office RD scheme is liable for taxation.", " Individuals need to pay a tax amount as per their income tax slab. Additionally, an interest that exceeds Rs. 10,000 would be liable for a TDS deduction. ", "Individuals who have an active PAN would pay TDS at the rate of 10%, while those without one would pay the same but at the rate of 20%."],
    },
    {
        highlighter: "Eligibility Criteria",
        question: "to open a Post Office RD account",
        description: "Individuals who want to open an RD account in Post Office must meet these criteria ",
        answer_in_steps: ["Indian Nationals who are over the age of 18 years.", "Minors who are over the age of 10 years.", "Parents or guardians who wish to open and operate the account of a minor on their behalf.", "Indian nationals above the age of 18 years would be deemed eligible to operate an RD account in Post Office either singly or jointly.", "However, a minor above the age of 10 years would be able to operate their account jointly with their guardian."]
    },
    {
        highlighter: "Things",
        question: "to keep in mind before starting Post Office RD",
        answer_in_steps: ["PORD is held individually or jointly.", "Nomination facility is available. ", "The minimum investment is Rs. 10 and higher deposits are in multiples of Rs. 5. "]
    },
    {
        highlighter: "How",
        question: "can you create a Post Office RD",
        description: "To create a Post Office Recurring Deposit, either visit a Post office nearby or you may either try the website of the Indian Postal System. "
    },
    {
        highlighter: "Quick Overview",
        question: "section",
        advantages:
            [
                {
                    info: 'What is the risk involved?',
                    details: 'Being govt-backed, the risk is zero.',
                },
                {
                    info: 'What returns can I expect?',
                    details: 'The returns are high and an added advantage is the tax benefits.',
                },
                {
                    info: 'What are the tax exemptions applicable on EPF?',
                    details: 'Benefits under section 80C and interest is taxable',
                },
                {
                    info: 'Time limit for investment?',
                    details: 'Lock-in period or maturity period is for 5 years',
                },
                {
                    info: 'How much can I invest?',
                    details: 'Min of Rs. 10 per month and maximum is unlimited',
                },
            ],
    },
];
// done

// verified
export const pord_FeatFact = [{
    features:
        [
            {
                f_highlighter: 'Negligible limitation on deposits',
                f_details: 'Individuals need to deposit as little as Rs. 10 each month. There is no upper limit to the amount of investment which can be made. Individuals can open an RD scheme in post office with either cheque or cash.'
            },
            {
                f_highlighter: 'Interest',
                f_details: 'The scheme offers a higher rate of interest to the depositors. The sum of interest is compounded quarterly and enables individuals to generate better earnings.',
            },
            {
                f_highlighter: 'Operation',
                f_details: 'Individuals can operate their account either singly or jointly. In case of a joint account, two adults can jointly operate their RD account in Post Office. However, if one of the account holders is a minor above 10 years of age, they would be able to operate the account in joint association with their guardian.',
            },
            {
                f_highlighter: 'Nomination facility',
                f_details: 'The scheme offers the opportunity to avail a nomination facility. Individuals can opt for such a facility either at the time of opening an account of RD scheme in the post office. They can also decide to opt for the facility after opening their account under the said scheme.',
            },
            {
                f_highlighter: 'Rebate facility',
                f_details: 'Individuals can avail the rebate facility on their advance deposits. But such a facility is limited to only 6 instalments.',
            },
            {
                f_highlighter: 'Ease of transfer',
                f_details: 'Investors can transfer funds from their post office recurring deposit easily. They are also entitled to open any number accounts in several post offices.',
            },
            {
                f_highlighter: 'Ease of withdrawal',
                f_details: 'The said scheme allows individuals to withdraw their deposit without hassle. They can withdraw up to 50% of their deposit balance a year after opening the account.',
            },
        ],
}
];
// done

// verified
export const pomis_about = [
    {
        highlighter: "What",
        question: "is Post Office Monthly Income Scheme(POMIS)?",
        description: "The Post Office Monthly Income Scheme (POMIS) is a Government of India backed small savings scheme that allows the investor to set aside a specific amount every month.",
        answer_in_steps: ["Subsequently, interest is added to this investment at the applicable rate and paid out to the depositor on a monthly basis.", "Post Office Monthly Income Scheme, amongst others such as Post Office Savings Account, Post Office Recurring Deposit, Post Office Time Deposit, is one of the highest-earning schemes with an interest rate of 7.6%. "]
    },
    {
        highlighter: "Why",
        question: "should we opt for POMIS?",
        answer_in_steps: ["POMIS provides a safe avenue for people to invest their money periodically and also get a monthly income out of it. ", "The interest is payable monthly and is at a rate of 6.6% approximately. "]
    },
    {
        highlighter: "Things",
        question: "to keep in mind before starting POMIS",
        answer_in_steps: ["It can be held individually or jointly.", " The tenure is 5 years. ", "The minimum amount that can be invested is Rs. 1500 and the maximum is Rs. 4.5 lakhs for individual accounts and Rs. 9 lakhs jointly."]
    },
    {
        highlighter: "How",
        question: "can you create a POMIS",
        answer_in_steps: ["To create a Post Office Recurring Deposit, either visit a Post office nearby or you may either try the website of the Indian Postal System.", "To create it offline , Submit the account opening form along with the following documents – photocopy of ID proof, photocopy of address proof, 2 passport-sized photographs."]
    },
    {
        highlighter: "Eligibility Criteria",
        question: "to open a Post Office RD account",
        answer_in_steps: ["A resident of India. NRIs are not eligible to invest in this scheme", "Individual of or above the age of 10 years"]
    },

    {
        highlighter: "Quick Overview",
        question: "section",
        advantages:
            [
                {
                    info: 'What is the risk involved?',
                    details: 'Being govt-backed, the risk is zero.',
                },
                {
                    info: 'What returns can I expect?',
                    details: 'The returns are moderate.',
                },
                {
                    info: 'What are the tax exemptions applicable on EPF?',
                    details: 'Taxed as Income From Other Sources',
                },
                {
                    info: 'Time limit for investment?',
                    details: 'Lock-in period or maturity period is for 5 years but withdrawals allowed after 1 year of opening ',
                },
                {
                    info: 'How much can I invest?',
                    details: 'Rs 1500 and Max: Rs. 4.5 Lakh (individually) and Rs.9 L (jointly)',
                },
            ],
    },
];
// done

// verified
export const pomis_FeatFact = [{
    features:
        [
            {
                f_highlighter: 'Lock-in period',
                f_details: 'When you open a Monthly Income Scheme account with a post office, you cannot withdraw the amount deposited in such account prior to 5 years.'
            },
            {
                f_highlighter: 'Maximum limit',
                f_details: 'You can make a maximum investment of Rs. 4.5 Lakh in the scheme. Even if you hold the scheme in multiple post offices, the aggregate of all your deposit cannot exceed Rs. 4.5 Lakh.',
            },
            {
                f_highlighter: 'Transferrable',
                f_details: 'In case, you are changing your residential status to a different city anywhere in India, you can transfer your POMIS account to a convenient post office. Your Post Office Monthly Income Scheme investment corpus and interest disbursal will be carried forward to such post office.',
            },
            {
                f_highlighter: 'Joint account',
                f_details: 'A maximum of 3 individuals can open a joint account for this scheme. In case of joint accounts, each investor possesses equal rights over the account. The maximum limit in case of joint accounts is Rs. 9 Lakh, and singular limit is Rs. 4.5 Lakh.',
            },
            {
                f_highlighter: 'Minor account',
                f_details: 'You can open a POMIS minor account in the name of your child. The Post Office Monthly Income Scheme age limit for minors is above 10 years. He/she can withdraw the amount after maturing to 18 years.',
            },
            {
                f_highlighter: 'Eligible residential status',
                f_details: 'Every Indian citizen is eligible to open a POMIS account; however, NRI individuals cannot.',
            },
            {
                f_highlighter: 'Auto-withdrawal',
                f_details: 'You can opt to withdraw the monthly interest amount on your investment through automatic transfer to your savings account through PDCs or ECS. If the POMIS account is with a CBS Post Office, then the interest amount can be directed towards any other CBS centric savings account.',
            },
            {
                f_highlighter: 'Penalty',
                f_details: 'In case you wish to withdraw your investment corpus before the lapse of the lock-in period, a penalty is charged on the withdrawal amount depending on the time of such redemption.If you redeem your investment within the 1st and 3rd year, a 2% penalty is charged. If you redeem within the 3rd and 5th year, a 1% penalty is charged.',
            },
        ],
    factors:
        [
            {
                ff_highlighter: 'Capital protection',
                ff_details: 'Your money is safe until maturity as this is a government-backed scheme.'
            }, {
                ff_highlighter: 'Tenure',
                ff_details: 'The lock-in period for Post Office MIS is 5 years. You can withdraw the invested amount when the scheme matures or reinvest it.'
            },
            {
                ff_highlighter: 'Low-risk investment',
                ff_details: 'As a fixed income scheme, the money you invested is not subject to market risks and is quite safe.'
            },
            {
                ff_highlighter: 'Start small',
                ff_details: 'You can start with a nominal initial investment of Rs.1,500. As per your affordability, you can invest in the multiples of this amount.'
            },
            {
                ff_highlighter: 'Guaranteed returns',
                ff_details: 'You earn income in the form of interest every month. The returns are not inflation-beating, but is higher compared to other fixed income investments like FD'
            },
            {
                ff_highlighter: 'Tax-efficiency',
                ff_details: 'Though your post office investment doesn’t fall under Section 80C and the income is subject to taxation. On the other hand, it has no TDS either.'
            },
            {
                ff_highlighter: 'Eligibility',
                ff_details: 'Though your post office investment doesn’t fall under Section 80C and the income is subject to taxation. On the other hand, it has no TDS either.'
            },
            {
                ff_highlighter: 'Payout',
                ff_details: 'You will receive the payout one month from making the first investment, and not the beginning of every month.'
            },
            {
                ff_highlighter: 'Tax-efficiency',
                ff_details: 'Though your post office investment doesn’t fall under Section 80C and the income is subject to taxation. On the other hand, it has no TDS either.'
            },
        ]
},
];

// verified
export const tfd_about = [
    {
        highlighter: "What",
        question: "is a Fixed Deposit(F.D)?",
        description: "Fixed Deposits are financial instruments offered by NBFCs or Banks which provide investors higher interest rates than a regular savings account until the given date of maturity. ",
        answer_in_steps:
            ["A fixed deposit refers to an investment scheme that banks and non-banking financing companies provide. FDs offer greater returns on the principal invested when compared to the returns generated from a regular savings account.", "Fixed deposits have a fixed tenure, hence the name. Depending on a consumer’s investment portfolio, the FD investment period can either be short-term or long-term. The interest rates on fixed deposits vary from one company or bank to another.", "Fixed deposit investors need to remember, however, that they cannot withdraw money before maturity without financial repercussions. In emergencies, early withdrawal is possible after the payment of penalties."],
    },

    {
        highlighter: "Who",
        question: "all offers F.D to Indian Citizens?",
        answer_in_steps: ["As stated previously, fixed deposit investments are offered by banks, post-offices, and other non-banking financial companies. In India, investors have countless options to open fixed deposit accounts. However, they must compare the interest rates, the reputation of the company, and other factors before depositing their funds.", "You can approach any of the banks or NBFCs in the country to open a fixed deposit account. Individuals lacking bank accounts can also avail of fixed deposit investments through post office accounts."]
    },
    {
        highlighter: "Why",
        question: "should we opt for F.D?",
        answer_in_steps: ["The returns on your deposit are assured and remain unaffected by market fluctuations", "FD interest rates offered by NBFCs are higher than FD rates offered by banks", "Fixed Deposit can be easily renewed, and you can also reap additional rate benefits on renewing your deposits", "Fixed Deposit is one of the safest investment instruments, which offers highest stability", "Returns on Fixed Deposit are assured, and there is no risk of loss of principal", "You can opt for periodic interest payouts, to help you manage your monthly expenses", "There is no effect of market fluctuations on your Fixed Deposit, which ensures greater safety of your investment capital", "Some financiers also offer higher FD interest rates for senior citizens"],
    },
    {
        highlighter: "Things",
        question: "to keep in mind before starting F.D",
        answer_in_steps:
            ["Tax is deducted at source, from interest on Fixed Deposit as applicable, as per the Income Tax Act, 1961.", "Compare rate of interests (Fixed deposit rates)", "Type of interest offered & applicable(Fixed rate or floating rate)", "Frequency of compounding", "Mode of interest payout", "Withdrawal facility", "Sweep facility", "Know other attached benefits."],
    },
    {
        highlighter: "How",
        question: "can you create a F.D",
        description: "You can go to the branch or you can do the same online. Any and every bank or NBFC offers an FD. ",
    },
    {
        highlighter: "Can",
        question: "we avail Loan against F.D?",
        answer_in_steps: ["Fixed deposit investors can avail of a loan against their fixed deposit investment.", "The maximum loan in such a case can vary between one bank FD and another. The loan quantum, in this case, is up to a certain percentage of the fixed deposit amount.", "The interest rate on such loans is generally lower when compared to unsecured credits. An investor availing such loans must mandatorily check interest rates on loan.", "The repayment tenure for the loan is limited to the maturity period of the fixed deposit in question."]
    },
    {
        highlighter: "Is F.D",
        question: "a good investment for you?",
        answer_in_steps: ["Fixed deposits are perfect investment instruments for inexperienced investors. Further, risk-averse individuals can benefit greatly from such schemes. Since FDs offer assured returns, there is almost no risk of principal loss.", "However, investors should remember that the rate of return from such an investment is limited when compared to other high-risk options."]
    },
    {
        highlighter: "Eligibility Criteria",
        question: "for F.D Investment?",
        description: "The following list of individuals is eligible to open a fixed deposit account in India",
        answer_in_steps: ["Indian resident", "NRI", "Individuals or joint investors (2 or more individuals)", "Senior citizens", "Minors", "Sole proprietorship", "Societies or clubs", "Companies", "Partnership firms"]
    },
    {
        highlighter: "Documents",
        question: "Necessary to open a F.D",
        description: "For Offline Procedure : Investors need to submit certain papers regarding their identity and residential address to invest in FD schemes successfully.",
        answer_in_steps: ["Identity Proof (Any One)", "Passport", "Voter ID card", "PAN card", "Aadhaar card", "Driving license", "Address Proof (Any One)", "Electricity bill", "Telephone bill", "Post Office issued ID"]
    },

    {
        highlighter: "Quick Overview",
        question: "section",
        advantages:
            [
                {
                    info: 'What is the risk involved?',
                    details: 'Zero to low. Insured for up to Rs. 100000 ',
                },
                {
                    info: 'What returns can I expect?',
                    details: '	The returns are better than just saving account',
                },
                {
                    info: 'What are the tax exemptions applicable on EPF?',
                    details: 'TDS - tax is deducted at source ',
                },
                {
                    info: 'Time limit for investment?',
                    details: 'Lock-in period or maturity period ranges from 1 week to 10 years',
                },
                {
                    info: 'How much can I invest?',
                    details: 'No limit on maximum investment',
                },
            ],
    },
];

export const tfd_FeatFact = [{
    features:
        [
            {
                f_highlighter: 'Tenure',
                f_details: 'Fixed deposits usually have a tenure range of 7 days to 10 years. Interest rates of fixed deposits also vary in accordance with different tenures and other factors. '
            },
            {
                f_highlighter: 'One-time investment',
                f_details: 'Fixed deposits are seen as single-time investment mode. For any customer who is willing to make extra deposits, there would be a requirement of opening separate deposit accounts. '
            },
            {
                f_highlighter: 'Rate of Interest',
                f_details: ["The rate of interest on Fixed Deposits depends upon the investment tenures, market rates & other important factors.", "The interest rates offered on FDs are generally lower than on other investment products. But at the same time they are less riskier than a number of other financial products.", "Corporate FDs usually offer higher interest rates than the Bank FDs as they carry a substantially higher risk than the latter. Investors could however consider choosing high rated corporate FDs over lower-rated ones to avoid the extra risks of defaults associated with them."]
            },
            {
                f_highlighter: 'Easy planning',
                f_details: 'Usually, Fixed Deposit offering institutions provide a Fixed Deposit Calculator which helps in planning out investment easily. Fixed Deposit Calculators can be used simply. Anyone who is willing to calculate their investment amount may put the desired investment amount and term and the Fixed Deposit calculator will help in providing maturity amount, payout amount, and interest which is earned. Other factors can also be ascertained by a fixed deposit calculator. '
            },
            {
                f_highlighter: 'Assured returns',
                f_details: 'Unlike most other investment schemes, FDs offer guaranteed returns on the deposited sum.'
            },
            {
                f_highlighter: 'Offers great flexibility',
                f_details: 'The best FD plans offer flexibility when it comes to the tenure of the plan. Depending on investor goals, they can either open short-term FD accounts or long-term FD accounts. For reference, most financial institutions offer maturity tenures ranging from 7 days to 10 years.'
            },
            {
                f_highlighter: 'High capital appreciation',
                f_details: 'Cumulative fixed deposit plans compound interest monthly or quarterly or half-yearly. Hence, the deposit amount is substantially appreciated by the end of tenure.'
            },
            {
                f_highlighter: 'An additional source of income',
                f_details: 'The investor can select the frequency of interest payout for non-cumulative fixed deposit plans. Therefore, they can act as an added source of income.'
            },
        ],

    factors:
        [
            {
                ff_highlighter: 'Interest rates remain fixed',
                ff_details: 'Fixed deposit interest rates do not grow over time or comply with inflation. Therefore, they are not the appropriate choice for investment if an individual is trying to beat inflation.'
            },
            {
                ff_highlighter: 'Deposit lock-in',
                ff_details: 'A lump sum amount is locked-in for a specific period. You cannot use this money in case of emergencies if you want to ensure proper returns from the investment. Early withdrawals lead to penalties and additional charges.'
            },
            {
                ff_highlighter: 'Early withdrawal charges ',
                ff_details: 'If an investor decides to initiate a premature withdrawal from an FD, they will end up forfeiting a portion of their interest earnings from the scheme.'
            },
            {
                ff_highlighter: 'Limited or no tax benefits',
                ff_details: 'Unless an individual specifically opts for tax-saving FDs, investors are not eligible for any tax exemptions or rebates on the fixed deposit interest earnings.'
            },
        ]
},
];

export const tfd_types = [{
    types:
        [
            {
                t_title: 'There are several fixed deposit types that investors need to know about before investing. Without such knowledge, you may end up picking up plans not suitable to your investment objectives. Listed below are some of the common options open to prospective investors.',
                t_highlighter: 'Corporate fixed deposits',
                t_details: 'These are fixed deposit schemes that are held by companies, other than banks. Also known as company FDs, investing in such instruments, may, in some cases, lead to higher returns.'
            },
            {
                t_highlighter: 'Standard fixed deposits',
                t_details: 'Standard plans are basic investment schemes where you invest a fixed amount with a financial institution. After the fixed maturity period expires, you are eligible to receive the principal amount, along with the interest earnings from the scheme.'
            },

            {
                t_highlighter: 'Senior citizen fixed deposit',
                t_details: 'Individuals aged over 60 years are also eligible to invest in fixed deposit instruments. However, most plans geared to this age group offer flexible tenure options. Additionally, senior citizen investors are eligible for higher interest rates on their investments compared to the standard schemes'
            },
            {
                t_highlighter: 'Tax-saving fixed deposit',
                t_details: 'If the primary goal of an investment is to save taxes, investors can take advantage of tax-saving FDs. However, the maximum deposit for such plans is limited to Rs. 1.5 Lakh per year. The lock-in period for this type of FD is 5 years.'
            },
            {
                t_highlighter: 'Cumulative fixed deposit',
                t_details: 'In these fixed deposit schemes, the interest is compounded quarterly, half-yearly, or yearly. However, the total interest earnings are paid at the time of maturity. Opting for this kind of FD allows you to build your corpus considerably.'
            },
            {
                t_highlighter: 'Non-cumulative fixed deposit',
                t_details: 'Interest earnings on fixed deposits are paid out monthly, quarterly, or half-yearly. This option is best for investors looking for a regular source of income. Hence, pensioners benefit prominently from such plans.'
            },
            {
                t_highlighter: 'Flexi fixed deposit plans',
                t_details: 'In this case, the deposit moves between a savings account and an FD account. Therefore, to initiate an investment using Flexi FDs, investors need to connect the fixed deposit account with their savings account. Investors can enjoy high-interest rates on their deposits along with liquidity with this category of FD plans.'
            },
            {
                t_highlighter: 'NRO fixed deposit account',
                t_details: 'Non-resident Indians can deposit their earnings generated from India in an NRO FD account. The interest earned from these FD accounts can be repatriated entirely by NRO account holders, and the principal amount can only be repatriated up to a certain limit.'
            },
            {
                t_highlighter: 'NRE fixed deposit account',
                t_details: 'NRIs can remit their income generated abroad and invest in an NRE fixed deposit account. Both the interest and principal are repatriable in this case.'
            },

        ],
}
];
//done

//verified
export const lifei_about = [
    {
        highlighter: "What",
        question: "is a Life Insurance?",
        description: "Life Insurance is a contract between an insurance policyholder and an insurance company, where the insurer promises to pay a sum of money in exchange for a premium after a set period or upon the death of an insured person.",
        answer_in_steps:
            ["In India, the death rate is estimated to be somewhere around 7-9/1000 which translates to over 25000 deaths every day.Well of this, many people die prematurely due to any disease, or accident or naturally. Also, many are the single bread earners of their family.", "Life comes to a standstill. Leave alone maintaining the standard of living; these people struggle even to lead a life with basic necessities. In order to avoid the devasting consequences of the loss of an earning member of a family, a life insurance policy comes to the rescue."]
    },
    {
        highlighter: "Why",
        question: "should we opt for a Life Insurance policy?",
        description: "If an earning member of the family passes away due to mishap or any other causes, a life insurance ensures that the nominees of the insured are paid the sum of money in the event of loss of life. "
    },

    {
        highlighter: "Things",
        question: "to keep in mind before starting Life Insurance policy",
        answer_in_steps: ["A person must keep in mind his premium paying ability, purpose of investment, term of insurance, risk covered by the policy, etc. before buying an insurance policy", "Premium - The premium should be easily payable considering ones regular liabilities such as EMI, rent, household expense etc.", "A term life insurance plan is renewed every year for a period of one year. The term can vary in case of an endowment plan, which offers the combined benefit of insurance and investment. Experts say one must be clear about the term before committing to a policy.", "Risk covered - One must also closely observe the sum assured, benefits provided, risks covered, flexibility offered, premium amount, etc.", "Additional benefits - Term insurance plans are suitable for individuals seeking complete risk cover against liabilities. Compared with term insurance plans, endowment and ULIP plans usually offer additional benefits at a higher premium at lower insurance cover , Hence consider all benefits offered and then consider purchasing a plan."]
    },
    {
        highlighter: "How",
        question: "can you create a Life Insurane",
        description: "Either online on the website of an Insurance company or through an Insurance agent offline.",
    },
    {
        highlighter: "Can",
        question: "we avail Loan against Life Insurance Policy?",
        answer_in_steps: ["You will have to check with the company on the amount of loan you are eligible for. The loan amount is a percentage of its surrender value. It can be upto 85-90% against traditional insurance plans with guaranteed returns. For ULIP plans, loan amount will depend on the current value of the corpus and the type of fund.", "However, this loan amount is not considered as an income and is very much taxable.", "Note : You will continue to pay premiums even after availing loan."]
    },

    {
        highlighter: "Documents",
        question: "Necessary to open a Life Insurance Policy",
        description: "Offline Procedure: To buy a life insurance policy, the following documents would be required",
        answer_in_steps: ["Life insurance proposal form, duly filled and signed by the proposer and/or the life insured", "Photograph of the proposer", "Photograph of the life insured (if different from the proposer)", "Age proof of the proposer and/or the life insured ", "Identity proof of the proposer and/or the life insured", "Address proof of the proposer", "Medical examination report of the life insured if required by the policy because of the age and/or the sum assured chosen ", "Income proof of the proposer if the sum assured and/or the premium of the policy is high", "PAN card of the proposer"]
    },
];

export const lifei_types = [{
    types:
        [
            {
                t_title: 'Here goes types of life insurance',
                t_highlighter: 'Term life insurance',
                t_details: ["A term life insurance policy is one of the simplest and most affordable life insurance plans that you can buy. It provides coverage for death risk for a specified period. In the event of death of the policyholder, the sum assured amount is paid to the nominee in lump sum or as monthly pay-outs. This type of life insurance gives you maximum coverage with minimum premium. You can also widen up the coverage by buying additional riders.", "Some insurance companies have come up with innovative term insurance plans where they offer return of premiums to the insured at the end of the policy term. Future Generali Term Plan with Return of Premium is one such term life insurance policy that returns you back up to 115% of the premiums you have paid if you survive the end of the policy term (10-15 years)."]
            },
            {
                t_highlighter: 'Unit linked insurance plans',
                t_details: 'ULIPs give you the triple advantage of insurance, wealth creation and tax-saving investment. In ULIPs the money that you pay as premium is partly invested on funds and partly on risk cover. You can choose the funds to invest depending upon your risk appetite and investment horizon. '
            },
            {
                t_highlighter: 'Endowment plans',
                t_details: 'Similar to a ULIP, endowment plans are types of life insurance that offers a mix of insurance coverage and investment opportunity. Sum assured is paid to the nominee or family in case of death or sum assured amount plus accumulated bonus in case the insured outlives the policy term.'
            },
            {
                t_highlighter: 'Money back policy',
                t_details: 'As the term suggests, in this type of life insurance policy the insured receives a specified sum in intervals during the policy term as well as sum assured amount on death or on maturity. Investors also get accrued bonuses on maturity.'
            },
            {
                t_highlighter: 'Whole life insurance',
                t_details: 'A whole life insurance covers the insured during the entire lifetime of the individual or in some cases up to 100 years. Sum assured is paid to nominee on death of the policy holder. In the rare event that the policyholder lives more than 100 years, the maturity amount is paid to the insured.'
            },
            {
                t_highlighter: 'Child plan',
                t_details: 'A child insurance plan helps to build capital for important events in a child’s life such as higher education, overseas studies, marriage, etc. Most child plans provide one time pay-out or annual payments after the child reaches 18 years of age. In case the parent passes away during the policy term, payment is made to the child or family. Some insurance companies waive off the premiums in case of death of the policyholder and make the payment after maturity period.'
            },
            {
                t_highlighter: 'Retirement plan',
                t_details: 'This type of insurance plan helps you build a substantial amount of capital to live a worry-free retirement life. You can opt for annual payments or a single pay-out after the age of 60 years. In case of the death of the insured, payment is made to the nominee either based on coverage, fund value or 105% of premiums paid. '
            },
            ,

        ],
}
];
// done

export const lifei_FeatFact = [{
    features:
        [
            {
                f_highlighter: 'Waiver of premium',
                f_details: 'This feature pays the premium of a policy if you become seriously ill or disabled.'
            },
            {
                f_highlighter: 'Accelerated death benefit',
                f_details: "This feature allows you to receive cash advances against the death benefit of your policy if you're diagnosed with a terminal illness.",
            },
            {
                f_highlighter: 'Guaranteed purchase option',
                f_details: "With this feature, you can purchase coverage at designated future dates or life events without proving you're in good health."
            },
            {
                f_highlighter: 'Long-term care riders.',
                f_details: 'Some life products include this option, which allows you to use the benefits of your policy to pay for long-term care in exchange for a reduced life benefit. '
            },
            {
                f_highlighter: 'Spouse or child term riders',
                f_details: 'Life policies with this feature allow you to purchase term life insurance for your spouse or dependent child, up to age 26. '
            },
            {
                f_highlighter: 'Cash value plans',
                f_details: 'This type of policy pays out upon your death and also accumulates value during your lifetime.  You can use the cash value as a tax-sheltered investment, as a fund from which you can borrow and use to pay the policy premiums later.'
            },
            {
                f_highlighter: 'Mortgage protection',
                f_details: 'This feature, typically found on term life policies, will pay your mortgage if you die.'
            },
            {
                f_highlighter: 'Cash withdrawals and loans',
                f_details: 'Many universal and whole life policies allow you to withdraw or borrow money, using the cash value of the policy as collateral. Interest rates tend to be relatively low.  You can also use the cash value of your life policy to pay your premiums if you need or want to stop paying premiums for a period of time.  '
            },
            {
                f_highlighter: 'Survivor support services',
                f_details: 'Some life policies offer services that provide objective financial and legal assistance to beneficiaries.'
            },
            {
                f_highlighter: 'Employee assistance programs.',
                f_details: 'This feature makes resources available to you for problems that can affect your personal and professional life.  Resources are usually free and help address issues such as substance abuse, stress, marital problems, legal concerns and major life events.'
            },
        ],
},
];

export const traveli_about = [
    {
        highlighter: "What",
        question: "is a Travel Insurance?",
        description: "Travel insurance is an insurance product for covering unforeseen losses incurred while travelling, either internationally or domestically.",
    },
    {
        highlighter: "Why",
        question: "should we opt for a Travel Insurance?",
        description: "It not only acts as a safety net against the risk of incurring unforeseen medical expenses but also safeguards against other travel related emergencies that could spoil your trip; for example - losing a passport or checked-in baggage.",
    },
    {
        highlighter: "Things",
        question: "to keep in mind before starting Life Insurance policy",
        description: "Tour agencies generally provide you with travel insurance policies, but a majority of people think it is advisable to research prices in advance to evaluate prices as well as insurance coverage. Indeed, these kinds of agreements could be lengthy, however, it’s crucial that you really know what is protected as well as things which are not. "
    },
    {
        highlighter: "How",
        question: "can you create a Life Insurane",
        description: "Online through insurance companies websites or offline through agents. ",
    },
    {
        highlighter: "Coverage",
        question: "of travel insurance?",
        description: "The coverage offered by insurer varies for different providers. Following are the most common inclusions under travel insurance policy",
        answer_in_steps: ["Loss of baggage and personal documents", "Loss of passport", "Emergency medical expenses", "Repatriation of human remains", "Hospital cash allowance", "Accidental death or permanent total disability", "Hijack distress allowance", "Compassionate visit of relatives", "Personal liabilities", " in case of injury or damage to third party", "Delayed trip and missed flight connection"]
    },
    {
        highlighter: "Eligibility",
        question: "Criteria for travel insurance?",
        description: "Travel insurance has different types of plans under its umbrella, so eligibility criteria and age limit will also differ from policy to policy. Let’s understand this.",
        answer_in_steps: ["For Family travel insurance plan - It covers two adults up to the age of 60 years of age and their two children up to 21 years of age.", "For Student travel insurance plan - Students between the age of 16 and 40 can buy this plan who are studying abroad for their higher education.", "Senior citizen travel insurance plan-This plan is for senior citizens up to 85 years of age.", "Schengen travel insurance plan- To be eligible for Schengen travel insurance, adults should be up to the age of 70 years and infants must be older than 90 days.", "International travel insurance - It offers coverage for two adults who should be up to the age of 60 and two children up to 21 years."]
    },
    {
        highlighter: "Documents",
        question: "required for Travel Insurance Claim:",
        description: "you might require one or more document listed below depending on the claim type",
        answer_in_steps: ["Identification - Some insurance companies might ask you to submit some kind of identification document such as your passport copy or Aadhaar copy.", "Trip tickets -In case of international travel insurance, you might have to submit flight (other travel) tickets. Such kind of documentation comes in handy in case of claims related to trip delay, trip cancellation, etc.", "Cancellation -If there’s a trip cancellation related to a medical issue, the insurance company might need a doctor’s statement stating the same.", " Such type of documentation becomes crucial in case of baggage delay/loss. You need to get the appropriate correspondence from the airport authorities that verifies your claim. ", "Airport correspondence - Such type of documentation becomes crucial in case of baggage delay/loss. You need to get the appropriate correspondence from the airport authorities that verifies your claim.", "FIR -A First Information Report (FIR) is required for claims related to theft of baggage. A detailed report needs to be fetched from the appropriate police officials.", "Loss of passport -If you lose your passport on your foreign trip, you need to submit a photocopy of the document along with the claim form.", "Hospital bill -In case of an unfortunate event of an accident, you need to submit the relevant hospital bills. This is assuming you went for a reimbursement claim and not a cashless one.", "Discharge card - In case you have a prolonged stay at a hospital, you need to submit a discharge card from the hospital where you were treated. Again, this is not applicable in case of cashless claims."]
    },
];

export const traveli_FeatFact = [{
    features:
        [
            {
                f_highlighter: 'Hospitalization Expenses',
                f_details: 'Medical emergencies including hospitalization expenses, accidents, etc can be covered with a travel insurance.'
            },
            {
                f_highlighter: 'Cover for Belongings',
                f_details: 'The best travel insurance plan will have a cover for your high-end personal belongings like smart phone, laptops, etc that may have your sensitive information as well as treasured memories. '
            },
            {
                f_highlighter: 'Missed Flight Cover',
                f_details: 'Travel insurance is meant to protect you financially in case you face any unforeseen situations while traveling. If you miss your flight because of major unforeseen events, the best traveling insurance will cover you.'
            },
            {
                f_highlighter: 'Covers Cancellation',
                f_details: 'The best traveling insurance plan will cover you for the prepaid expenses you made before the trip including flight tickets, hotel bookings, etc in case of cancellation. '
            },
            {
                f_highlighter: 'Luggage Cover',
                f_details: 'The best travel insurance plan should cover you in case you lose any luggage during the travel. '
            },
            {
                f_highlighter: 'Cover for Legal Liabilities',
                f_details: 'The best travel insurance plan should be able to provide legal help in unforseen conditions. It should cover you against any legal liabilities in the foreign land.'
            },
            {
                f_highlighter: 'Hassle-free',
                f_details: 'Lastly, the best travel insurance in India should be easy to purchase, simple to understand and helpful when in need. The inclusions and exclusions should be clearly defined and easy to understand.'
            },

        ],
},
];

export const traveli_types = [{
    types:
        [
            {
                t_title: 'Travel insurance in India can be classified into the following 4 types,',
                t_highlighter: 'Domestic Travel insurance',
                t_details: "A domestic travel insurance plan provides insurance while traveling within the country. It covers medical emergencies, death, disability, loss of baggage, etc."
            },
            {
                t_highlighter: 'International Travel Insurance',
                t_details: 'An international travel insurance is a consolidated insurance plan for your trip to the foreign lands. It provides a cover for emergency medical expenses that you may need overseas, hijack, loss of important documents like visa or passport, etc.'
            },
            {
                t_highlighter: 'Student Travel Insurance',
                t_details: 'These are the simple travel insurance plans that cover student who wishes to study abroad. These plans can be purchased with minimum paperwork or formalities. The coverage is similar to but not as wide as other types of travel insurance plans. For example – these plans include a cover for medical expenses, loss of important documents like visa or passport, etc.'
            },
            {
                t_highlighter: 'Group Travel Insurance Travel Insurance',
                t_details: 'A group travel insurance plan can be bought especially when you travel along with your friends rather than with your family. A group travel insurance plan costs less as compared to individual plans.'
            },
            {
                t_highlighter: 'Senior citizen travel insurance',
                t_details: 'Besides the usual advantages of purchasing travel insurance, a policy that is directed at senior citizens (generally belonging to the age group of 61-70 years) offers additional coverage against dental treatments/procedures as well as cashless hospitalization.'
            },
            {
                t_highlighter: 'Single and multi-trip travel insurance',
                t_details: ['As the name suggests, a single-trip travel insurance policy retains its validity through the time you are on a trip. It covers both medical as well as non-medical expenses (such as baggage loss, delays in flights, etc.).Multi-trip travel insurance policy, on the other hand, provides extended coverage (lasting usually a year in most cases) so that frequent flyers don’t have to go through the entire process of availing insurance every time they prep for travel.', 'In conclusion, you should choose a type that best fits your requirements, only after having accounted for what you might need the most over the course of travel.'],
            },

        ],
}
];
//done

// verified
export const healthi_about = [
    {
        highlighter: "What",
        question: "is a Health Insurance?",
        answer_in_steps: ["Health insurance is an insurance that covers the whole or a part of the risk of a person incurring medical expenses, spreading the risk over numerous persons.", "Health insurance can reimburse the insured for expenses incurred from illness or injury, or pay the care provider directly. It is often included in employer benefit packages as a means of enticing quality employees, with premiums partially covered by the employer but often also deducted from employee paychecks."],
    },
    {
        highlighter: "Why",
        question: "should we opt for a Medical Insurance?",
        description: "The main purpose of Medical insurance is to receive the best medical care without any strain on your finances. Health insurance plans offer protection against high medical costs. It covers hospitalization expenses, day care procedures, domiciliary expenses, and ambulance charges, besides many others.",
    },
    {
        highlighter: "Importance",
        question: "of having a Health Insurance so Important",
        description: "You should know the important aspects of having a health insurance policy as,",
        answer_in_steps: ["It gives you peace of mind.", "You get better health facilities because the cost will be covered by the insurance company.", "If health insurance comes as a Group Insurance from your employer then you can improve your savings.", "Some health insurance companies offer complementary annual health check-ups.", "It acts as your saving for the time of distress. You will be paying a premium but the amount of financial help you receive is way more.", "Tax Benefit: Buying a health insurance policy will offer you a tax benefit under Section 80D of the Income Tax."]
    },
    {
        highlighter: "Things",
        question: "to consider before investing in Medical Insurance",
        answer_in_steps: ["Health insurance can be very costly even for those that have a health insurance plan through their employers. ", "Buying a health plan at an early age has its benefits as you get rid of the waiting period at an early stage of your life when the chances of attracting diseases are less, you earn a good amount of Cumulative Bonus, and you can increase the sum insured over the years. ", "It is essential to compare the plans as well as the premium. There are many online aggregators that, help you compare the insurance policies in terms of benefits, features, premiums, maximum returns, etc.; picking a plan without weighing all the options available in the market is like inviting regret. By comparing the plans you can avail all the benefits at a comparatively cheaper premium rate."]
    },
    {
        highlighter: "Coverage",
        question: "of Critical Illness Health Cover - Medical Insurance",
        answer_in_steps: ['Cancer', 'Stroke', 'Kidney Failure', 'Paralysis', 'Coronary Artery Bypass Surgery', 'First heart attack', 'Pulmonary Arterial Hypertension', 'Multiple Sclerosis', 'Aorta Graft Surgery']
    },
    {
        highlighter: "How",
        question: "can you create a Health Insurane",
        description: "Online through insurance companies websites or offline through agents ",
    },
    {
        highlighter: "Eligibility",
        question: "Criteria for Health insurance?",
        description: "If we consider the ideal situation there are as such no eligibility factors which are hard and fast but in reality the following two factors slightly affect eligibility. ",
        answer_in_steps:
            ["Minimum Entry Age: The minimum age to avail of the benefits of health insurance for your child under a child plan is usually 16 days to 18 years. Entry age of a policy for adults ranges from 18 up to 65 years. It can also be 70 years and above based on the plan and insurer. ", "Pre-Existing Medical Conditions: If you are purchasing health insurance after the age of 45, you may have to go through various medical tests before getting a health cover. If the policyholder is not asked for the medical tests, then he/she has to submit a declaration disclosing his/her pre-existing conditions.  These preconditions or revelations by medical tests determine whether a policy is accessible to a policyholder."]
    },
    {
        highlighter: "Documents",
        question: "required while making claim",
        answer_in_steps: ["Duly filled the claim form", "Medical Certificate", "Patient’s Discharge summary or card (original)", "Prescription and cash invoice from pharmacies/hospital", "Investigation report", "FIR or Medico Legal Certificate (MLC), in case of an accident"]
    },
];

export const healthi_types = [{
    types:
        [
            {
                t_highlighter: 'Individual Health Insurance Plan',
                t_details: ['Individual Health Insurance Plan is a type of health insurance that covers health expenses of an insured individual.', ' These policies pay for surgical and hospitalisation expenses of an insured individual till the cover limit is reached.', ' The premium for an individual plan is decided on the basis of medical history and the age of the individual buying the plan.']
            },
            {
                t_highlighter: 'Family Floater Health Insurance Plan',
                t_details: 'If a person wants to buy health insurance for his entire family (spouse, children and parents) in a single plan, then he should go for a Family Floater Policy. Any family member covered under the policy can claim in case of hospitalisation and surgical expenses. Like Individual Health Insurance Plan one has to pay a premium for family floater policy. The premium for family floater policy is determined based on the age of the eldest member under the coverage of the policy.'
            },
            {
                t_highlighter: 'Group Health Cover',
                t_details: 'Group Health Insurance plans are bought by an employer for his employees. The premium in group insurance is lower than individual health insurance policy. Group health plans are usually standardised in nature and offer the same benefits to all employees.'
            },
            {
                t_highlighter: 'Senior Citizen Health Insurance',
                t_details: 'At old age, health issues arise that involve expensive treatments. In order to meet such high medical cost, insurance companies have designed special health insurance plans for senior citizens. These plans provide cover to anyone from the age of 65 and above. Generally, the premium is higher in the case of senior citizen health insurance plan as compared to other policies.'
            },
            {
                t_highlighter: 'Critical Illness Health Cover',
                t_details: 'ritical Illness Policy covers the expenses involved in treating the life-threatening diseases like a tumour, permanent paralysis etc. These policies usually pay a lump sum amount to insured person on the diagnosis of serious diseases covered in the policy document. Unlike other policies, Individual Health Insurance and Family Floater Policy, hospitalisation is not required, only the diagnosis of the disease is enough to claim the benefits.'
            },
            {
                t_highlighter: 'Super Top-Up Policy',
                t_details: ['Super Top-Up Plans offer an additional coverage over the regular policy that can help to increase the amount of sum insured. The Super Top-Up Policy can be used only after the sum insured of one’s regular policy is exhausted.', 'For example, if a person has a regular health plan of Rs 3 lakh and a Top-Up plan of Rs 5 lakh. If there is a claim for Rs 5 lakh, then the existing medical policy will pay a claim of Rs 3 lakh and remaining claimed amount of Rs 2 lakh will be covered by the Super Top-Up policy.']
            },
            {
                t_highlighter: 'Maternity Health Insurance ',
                t_details: ['These policies cover medical expenses during pre-natal, post-natal and delivery stages. It covers both the mother as well as her newborn.']
            },
        ],
}
];

export const healthi_FeatFact = [{
    features:
        [
            {
                f_title: 'Features differ according to the type of policy you choose, some of the features commonly offered in a health insurance policy are listed below. ',
                f_highlighter: 'Cashless treatment',
                f_details: 'When you possess insurance, you become eligible for cashless treatments at one of the various hospital networks your insurer has collaborated with.',
            },
            {
                f_highlighter: 'Pre & post hospitalization cost coverage',
                f_details: 'Health insurance policies provide coverage for pre & post hospitalization expenses for a specific number of days. Learn that the number of days covered depends on the type of plan purchased. ​',
            },
            {
                f_highlighter: 'Ambulance expenses',
                f_details: 'One of the benefits of having health insurance by side is that it covers you for ambulance expenses incurred on the transportation of insured. Thus, the need for arranging a vehicle during an emergency situation gets eliminated.',
            },
            {
                f_highlighter: 'No Claim Bonus',
                f_details: 'No claim bonus or NCB is a health insurance element paid to the insurer if he or she does not file any claim for any treatment in a previous year. ',
            },
            {
                f_highlighter: 'Medical Checkup',
                f_details: 'When you choose health insurance, as per your plan type, you become eligible for health checkups. Note that free health checkups are also provided by some insurers on the basis of your previous NCBs.',
            },
            {
                f_highlighter: 'Room rent',
                f_details: 'Most insurance policies provide coverage for hospital room expenses based on the premium paid by the insured individual',
            },
            {
                f_highlighter: 'Alternative treatment',
                f_details: 'Many insurance companies provide coverage for alternative treatment such as Ayurveda, Homeopathic, Yoga and others. On buying a policy that covers this element, you will be able to make the most of the offered treatment without bearing expenses on your own.',
            },
            {
                f_highlighter: 'Organ donor expenses',
                f_details: 'A health insurance policy helps you secure from the worry of medical expenses that come with the procedure of undergoing organ transplant surgery. If not a regular part of your basic health insurance, this element may be offered to you as an add-on benefit. ',
            },
            {
                f_highlighter: 'Domiciliary treatment',
                f_details: 'If your policy provides domiciliary treatment, you would be covered for availing treatment at home under medical supervision. ',
            },
            {
                f_highlighter: 'Daily hospital cash allowance',
                f_details: 'This daily cash allowance feature is one of the hidden benefits of health insurance (if featured in the policy) that lets you take care of your additional expenses like food, travel and others during hospitalization.',
            },
        ],
    factors:
        [
            {
                ff_title: 'Here is a quick checklist to consider before you finalize your insurance product.',
                ff_details: 'Be sure of the Sum Insured chosen. Think about whether it will be sufficient for you or your family members.'
            },
            {
                ff_details: 'Set the right Sum Insured limit for your dependent parents.'
            },
            {
                ff_details: 'Choose the plan with the minimum waiting period.'
            },
            {
                ff_details: 'Check for the maximum age-renewal.'
            },
            {
                ff_details: 'Buy health insurance policy from the companies that have a fast claim settlement ratio.'
            },
            {
                ff_details: 'Choose the insurance company that has the widest network of the hospital on their list.'
            },

        ],
},
];


export const motori_about = [
    {
        highlighter: "What",
        question: "is a Motor Insurance?",
        description: "Motor or Vehicle insurance is the insurance for cars, trucks, motorcycles, and other road vehicles. Its primary use is to provide financial protection against physical damage or bodily injury resulting from traffic collisions and against liability that could also arise from incidents in a vehicle."
    },
    {
        highlighter: "Why",
        question: "should we opt for a Motor Insurance?",
        description: "Be it damage or loss to insured vehicle or personal accident cover, the benefits are numerous. If you own a vehicle, car or bike, it's important to take motor insurance. ",
    },
    {
        highlighter: "Things",
        question: "to consider before investing in Motor Insurance",
        answer_in_steps: ["Choose Comprehensive Cover over Third-party", "Consider the Reputation of the Insurer", "Consider Add-ons for Extra Coverage", "Consider the Premium Amount", "The coverage may not be enough in case of serious accidents. Third party vehicle insurance won't be of help in case of theft or damage due to fire. "]
    },
    {
        highlighter: "Coverage",
        question: "of Motor Insurance",
        answer_in_steps: ["Damage to the insured vehicle due to accident, riots, burglary, thefts, terrorism, natural calamities and man-made calamities like fire, explosion, flood, storm, earthquake", "Third party legal liability will cover accidental death and injury to the third party, along with any damage done to third party property", "Under personal accident coverage, the insurance company bears the cost of any medical treatment of the driver needed after any eventuality that occurred while driving"]
    },
    {
        highlighter: "Exclusions",
        question: "under Motor Insurance (Things which wont be covered)",
        answer_in_steps: ["Normal wear and tear", "Damage due to malicious activity", "Damage by the person driving the vehicle without a license", "Mechanical/electrical breakdown", "Consequential loss", "Damage occurred outside geographical boundaries"]
    },

    {
        highlighter: "Types",
        question: "of Insurance coverage",
        answer_in_steps: ["Third Party Insurance: This type of automobile insurance covers third-party liabilities. The expenses of any unintentional damage caused to a third party or their property due to your insured vehicle can be covered with third party insurance. According to law, it is mandatory to give compensation to the third- party in case of accidents causing serious injuries, disability or death of the person or damages to their property.", "Comprehensive Insurance: Comprehensive motor insurance covers you and your vehicle against any unforeseen damage to your vehicle or a third-party and their property. It also covers death/disability of the driver, owner, and passengers due to an accident by the insured vehicle."]
    },
    {
        highlighter: "How",
        question: "can you create a Motor Insurane",
        description: "Online through insurance companies websites or offline through agents. ",
    },
    {
        highlighter: "Eligibility",
        question: "Criteria for Motor insurance?",
        description: "In India, a motor insurance can be bought for private cars, two wheelers and commercial vehicles. To be eligible to buy a motor insurance, you must own a car, can be old or new, should be above 18 years of age, should be a citizen of India and should possess the RC of the car.",
    },
    {
        highlighter: "Documents",
        question: "required to buy a Motor Insurance",
        description: "Offline Procedure : These are the documents required to open a offline Motor Insurance Policy",
        answer_in_steps: ["Passport size photograph", "Driving license or PAN card", "Proof of permanent residence", "Registration certificate of the car"]
    },
    {
        highlighter: "Documents",
        question: "required while making claim",
        answer_in_steps: ["Duly filled in claim form", "Driving license", "Registration certificate", "Police report (in case of an accident)", "Non traceable certificate issued by the police (in case of theft)", "Original repair estimate bill (in case cashless service is not opted for)"]
    },
];

export const motori_types = [{
    types:
        [
            {
                t_title: 'Motor insurance in India can be broadly classified into the following 3 types,',
                t_highlighter: 'Private Car Motor Insurance Policy',
                t_details: 'This is motor insurance that needs to be taken for any private car owned by an individual and is mandated by the Government of India. It covers the vehicle for damages against accidents, fire, natural disasters, theft among others and also covers for any injury to the owner. It also covers any damages and injuries caused to the third party.'
            },
            {
                t_highlighter: 'Two-Wheeler Insurance Policy',
                t_details: 'This insurance policy covers two-wheelers like a scooter or a bike and is mandated by the Government of India. The two-wheeler is covered against damages from accidents, disasters, fire, theft, etc. as well as any damages and injuries to the third-party. It also offers a mandatory personal accident cover for the owner rider and can be taken for passengers too.'
            },
            {
                t_highlighter: 'Commercial Vehicle Insurance Policy',
                t_details: 'This insurance covers all vehicles that are not used for personal use. his type of insurance covers all those vehicles which are not used for personal purpose. Trucks, buses, heavy commercial vehicles, light commercial vehicles, multi-utility vehicles, agricultural vehicles, taxi/cab, ambulances, auto-rickshaw etc. are some vehicles that are covered under this insurance.'
            },
        ],
}
];

export const motori_FeatFact = [{
    features:
        [
            {
                f_highlighter: 'Coverage against accident',
                f_details: 'A motor insurance can give you a coverage against damage happened to your vehicle due to some accidents. You can claim the expenses and save more money.'
            },
            {
                f_highlighter: 'Coverage against natural disaster',
                f_details: 'A sudden huge flood can damage your vehicle which will cause you to incur a huge expense. With the motor insurance, you can save few bucks on vehicle repairs.'
            },
            {
                f_highlighter: 'Legal protection',
                f_details: 'As having no motor insurance is a violation of traffic laws, it can lead to paying penalties. Hence, it’s important you renew your insurance once it gets expired and also Helps you to avoid any traffic fines and other legalities that you would otherwise need to bear.'
            },
            {
                f_highlighter: 'Death Benefits',
                f_details: 'As third-party insurance is mandatory, the motor insurance can benefit the survivors when an accident results in death.'
            },
            {
                f_highlighter: 'No Claim Bonus',
                f_details: 'If you have not made any claims during the policy term, you are eligible for No Claim Bonus upon renewal of the policy. However, you may need to fulfil certain terms and conditions.'
            },
            {
                f_highlighter: 'Meets All Third-Party Liability',
                f_details: 'If you injure a person or damage someone’s property during a vehicular accident, the insurance policy helps you meet the monetary losses, effectively.'
            },

            {
                f_highlighter: 'Theft/loss cover',
                f_details: 'If your vehicle is stolen, your insurance policy will help you reclaim a portion of the car/bike’s on-road price. You can expect similar assistance if your vehicle is damaged beyond repair due to accidents.'
            },

        ],
},
];

export const mobilei_about = [
    {
        highlighter: "What",
        question: "is a Mobile Insurance?",
        description: "Mobile phones are arguably our most important possession. When a costly phone is lost, the loss is huge. To overcome such issues, companies are now offering mobile insurance in India. These policies provide coverage against theft or accidental damage to all types of phones, including smartphones"
    },
    {
        highlighter: "Why",
        question: "should we opt for a Mobile Insurance?",
        description: "You should consider buying mobile phone insurance if,",
        answer_in_steps: ["You have a history of lost, stolen or broken phones.", "You have an expensive smartphone and/or you’re locked into a long-term contract.", "You couldn’t afford to replace the handset and you wouldn’t want to downgrade to a cheaper model.", "You rely on your mobile phone and would need an instant replacement if it was lost, stolen or damaged (bear in mind that you’d need to choose a policy offering a rapid replacement service – not all of them do)"]
    },
    {
        highlighter: "Coverage",
        question: "of Mobile Insurance",
        description: "Below are the damages that are usually covered under mobile insurance policies,",
        answer_in_steps: ["Internal part damage of the phone due to water spillage", "Damage to the exterior and interior components of the insured device", "Hardware failure including charging port problem, faulty earphone jack or touchscreen malfunction", "Phone screen cracks", "Loss of the insured phone due to house break-in, theft, and burglary", "The insured phone damaged due to fire, or due to strike, riot, terrorist activities. Some policies also cover damage due to malicious intent", "Loss of the insured phone from a securely locked vehicle or secured building"]
    },
    {
        highlighter: "Exclusions",
        question: "under Mobile Insurance (Things which wont be covered)",
        answer_in_steps: ["Insured device damage due to routine wear and tear", "Insured device missing mysteriously", "The insured phone was stolen from an unattended building or vehicle", "The insured device has an electronic or mechanical breakdown", "Insured device experimented with under any abnormal conditions", "Insured device damaged due to wilful or malicious activity caused by the policyholder", "Damage to the phone or the device is lost when in possession of the third party will not be covered"]
    },
    {
        highlighter: "Is a mobile phone",
        question: "purchased outside India covered by the mobile insurance plan?",
        description: "The Mobile Screen Insurance policy is restricted to domestic mobile manufacturers and models available in India and to Indian residents only.",
    },
    {
        highlighter: "How",
        question: "to buy a Mobile insurance",
        description: "You can buy mobile insurance online through the website of insurance companies. The process is simple and quick. You just have to choose the insurer, fill up an online application form and pay the premiums online and the insurance policy would be issued. Alternatively, you can also visit the branch of a mobile insurance company and apply for the policy.",
    },
    {
        highlighter: "Documents",
        question: "required to buy a Mobile Insurance",
        description: "To buy a mobile insurance plan, you would have to submit the following documents,",
        answer_in_steps: ["An application form seeking insurance cover", "Invoice of the phone", "IMEI number of the phone", "Your identity proof"]
    },
    {
        highlighter: "Documents",
        question: "required for mobile insurance claims",
        description: "To get your mobile insurance claims successfully settled, you would have to submit the following documents to the insurance company",
        answer_in_steps: ["Filled and signed claim form", "Police FIR copy in case of theft or loss of the phone", "Invoice of the phone", "Policy bond"]
    },
    {
        highlighter: "Making",
        question: "a claim under mobile insurance",
        description: "In case your mobile phone suffers any of the covered damages, you can make a claim under your mobile insurance policy. To make a claim, you should follow the below-mentioned steps,",
        answer_in_steps: ["One must immediately report any loss or damage to the mobile phone to the insurance company offering the coverage either on a toll-free number or any other suggested customer support channels mentioned by the company", "Customers must also submit the Claim Form as requested by the insurance company. This can be done either online or by visiting the nearest branch office of the insurance company", "In case of theft or robbery, the customer must file an FIR with the nearest police station or submit the complaint and obtain a copy of it. In case of loss or damage to the mobile phone due to household fire, a report from the fire station is also required by some insurance companies", "Some insurance companies may require the photographs of the damaged mobile phone to be sent to the claims assessor", "Based on the policy document issued by the insurance company to the individual for his/her mobile insurance and internal investigation process of the insurance company, the claims would be settled in either way i.e. replacement or repair of the insured mobile phone", "Some private insurance companies settle the claim bills by directly making a payment to third-party mobile repair centers authorized by the insurance company for repairs", "Some insurance companies only allow one claim settlement per policy term whereas some offer more than one claims in the policy term year. However, one must always check the terms of the mobile insurance policy he/she has purchased", "The policyholders may contact the insurance company representatives during the claim process for any support either by visiting the branch office or contacting them via online channels provided by the insurance company"]
    },
];

export const mobilei_FeatFact = [{
    features:
        [
            {
                f_highlighter: 'Ease of replacing a lost phone',
                f_details: 'When your mobile is stolen or if you lose your phone, which is quite common, you face a considerable financial loss. If your phone was a premium Smartphone, the costs are unimaginable. The insurance policy covers this loss and compensates you financially. This allows you to buy another phone without worrying about your finances.'
            },
            {
                f_highlighter: 'Coverage for repair costs',
                f_details: 'Repair costs can be quite expensive when you drop your phone and damage it or if liquid enters your phone’s internal parts. The costs can go up to tens of thousands which might prove unaffordable. Since a mobile insurance policy covers the repair costs, you don’t have to worry about the financial pinch in case of damages.'
            },
            {
                f_highlighter: 'Is more comprehensive than warranty',
                f_details: 'Mobile phone companies allow you extended warranty which covers damages to the phone. However, these warranties are not operational if you lose your phone or if the phone is stolen. This is where a mobile insurance policy proves beneficial.'
            },
        ],
},
];
// done

export const stocks_abbreviation = [
    {
        short_form: "S.L",
        long_form: "Stop Loss",
        description: "This is used to place a stop loss at the limit price. Here you need to specify a Limit price and a trigger price. When the trigger price is reached, then the stop loss order is sent to the exchange at a limit order mentioned by you. STOP LOSS is used to limit the losses when the price of a stock starts falling.",
        example: "For example, lets say that you are entering stock at Rs 300. However, the price of that stock starts falling and you fear to book losses. In such a scenario, you can place an order to limit the loss to Rs 295. It specifies that you want to execute a trade but only if the specified price is met. Stop-loss is a very good tool to limit risks.",
    },
    {
        short_form: "C.N.C",
        long_form: "Cash N Carry",
        description: "CNC is used in delivery based equity. There is no leverage provided in CNC. However, there is also no auto square off at the end of the session."
    },
    {
        short_form: "M.I.S",
        long_form: "Margin Intraday Square-off",
        description: "It is used for Intraday trading with leverage. All MIS position is auto squared off at the end of the day session.",
    },
    {
        short_form: "MKT",
        long_form: "Market Order",
        description: "When you want to buy/sell a share at the current market price, then you need to place a market order.",
        example: "For example, if the market price (current trading price) of a stock is Rs 100 and you are ready to buy the share at the same price or the price of the market, then you can place a market order. Here, the order is executed instantaneously at the market price. However, please note that the market price keeps fluctuating second-by-second. Therefore, your purchase price might be little different than what you may have noticed before placing the order"
    },

    {
        short_form: "LMT",
        long_form: "Limit Order",
        description: "A limit order means to buy/sell a share at a limit price. If you want to buy/sell a share at a given price, then you place a limit order.",
        example: "For example, if the current market price of a company is Rs 200, however you want to buy it at Rs 195. Then you need to place a limit order. When the market price of ABC falls to Rs 195, then the order is executed."
    },

    {
        short_form: "S.L-M",
        long_form: "Stop loss market",
        description: "This is used to place a stop loss at market price. Here you just have to specify the trigger price. When the trigger price is reached, then the stop loss order is sent to the exchange at market price.",
    },
    {
        short_form: "T.P",
        long_form: "Trigger Price",
        description: "This is used in stop loss orders. It is the price at which you want ‘stop-loss’ to be triggered.",
    },
];

// More here
// Disclosed quantity.This allows traders to disclose only a part of the actual quantity of the stocks that he bought or sold. This disclosed quantity should be more than 10% of the order quantity. For example, let’s say you bought 1000 stocks. However, you can disclose only 400 stocks (if you want). Only the discloses quantity will be shown on the market screen.
// AMO: It stands for aftermarket orders. You can use this facility to place an order when you can’t buy/sell during the trading time. You can place your order between 4:00 PM to 08:59 AM i.e. after the post-closing session and before the pre-opening session.
// Brackte Order (BO): Bracket order is used for higher leverages (than that of MIS). Here, you place an Intraday buy or sell at limit order with a target price and a compulsory stop loss. All the orders are squared off before the end of the day.
// Cover Order (CO): Cover order is used for placing intraday buy or sell at the market order for high leverage (that trading using MIS). Here you just have to specify the stop loss. All the orders will be squared off before the end of the day.
// IOC: It stands for ‘Immediate or cancel’. Here the order is executed as soon as it is released. If the order fails to execute, then it is immediately canceled. In the case of part execution, the remaining quantity (which is not executed) will be canceled.
// Normal (NRML) is used for overnight trading of futures and options. If the client does not want any excess leverage, he can use the product type NRML, and he would not have to worry about auto square-offs. NRML product code is also used for Delivery based trading of Currency.


export const stocks_basic_glossary = [
    {
        long_form: "Long term capital gain",
        description: "Long Term Capital Gains Tax or LTCG Tax is the tax levied on the profit generated by an asset such as real estate and shares, which is held for a long time period. For equity securities it is greater than 1 year and for debt securities greater than 3years.",
    },
    {
        long_form: "Dividend",
        description: "A dividend is a distribution of profits by a corporation to its shareholders. When a corporation earns a profit or surplus, it is able to pay a proportion of the profit as a dividend to shareholders.",
    },
    {
        long_form: "Face value",
        description: "Face value is the value of the stock at the time of its release.",
    },
    {
        long_form: "Promoter",
        description: "An entity or an individual responsible for the incorporation of a company.",
    },
    {
        long_form: "Top Line & Bottom Line",
        description: " The top and the bottom line are two of the most important figures on a company's income statement.The top line is a company's gross revenues, or total sales, before subtracting any operational costs. The bottom line in business is a company's net income. ",
    },
    {
        long_form: "Share Split",
        description: "A stock split or stock dividend increases the number of shares in a company. A stock split causes a decrease of market price of individual shares, not causing a change of total market capitalization of the company. Stock dilution does not occur. ",
    },
    {
        long_form: "Bonus shares",
        description: "Bonus shares are the additional shares that a company gives to its existing shareholders on the basis of shares owned by them. Bonus shares are issued to the shareholders without any additional cost.",
    },
    {
        long_form: "Bonus shares",
        description: "Bonus shares are the additional shares that a company gives to its existing shareholders on the basis of shares owned by them. Bonus shares are issued to the shareholders without any additional cost.",
    },
    {
        long_form: "Who decides shares prices?",
        description: "Stock prices are first determined by a company's initial public offering (IPO) Prior to an IPO, a company is considered a private company, usually with a small number of investors (founders, friends, family, and business investors such as venture capitalists or angel investors).",
    },
    {
        long_form: "what are the types of securities market?",
        description: "There are two types of securities market - Primary Market and Secondary market ",
    },
    {
        long_form: "DEMAT account",
        description: "A Demat account is an account to hold financial securities in electronic form. In India, Demat accounts are maintained by two depository organisations, National Securities Depository Limited and Central Depository Services Limited",
    },
    {
        long_form: "Savings Account",
        description: "An interest-bearing financial deposit account that offers safety to the money along with modest interest rates and quicker liquidity.",
    },
    {
        long_form: "Trading account",
        description: "A trading account can be any investment account containing securities, cash or other holdings. Most commonly, a trading account refers to a day trader’s primary account",
    },
    {
        long_form: "Contract note",
        description: "Contract note is the legal record of any transaction carried out on a stock exchange through a stockbroker. It serves as the confirmation of trade done on a particular day on behalf of a client on a stock exchange.",
    },
    {
        long_form: "Settlement Date",
        description: "Settlement date is a securities industry term describing the date on which a trade settles. That is, the actual day on which transfer of cash or assets is completed and is usually a few days after the trade was done.",
    },
    {
        long_form: "Latest traded price",
        description: "Last Traded Price stands for the price of a stock on which the last transaction or trade occurred. LTP of a stock is a number of the past. The LTP is a reliable metric to assess the value of the stock and how the prices have fluctuated in the past. The LTP always moves with every successful trade.",
    },
    {
        long_form: "Pre-opening market session",
        description: "The duration of the pre-open market session is from 9:00 AM to 9:15 AM, i.e 15 minutes before the trading session starts and is conducted on both the major Indian stock exchanges: NSE and BSE",
    },
    {
        long_form: "After Market Orders",
        description: "AMO is an advance order that allows traders to place buy/sell orders after regular market hours. In other words, an AMO is similar to a normal order with the exception that it is placed after regular market hours",
    },
    {
        long_form: "Gap up",
        description: "When the price of a financial instrument opens higher than the previous day's price, it is gap-up.",
    },
    {
        long_form: "Gap down",
        description: "When the price of a financial instrument opens lower than the previous trading day it is gap-down.",
    },
    {
        long_form: "Announcement date",
        description: "The announcement date is a term most commonly known as the date on which a company announces important details about a high impact decision.",
    },
    {
        long_form: "Record date",
        description: "The record date, or date of record, is the cut-off date established by a company in order to determine which shareholders are eligible to receive a dividend or distribution.",
    },
    {
        long_form: "Types of investors",
        description: "There are various types of investors such as Individual Investors, Partnership/HUF, Companies, Mutual Funds, Societies and Trusts, Financial Institutions and Foreign Institutional Investors (FII's). ",
    },
    {
        long_form: "Depositories",
        description: "A depository is an entity which helps an investor to buy or sell securities such as stocks and bonds in a paper-less manner. Securities in depository accounts are similar to funds in bank accounts.",
    },
    {
        long_form: "Depository Participant (DP)",
        description: "Depository Participant (DP) is described as an Agent (law) of the depository. They are the intermediaries between the depository and the investors. The relationship between the DPs and the depository is governed by an agreement made between the two under the Depositories Act.",
    },
    {
        long_form: "Corporate Actions",
        description: "A corporate action is an event initiated by a public company that brings or could bring an actual change to the securities—equity or debt—issued by the company. Corporate actions are typically agreed upon by a company's board of directors and authorized by the shareholders.",
    },
    {
        long_form: "Volume",
        description: "Volume is the amount of an asset or security that changes hands over some period of time, often over the course of a day. For instance, stock trading volume would refer to the number of shares of a security traded between its daily open and close.",
    },
    {
        long_form: "Trading system",
        description: "A trading system is a set of rules that formulate buy and sell signals without any ambiguity or any subjective elements. These signals are mostly generated by technical indicators or combinations of technical indicators.",
    },
    {
        long_form: "Types of order",
        description: "The most common types of orders are market orders, limit orders, and stop-loss orders.",
    },
    {
        long_form: "Bearish & Bullish(bear and bull market)",
        description: "A bear market is when a market experiences prolonged price declines. It typically describes a condition in which securities prices fall 20% or more from recent highs amid widespread pessimism and negative investor sentiment.A bull market is a market that is on the rise and where the economy is sound; while a bear market exists in an economy that is receding, where most stocks are declining in value.",
    },
    {
        long_form: "Short sell",
        description: "Short selling is an investment or trading strategy that speculates on the decline in a stock or other securities price. It is an advanced strategy that should only be undertaken by experienced traders and investors.",
    },
    {
        long_form: "Shares Auction",
        description: "An auction market is one in which stock buyers enter competitive bids and stock sellers enter competitive offers at the same time. If this sounds like buying and selling stock on a stock exchange, you're right.",
    },
    {
        long_form: "Stop Loss (SL)",
        description: "Stop-loss can be defined as an advance order to sell an asset when it reaches a particular price point. ",
    },
    {
        long_form: "Circuit filter/Breaker",
        description: "Circuit filter is a mechanism used by stock exchanges to curb excessive volatility in markets. It is the maximum fluctuation in price allowed during trading.",
    },
    {
        long_form: "Trend Analysis",
        description: "Trend analysis is the widespread practice of collecting information and attempting to spot a pattern. In some fields of study, the term 'trend analysis' has more formally defined meanings.",
    },
    {
        long_form: "Market capitalization or Market Cap",
        description: "Market capitalization refers to the total market value of a company's outstanding shares of stock. ",
    },
    {
        long_form: "Ring trading",
        description: "That's not relevant for Indians - it's London Metal Exchange investment strategy",
    },
    {
        long_form: "Block deal",
        description: "It is a single transaction, of a minimum quantity of five lakh shares or a minimum value of Rs 5 crore, between two parties which are mostly institutional players. The transaction happens through a separate trading window",
    },
    {
        long_form: "Bid-ask spread",
        description: "The bid–ask spread, is the difference between the prices quoted for an immediate sale and an immediate purchase for stocks, futures contracts, options, or currency pairs. The size of the bid–ask spread in a security is one measure of the liquidity of the market and of the size of the transaction cost.",
    },
    {
        long_form: "Market Indices",
        description: "A stock index, or stock market index, is an index that measures a stock market, or a subset of the stock market, that helps investors compare current price levels with past prices to calculate market performance. It is computed from the prices of selected stocks.",
    },
    {
        long_form: "Sectoral Indices",
        description: "These indices give summaries and benchmarking data to certain sectors. ",
    },
    {
        long_form: "Free float market cap",
        description: "The free-float methodology is a method of calculating the market capitalization of a stock market index's underlying companies.",
    },
    {
        long_form: "Promoters pledging shares",
        description: "When a company needs funds for regular operations or expansion, it has several options to choose from like raising equity capital, issuing corporate bonds, or approaching a bank for a loan, etc. To avail of the loan, the company needs to provide some collateral to the lenders. Usually, financial institutions and banks ask companies to offer promoter’s shares as collateral for the loan. This is pledging of promoter’s shares.",
    },
    {
        long_form: "Lot size",
        description: "Lot size refers to the quantity of an item ordered for delivery on a specific date or manufactured in a single production run.",
    },
    {
        long_form: "OFS (Offer for sale)",
        description: "Offer for sale (OFS) is a simpler method of share sale through the exchange platform for listed companies.",
    },
    {
        long_form: "Further Public Offer (FPO)",
        description: "A follow-on public offer is also called further public offer. When a listed company comes out with a fresh issue of shares or makes an offer for sale to the public to raise funds it is known as FPO.",
    },
    {
        long_form: "Book Value",
        description: "In accounting, book value is the value of an asset according to its balance sheet account balance. ",
    },
    {
        long_form: "Merchant Banker",
        description: "Merchant banks are experts in international trade, which makes them specialists in dealing with multinational corporations.",
    },
    {
        long_form: "Price Band",
        description: "A price band is a policy instrument that serves to insulate domestic producers and processors when the world price for a commodity falls below a calculated reference price.",
    },
    {
        long_form: "Book Building Issue",
        description: "Book building is a systematic process of generating, capturing, and recording investor demand for shares. Usually, the issuer appoints a major investment bank to act as a major securities underwriter or bookrunner.",
    },
    {
        long_form: "Prospectus",
        description: "A prospectus, in finance, is a disclosure document that describes a financial security for potential buyers.",
    },
    {
        long_form: "Importance of Primary Markets",
        description: "Primary Markets are where the securities are issued to the investors directly. ",
    },
    {
        long_form: "Underwriter",
        description: "An underwriter is any party that evaluates and assumes another party's risk for a fee.  Underwriters play a critical in many industries in the financial world, including the mortgage industry, insurance industry, equity markets, and some common types of debt security trading. ",
    },
    {
        long_form: "Listing of IPO (Initial public offer) ",
        description: "It is the event that marks the entry of a company into the stock market. From the IPO, the shares of the company are available for the general public. ",
    },
    {
        long_form: "Price Determination",
        description: "Determination of Prices means to determine the cost of goods sold and services rendered in the free market. In a free market, the forces of demand and supply determine the prices. The Government does not interfere in the determination of the prices.",
    },
];

export const stocks_tradingtypes = [
    {
        long_form: "Intraday Trading",
        steps: ["Intraday trading involves buying and selling stocks within the same trading day. Here stocks are purchased, not to invest, but to earn profits by harnessing the movement of stock indices. Thus, the fluctuations in the prices of the shares are monitored to earn profits from the trading of stocks.", "An online trading account is set up for intraday trading. While doing intraday trading, you need to specify that the orders are specific to intraday trading. As the orders are squared off before the end of the trading day, Thus being said Intraday Trading."],
        note: "Intraday trading is riskier than investing in the regular stock market. It is important, especially for beginners, to understand the basics of such trading to avoid losses. It is mostly suitable for seasoned investors and is not everyone’s cup of tea. It requires the investor to monitor position minute by minute."
    },
    {
        long_form: "Delivery trading",
        steps: ["In delivery transactions, an investor is not required to buy and sell shares within the same day. In such transactions, the individual can hold the shares for a longer-term depending on his/her willingness. The duration can range from two days to even two decades or more", "The market allows an individual to pay only a part of the full price; thus, a trader can technically gain more by investing less"]
    },
    {
        long_form: "Margin Trading",
        steps: ["In the stock market, margin trading refers to the process whereby individual investors buy more stocks than they can afford to. Margin trading also refers to intraday trading in India and various stock brokers provide this service.", "time, various brokerages have relaxed the approach on time duration. The process requires an investor to speculate or guess the stock movement in a particular session. ", "Margin trading is an easy way of making a fast buck. With the advent of electronic stock exchanges, the once specialised field is now accessible to even small traders. "]
    },
    {
        long_form: "Swing Trading",
        steps: ["Swing trading is a style of trading that attempts to capture short- to medium-term gains in a stock (or any financial instrument) over a period of a few days to several weeks. ", "time, various brokerages have relaxed the approach on time duration. The process requires an investor to speculate or guess the stock movement in a particular session.", "Swing traders primarily use technical analysis to look for trading opportunities. These traders may utilize fundamental analysis in addition to analysing price trends and patterns."]
    },
    {
        long_form: "Technical Trading",
        steps: ["Technical analysis is a trading discipline employed to evaluate investments and identify trading opportunities by analyzing statistical trends gathered from trading activity, such as price movement and volume.", "Unlike fundamental analysis, which attempts to evaluate a security's value based on business results such as sales and earnings, technical analysis focuses on the study of price and volume. ", "Technical analysis tools are used to scrutinize the ways supply and demand for a security will affect changes in price, volume and implied volatility. Technical analysis is often used to generate short-term trading signals from various charting tools, but can also help improve the evaluation of a security's strength or weakness relative to the broader market or one of its sectors. This information helps analysts improve their overall valuation estimate."]
    },
    {
        long_form: "Fundamental trading",
        steps: ["Fundamental trading is a method where a trader focuses on company-specific events to determine which stock to buy and when to buy it.", "Trading on fundamentals is more closely associated with a buy-and-hold strategy rather than short-term trading. There are, however, specific instances where trading on fundamentals can generate substantial profits in a short period."]
    },
    {
        long_form: "Momentum Trading",
        steps: ["Momentum investing is a trading strategy in which investors buy securities that are rising and sell them when they look to have peaked. The goal is to work with volatility by finding buying opportunities in short-term uptrends and then sell when the securities start to lose momentum.", "Trading on fundamentals is more closely associated with a buy-and-hold strategy rather than short-term trading. There are, however, specific instances where trading on fundamentals can generate substantial profits in a short period.", "Then, the investor takes the cash and looks for the next short-term uptrend, or buying opportunity, and repeats the process. Skilled traders understand when to enter into a position, how long to hold it for, and when to exit. They can also react to short-term, news-driven spikes or selloffs. "],
        note: "Risks of momentum trading include moving into a position too early, closing out too late, and getting distracted and missing key trends and technical deviations.",
    },
    {
        long_form: "Scalping or Micro Trading",
        steps: ["Scalping is a trading style that specializes in profiting off of small price changes. This generally occurs after a trade is executed and becomes profitable.", "Scalping requires a trader to have a strict exit strategy because one large loss could eliminate the many small gains the trader worked to obtain. Thus, having the right tools—such as a live feed, a direct-access broker, and the stamina to place many trades—is required for this strategy to be successful."],
    },
    {
        long_form: "Short Sell",
        steps: ["Short selling is an investment or trading strategy that speculates on the decline in a stock or other securities price. It is an advanced strategy that should only be undertaken by experienced traders and investors.", "Traders may use short selling as speculation, and investors or portfolio managers may use it as a hedge against the downside risk of a long position in the same security or a related one. Speculation carries the possibility of substantial risk and is an advanced trading method."]
    },
    {
        long_form: "Buy Today Sell Tomorrow Trading",
        steps: ["BTST (Buy Today, Sell Tomorrow) is a facility that allows customers to sell shares before they are credited into a demat account or take the delivery of shares. The decision has to be made in 2 days.", "This facility is also known as ATST or Acquire Today, Sell Tomorrow. The reverse of BTST is called STBT i.e. Sell Today, Buy Tomorrow.", "This facility is offered by most of the stock brokers in India like Zerodha,ICICIdirect, etc., where you can buy a stock today and sell it tomorrow."]
    },
    {
        long_form: "Sell Today Buy Tomorrow Trading",
        steps: ["Sell Today Buy Tomorrow (STBT) is a facility that allows customers to sell the shares in the cash segment (shares which are not in his demat account) and buy them the next day.", "STBT is the reverse of BTST (Buy Today Sell Tomorrow).None of the brokers in India offers STBT in the cash market as it's not permitted. You cannot sell shares if you don't have them in your demat account as brokers can't guarantee if those shares will be available in the market tomorrow to buy. If shares are not available tomorrow to buy, the broker will get penalized by the exchange for not to deliver the shares to the initial buyer.", "Some brokers did this in the past but this was extremely risky. They used other customers' shares in their pool account for this."]
    },
];

export const stocks_ordertypes = [
    {
        long_form: "Market Order",
        steps: ["The market order is the simplest order which is an order to purchase at the current asking price. This is like walking into the store and purchasing the toothpaste at the current price.", "Think of using limit orders when you want to buy a stock as soon as possible. If you have done your research and the price is right, then a market order is the fastest way to purchase a stock."],
        note: "The advantage of a market order is that as long as there are willing buyers and sellers, you are almost always guaranteed your order will be executed. The disadvantage is the price you pay when your order is executed may not be the price you expected."
    },
    {
        long_form: "Limit Order",
        steps: ["A limit order is an order to sell at a higher specified price or buy at a lower specified price. For example, if you’re looking to buy toothpaste and you’re not willing to buy it for more than $1. You can set a limit order to tell the system to only purchase 5 boxes of toothpaste when the price is lower than $1. When a sale comes, the system will automatically execute the order and buys the 5 boxes at a price lower than $1.", "Think of using limit orders when you’re looking to automatically realize your gains or purchase a stock at a value."]
    },
];

export const stocks_types = [
    {
        long_form: "Large cap stocks ",
        steps: ["These are often stocks of Blue-chip companies which are established enterprises with large reserves of cash at their disposal. The size of the company doesn't donate its growth.", "In fact it is the small stock companies that tend to outperform them over the longer time frame. But large cap stocks do come with the benefit of allowing the investors to reap higher dividends in comparison to the smaller and mid cap companies stocks, ensuring that the capital is preserved over the long term period."],
    },
    {
        long_form: "Mid cap stocks",
        steps: ["These are the stocks of medium sized companies that have a market capitalization of INR 250 Crore to about INR 4000 crore.", "These companies have a well recognized name in the market which brings along the benefit of potential for growth, as well as the stability that is usually accompanied with being a seasoned player in the market.", "Mid cap companies have a good track record of steady growth and are very similar to blue chip stocks barring their  size. In the long term these stocks do and grow well."]
    },
    {
        long_form: "Small cap stocks",
        steps: ["As is suggestive of the name, small cap stocks have the smallest value in the market as compared to its counterparts. These are small sized companies that have a market capitalization of upto INR 250 and have the potential to grow at a good pace in the future.", "Investors who are willing to commit to a long term and are not very particular about the current dividends, and are willing to stand their ground during price volatility, can make significant gains in the future.", "As an investor you can buy these stocks when they are available at a cheap price during the initial stage of the company. There is no surety about how the company will perform in the market since they are relatively new. ", "Because these small cap companies are new they are highly volatile and their growth impacts the value and revenue of the company to a huge extent."]
    },
];

export const stocks_brokertypes = [
    {
        long_form: "Traditional Brokers",
        steps: ["Traditional stock brokers or full-service stock brokers offer a wide array of services and products, including financial and retirement planning, investing and tax advice, regular portfolio updates, and margins to purchase investment products on credit which will be subjected to necessary terms and conditions. As they offer an array of services, their brokerage charges are expensive. ", "Few examples are Axis Securities, Kotak Securities, Share Khan, etc."],
    },
    {
        long_form: "Discount Brokers",
        steps: ["Discount stock brokers or online stock brokers usually allow clients to trade on their own with little or no interaction with a live broker. Discount brokers could be a better choice for the fee-conscious investors who prefer to do it all by themselves. These brokerages will generally not offer investment advice, although it’s common for a discount broker to offer free research and educational tools to help you make better investment decisions.", "Few examples of discount brokers are Zerodha, Upstock, etc."],
    }];


export const stocks_tradertypes = [
    {
        long_form: "Day Trader",
        steps: ["Day traders are traders who execute intraday strategies to profit off price changes for a given asset.Day traders employ a wide variety of techniques in order to capitalize on market inefficiencies.", "Day trading can be a lucrative undertaking, but it also comes with a high degree of risk and uncertainty. Heightened market volatility excited them. "],
    },
    {
        long_form: "Swing Trader",
        steps: ["Swing traders are those who try to profit from market swings of a minimum of one day and as long as several weeks. In contrast to swing traders, day traders usually are in and out of the market in one day and trend traders often hold positions for several months.", "They look for definitive trends and reversals in prices."],
    },
    {
        long_form: "Technical Trader",
        steps: ["A trader who places trades based on the technical analysis of price charts. ", "Technical traders analyze price charts to develop theories about what direction the market is likely to move. A technical trader ignores fundamental factors such as the change in supply and demand. They ignore breaking news about the company.", "They are only concerned about the price trends and are methodical in their approach rather than impulsive or instinctive. "],
    },
    {
        long_form: "Fundamental Trader",
        steps: ["Fundamental trading is a method where a trader focuses on company-specific events to determine which stock to buy and when to buy it. Trading on fundamentals is more closely associated with a buy-and-hold strategy rather than short-term trading.", "These traders analyze the true value of an asset. They look out for overpriced or underpriced prices. They are attentive to economic data, company earnings, and revenue. "],
    },
    {
        long_form: "The Long Term Trader",
        steps: ["Long term trading trader holds on to a position for an extended period of time. A position trade can last anywhere from a few weeks to a couple of years.", "Typically, a long term trader would prefer putting money in the market rather than in a bank deposit earning minimal interest rate."],
    }
];

export const stocks_adv_glossary = [
    {
        long_form: "Accumulation/Distribution",
        description: "Investors see if traders are accumulating (buying) or distributing (selling) a particular stock by comparing its closing price to its high and low prices—then multiplying that by its volume.",
    },
    {
        long_form: "After-Hours Trading",
        description: "Trading stocks after normal market hours through an electronic market, typically between 4:05 and 8:00 PM, is after-hours trading. With the advent of the internet and apps, many banks and brokerages now offer after-hours trading, and even pre-market trading, before the markets open. Some brokerages even offer trading 24/7, though executing trades can be more difficult, due to low trading activity.",
    },
    {
        long_form: "Analyst Ratings",
        description: "These ratings from stock brokers and ratings agencies urge traders to “buy,” “sell,” or “hold,” and are determined by the projected performance of a stock and its current level of risk. Some serious investors often live and die (if only proverbially) by the words of their favorite analysts and will base their trading strategy on their expertise and insight—while others will only take their ratings with a grain of salt.",
    },
    {
        long_form: "Analyst Ratings Trading",
        description: "Analysts may suggest additional actions with ratings like “underperform” and “outperform” to give more nuance to their projections beyond “buy,” “sell,” and “hold.”",
    },
    {
        long_form: "Arbitrage",
        description: "When a particular security or commodity trades on two different markets, investors can create a profit by leveraging the temporary price differences in each venue. This strategy has become a particularly popular method of making money with cryptocurrency, since the individual currencies may be traded on multiple markets, and its price may be different. Investors with an eye on the markets or serious foresight can buy low in one market, and sell high in another.",
    },
    {
        long_form: "Asset Allocation",
        description: "Balancing out the percentage of various asset classes (such as stocks, bonds, and cash) in your portfolio is called asset allocation; the goal is to minimize risk and maximize reward.",
    },
    {
        long_form: "Average Daily Trade Volume",
        description: "The number of trades for a particular security, divided by a specific number of days, will yield its ADTV, or average daily trading volume.The number of trades for a particular security, divided by a specific number of days, will yield its ADTV, or average daily trading volume.",
    },
    {
        long_form: "Backdoor Roth IRA",
        description: "This strategy (not to be confused with a similarly named product) allows high-income earners to place their retirement savings into a tax-deferred Roth IRA.",
    },
    {
        long_form: "Back-End Load",
        description: "When investors sell their shares in a mutual fund, they pay this sales commission.",
    },
    {
        long_form: "Balance Sheet",
        description: "An accounting of a company’s assets, liabilities (debt), and the capital it receives from shareholders.",
    },
    {
        long_form: "Balanced Fund",
        description: "A mutual fund with a mixture of stocks, commodities, and/or bonds.",
    },
    {
        long_form: "Bar Chart",
        description: "This price evaluation tool gives a visual representation of a particular security’s change in price over time.",
    },
    {
        long_form: "Bear Market",
        description: " When prices in the market have declined by 20% or more during the last two months or beyond, this is called a Bear Market. It’s common for pessimism, fear, and other negative sentiments from investors to accompany a bear market, and even fuel its downward spiral. Cyclical bear markets can last from a few weeks to a few years, while secular bear markets can last 1-2 decades",
    },
    {
        long_form: "Beige Book",
        description: "Published 8 times annually, this index is a prominent indicator of the US Economy.",
    },
    {
        long_form: "Beta",
        description: "This measurement tracks the relative volatility of a given security in comparison to a particular standard.",
    },
    {
        long_form: "Bid-Ask Spread",
        description: "Market makers facilitate orderly trading with this tool, which helps them buy and sell securities.",
    },
    {
        long_form: "Black Swan",
        description: "Black swan is used to describe an extremely rare and unpredictable event that triggers a perfect storm of catastrophic consequences.",
    },
    {
        long_form: "Blockchain",
        description: "This decentralized, digital ledger provides a secure record of digital currency transactions. Though blockchain started out as a way to keep a record of digital currency, the premise of decentralized record-keeping carries huge implications for how information is stored. Companies in all types of industries from health to supply chain are exploring the possibilities offered by a secure, decentralized accounting system.",
    },
    {
        long_form: "Blue-Chip Stocks",
        description: "Known for the stability of the stock and the lasting quality of the company, blue-chip stocks tend to have market capitalizations of over $5 billion. These companies tend to be household brand names that have imprinted their name on the American conscious, such as Coca-Cola, Disney, and IBM. Many of these companies are also attractive to investors because they issue dividends.",
    },
    {
        long_form: "Bollinger Bands",
        description: "A way to analyze the relative stability or volatility of a particular security by showing its price activity with bandwidths that represent the space between its highs and lows.",
    },
    {
        long_form: "Bond",
        description: "An organization such as a government or company can issue loans, represented by fixed-income bonds that offer the investor a relatively more stable return. After a certain period of time, established at the outset of the bond, it can be redeemed for a particular price. Government bonds are regarded as one of the most secure investment vehicles, since it is extremely unlikely that the U.S. government will default on its obligation to repay the loan.",
    },
    {
        long_form: "Book Value Per Share",
        description: "A ratio that compares common shareholder equity in a company to the volume of outstanding, unowned shares.",
    },
    {
        long_form: "Bull Market",
        description: "Investors get buyers fever and continue to drive up prices, whenever a particular market or asset class rises in value; this is called a bull market. Though the term commonly relates to the stock market, it can also be applied to other asset classes such as bonds, currencies, commodities, and even real estate. Though stock prices always rise and fall, the upward trend indicated by the term “bull market” can last for months or years.",
    },
    {
        long_form: "Buy Rating",
        description: "This rating from analysts predicts that the price of a particular security will probably move up over the next period of time.",
    },
    {
        long_form: "Buyback ",
        description: "Companies can actually purchase back their shares of stock in what is called a share repurchase program or stock buyback.",
    },
    {
        long_form: "Buy-Side Analysts",
        description: "Institutional investors such as hedge, mutual, and pension funds employ buy-side analysts to do equity research.",
    },
    {
        long_form: "Call Option",
        description: "Investors buy the right to purchase a specific number of shares of stock at an agreed price, without locking themselves into the obligation.",
    },
    {
        long_form: "Call Option Volume",
        description: "The amount of buying and selling of a certain security is called its volume. Stocks, currencies, and other types of asset classes all have a trading volume.",
    },
    {
        long_form: "Candlestick",
        description: "This technical indicator shows investors the opening and closing prices of a particular security during a certain amount of time.",
    },
    {
        long_form: "Capital Gains",
        description: "The positive difference in value between an asset’s selling price and what the investor first paid for that asset is its capital gain.",
    },
    {
        long_form: "Capital Gains Distribution",
        description: "This is a payment distributed to shareholders of a mutual fund once certain stocks and securities have been liquidated and the dividends and interest earned have been calculated.",
    },
    {
        long_form: "Cash Asset Ratio",
        description: "This measurement tool allows investors to compare short-term liabilities and highly liquid assets.",
    },
    {
        long_form: "Cash Flow",
        description: "The amount of cash and/or equivalents a company brings in, obtained by calculating its income and subtracting its expenses. Cash flow also applies to individual investors as well, if they have income-generating assets such as stocks that pay dividends, rental properties, or ownership in a business. Investors often regard cash flow as the wellspring and fuel of their financial success and growth.",
    },
    {
        long_form: "Catch-Up Contributions",
        description: "Deposits that are made in addition to allowable retirement plans sponsored by employers.",
    },
    {
        long_form: "CBOE Russell 2000® Volatility Index",
        description: "his index helps indicate and predict volatility in the stock market in relation to option prices in the Russell 2000 Index.",
    },
    {
        long_form: "CD Ladder",
        description: "This investing strategy divides a fixed amount of money equally between multiple CDs (certificates of deposit) even if they were purchased at different maturity dates.",
    },
    {
        long_form: "Certificate of Deposit (CD)",
        description: "This financial product allows banks, credit unions, and brokerage firms to hold deposited funds until the mature at a fixed date; in return, they offer the depositor a fixed rate of return.",
    },
    {
        long_form: "Channel Trading ",
        description: "This trading strategy relies on technical analysis on price movement patterns that create and inform defined trading channels.",
    },
    {
        long_form: "Circuit Breakers",
        description: "Circuit breakers are a security measure that has been put in place by the Securities & Exchange Commission (SEC) as an effort to reduce panic-selling on U.S. stock exchanges.",
    },
    {
        long_form: "Closed-End Mutual Funds",
        description: "These special mutual funds have their shares traded in the open market, much like stocks or EFTs.",
    },
    {
        long_form: "Commodities",
        description: "Raw materials used every day by millions or billions of consumers, the prices of which are based on supply and demand.",
    },
    {
        long_form: "Compound Annual Growth Rate (CAGR)",
        description: "This rate represents the mean average rate of growth of a particular security over a specific time period.",
    },
    {
        long_form: "Compound Interest",
        description: "Interest accrued on both the principal and the interest from the previous period is called compound interest.",
    },
    {
        long_form: "Consumer Price Index (CPI)",
        description: "This index examines the average cost of particular services and consumer goods ranging from food to tech to health.",
    },
    {
        long_form: "Convertible Shares",
        description: "This class of preferred shares give shareholders an actual ownership stake in the company.",
    },
    {
        long_form: "Correction",
        description: "A statistical even where security or assets prices decline at least 10% from a recent peak.",
    },
    {
        long_form: "Cost of Capital",
        description: "This amount of money is what’s needed to make capital budgeting worthwhile.",
    },
    {
        long_form: "Cost of Debt",
        description: "The effective interest rate, expressed as a percentage, that a company will pay on all of its outstanding debts",
    },
    {
        long_form: "Cost of Equity",
        description: "The return a business can expect from received equity financing. For individual investors, this is the expected return in exchange for their investment through buying shares.",
    },
    {
        long_form: "Cost of Goods Sold (COGS)",
        description: "Also referred to as the cost of sales, this business stat shows how much cost is associated with each sale the business makes.",
    },
    {
        long_form: "Coverage Ratio",
        description: "A series of ratios used by investors to assess the ability of a company to meet their financial obligations.",
    },
    {
        long_form: "Cryptocurrencies",
        description: "A digital currency formed from a series of coded transactions, the record of which is kept on a digital ledger called blockchain. The first digital currency was Bitcoin, which has since skyrocketed in price even as others such as Ethereum have come on the scene. Though cryptocurrency seems strange to most consumers, it’s becoming increasingly accepted as payment, even among online retailers.",
    },
    {
        long_form: "Current Ratio",
        description: "Also called the working capital ratio, this metric allows investors to gauge a company’s liquidity.",
    },
    {
        long_form: "Day Trading",
        description: "Buying and selling securities within a single day, even within several hours or minutes. Investors with either a serious understanding of the market or a high-risk tolerance track the market all day, buying low and selling high. Some investors use these profits as their primary source of income, though many amateurs who have not put in the requisite research have failed.",
    },
    {
        long_form: "Dead Cat Bounce",
        description: "This type of event occurs as part of a long-lasting downtrend in price. When the price falls significantly from a previous high, it may appear to bounce back or suggest a reversal of the downtrend.",
    },
    {
        long_form: "Death Cross",
        description: "A technical chart pattern indicating that a particular security could be exposed to major selling pressure.",
    },
    {
        long_form: "Debt-To-Equity Ratio",
        description: "This metric shows how much debt a company has, in relation to the value of their stock.",
    },
    {
        long_form: "Depreciation",
        description: "This accounting practice allows a company to record a portion of an asset’s cost over its lifespan as an operating expense.",
    },
    {
        long_form: "Derivative",
        description: "A contract between two parties, the value of which is determined by underlying securities such as stocks, bonds, commodities, or precious metals.",
    },
    {
        long_form: "Depreciation",
        description: "This accounting practice allows a company to record a portion of an asset’s cost over its lifespan as an operating expense.",
    },
    {
        long_form: "Derivative",
        description: "A contract between two parties, the value of which is determined by underlying securities such as stocks, bonds, commodities, or precious metals.",
    },
    {
        long_form: "Diluted Earnings Per Share",
        description: "This metric helps analysts estimate the quality of earnings per share (EPS) offered by a particular stock.",
    },
    {
        long_form: "Discount Rate",
        description: "The interest rate the Federal Reserve Banks charge to financial institutions borrowing money from their short-term (usually overnight) discount window. This is the most common definition.",
    },
    {
        long_form: "Diversification",
        description: "Allocating capital in a way that reduces risk and volatility, by investing in a variety of asset classes is called diversification. For example, investors diversifying their investments might invest their capital in stocks, real estate, commodities, and angel investing. Even within a particular asset class, investors may diversify their holdings, for example purchasing stock in many different types of industries.",
    },
    {
        long_form: "Dividend ",
        description: "An investing strategy that focuses on stocks that pay out higher dividend yields or have dividends that are growing quickly. These stocks are issued by companies who disburse a portion of their profit on a regular basis. A dividend payout for each stock might only be pennies, but if an investor owns many shares of that stock, their earnings can snowball into a massive amount. Some investors derive the majority of their income from dividends.",
    },
    {
        long_form: "Dividend Achievers",
        description: "A company the common stock of which has posted increasingly larger dividend payouts at least annually over the last 10 years.",
    },
    {
        long_form: "Dividend Aristocrat Index",
        description: "A group of blue-chip S&P 500 companies with a concrete history of increasing their dividend payouts for 25 consecutive years or more.",
    },
    {
        long_form: "Dividend Kings",
        description: " Dividend kings are companies who have increased their dividend payout for at least 50 consecutive years.",
    },
    {
        long_form: "Dividend Reinvestment Plan (DRIP)",
        description: "This plan is a program that allows investors to reinvest the profits disbursed by the securities they own which have paid dividends.",
    },
    {
        long_form: "Dividend Yield",
        description: "This stat, also called a dividend-price ratio, shows a company’s dividend as a percentage of its stock price.",
    },
    {
        long_form: "Dogs of the Dow",
        description: "This dividend-focused investing strategy focuses on beating the DJIA, or Dow Jones Industrial Average annually by loading a portfolio up with a higher proportion of high-yield dividend stocks.",
    },
    {
        long_form: "Dollar Cost Averaging",
        description: "This investment strategy has investors buying fixed dollar amounts of a security at regular intervals, no matter the individual price of each security. Stock prices may rise and fall over the years, but investors who have practice this strategy will build a serious portfolio of stocks as the decades go by. Some investors such as Warren Buffet are strong promoters of this strategy.",
    },
    {
        long_form: "Dow Jones Industrial Average (DJIA)",
        description: "One of the most-watched indices worldwide, these 30 blue chip stocks are tracked as an indicator of the market’s overall health.",
    },
    {
        long_form: "Earnings Per Share",
        description: "EPS is a metric that divides company profit by the number of outstanding common shares to show how much earning power each individual share carries.",
    },
    {
        long_form: "Earnings Reports ",
        description: "These reports are part and parcel of the legal obligation that publicly held companies have to reveal an accurate picture of their financial performance.",
    },
    {
        long_form: "Economic Bubble",
        description: "When an asset class rises in value based on investor sentiment rather than actual stat-driven analysis.",
    },
    {
        long_form: "Economic Reports",
        description: "These reports offer a variety of data about different sectors of the economy, both domestic and global; they are published regularly by several departments in the Federal Government.",
    },
    {
        long_form: "Elliott Wave Theory",
        description: "This theory, developed by Ralph Nelson Elliott in the 1930s, proposes that crowd psychology shifts between optimism and pessimism, in turn affecting market prices in a natural pattern.",
    },
    {
        long_form: "Equal Weight Rating",
        description: "This analyst rating indicates the performance of a particular security will match the average return of other stocks covered by the analyst.",
    },
    {
        long_form: "Equity Income ",
        description: "Income generated from stock dividends, which are cash earnings paid out to shareholders by the company.",
    },
    {
        long_form: "Exchange-Traded Funds (ETFs)",
        description: "These pooled investment products are securities in a specific type of index or industry. In this way, an ETF is like a cross between owning individual stocks and participating in a mutual fund.",
    },
    {
        long_form: "Ex-Dividend",
        description: "Once the issuing company announces a date of record for their dividend, the first day of trading after that date is ex-dividend for that security.",
    },
    {
        long_form: "FAANG Stocks",
        description: "These are stocks issued by Facebook, Amazon, Apple, Netflix, and Google (Alphabet).",
    },
    {
        long_form: "Federal Reserve",
        description: "The Fed plays a crucial role in guiding domestic monetary policy; it is the central bank of the United States. The centralized control of the monetary system was implemented in 1913 in order to alleviate and mitigate potential financial crises—further crises such as the Great Depression have led to an increase in its responsibilities and roles.",
    },
    {
        long_form: "Fibonacci Channel",
        description: "This indicator leverages Fibonacci numbers to predict price movement through support and resistance.",
    },
    {
        long_form: "Fiduciary",
        description: "One with an ethical and legal duty to put the needs of their client above their own interests, such as a broker or financial planner. A fiduciary figure is contractually bound to provide their client with well-intentioned financial advice. Deviating from this responsibility is regarded as unethical, and in many cases, could be a crime.",
    },
    {
        long_form: "FinTech",
        description: "FinTech is a culturally shortened derivation of the words Financial Technology. It refers to the use of technology that is used to automate and provide innovation to financial services.",
    },
    {
        long_form: "Float",
        description: "The number of shares issued by a company, which are traded without restriction on a secondary market.",
    },
    {
        long_form: "Forex",
        description: "The Foreign Exchange Market (FX) is the largest in the world, with the highest amount of liquidity.",
    },
    {
        long_form: "Front-End Load",
        description: "A sales charge paid by investors when they purchase into a mutual fund.",
    },
    {
        long_form: "Fundamental Analysis",
        description: "Analysis that seeks to assess which stocks are valuable, and which are not, through analyzing sales, P/E ratio, profits, EPS (earnings per share), and other factors.",
    },
    {
        long_form: "Futures Contract",
        description: "Trading futures involves buyers and sellers who agree to trade a specified asset in a particular amount at a particular date at a particular price.",
    },
    {
        long_form: "G-20",
        description: "This group of 20 finance ministers and central bank governors from 19 countries is the primary global economic council. The 20th member of the G-20 is the European Union. The G-20 includes the G-7, which is a group of the world’s most developed economies, including the United States, the United Kingdom, France, Germany, Italy, Japan, and Canada.",
    },
    {
        long_form: "Gap Down Stocks",
        description: "These stocks open at a significantly lower price level, due to after-hours trading that has negatively impacted the stock.",
    },
    {
        long_form: "Gap Up Stocks",
        description: "These stocks open at a significantly higher price level, due to after-hours trading that has positively impacted the stock.",
    },
    {
        long_form: "Golden Cross",
        description: "This technical indicator is formed on a candlestick chart when a short-term moving average and long-term moving average cross paths.",
    },
    {
        long_form: "Google Finance",
        description: "This search tab on Google.com gives investors the ability to track securities and filter stocks by specific requirements.",
    },
    {
        long_form: "Google Finance Portfolio",
        description: "This tool allows investors to create a watchlist to gauge and chart the daily performance of their current holdings.",
    },
    {
        long_form: "Green Investing ",
        description: "Investing in stocks, bonds, or mutual funds that focus on companies and/or projects dedicated to conserving natural resources, producing natural energy, and clean air and water is called Green Investing.",
    },
    {
        long_form: "Gross Domestic Product (GDP)",
        description: "This measurement indicates the monetary value of all final services and goods produced by a specific country within a specific time frame.",
    },
    {
        long_form: "Growth and Income Funds",
        description: "This mutual fund or ETF attempts to grow in value through capital appreciation and provide income through dividends.",
    },
    {
        long_form: "Growth Stocks",
        description: "These are stocks issued by companies that increase in value, rather than producing higher dividends. Their rising prices optimally outperform the general trend of the greater market.",
    },
    {
        long_form: "Hedge Funds",
        description: "This alternative to traditional investing uses a pool of funds from contributing investors who meet particular criteria. A hedge fund has a goal to create an absolute guaranteed return for its investors. This is done through a complex and diverse market strategy. Hedge funds generally avoid regulation because they are not publicly accessible; only select and invited investors can participate.",
    },
    {
        long_form: "High-Yield Dividend Stocks",
        description: "Stocks that payout generous dividends are regarded as a proven way for investors to increase their assets. They are a solid choice for investors of all types, and not just those who are averse to risk.",
    },
    {
        long_form: "Hold Rating",
        description: "A hold rating indicates investors should neither buy nor sell a particular security since it will most likely stay at a relatively constant price.",
    },
    {
        long_form: "Holder of Record",
        description: "The registered owner of a security is the holder of record; it can be an individual investor or entity like a financial institution.",
    },
    {
        long_form: "Index Funds",
        description: "This mutual fund includes securities picked to match or track specific market indices like the S&P 500. They are regarded by some as the most stable form of investing for individuals saving for retirement.",
    },
    {
        long_form: "Inflation",
        description: "The general decline in a currency’s purchasing power, which is often accompanied by a price increase of goods and services.",
    },
    {
        long_form: "Initial Coin Offering (ICO)",
        description: "Also called a token offering, this crowdfunding tool allows an investor to provide a cryptocurrency startup with existing traditional currency or cryptocurrency in return for tokens of the new cryptocurrency.",
    },
    {
        long_form: "Initial Public Offering (IPO)",
        description: "This formal process involves a private company raising capital through the sale of stock to institutional investors on a major stock exchange, for the first time. On rare occasions, individual investors can purchase stock in the IPO.",
    },
    {
        long_form: "Insider Trading",
        description: "Buying or selling a security based on information that is not available to the public. Participants in insider trading are often leveraging information that should be or will soon be public. As a result, insider trading is regarded as an illegal activity.",
    },
    {
        long_form: "Institutional Investors",
        description: "These large companies or firms buy and sell securities along the lines of their investment strategy, along with facilitating trades for members and shareholders.",
    },
    {
        long_form: "Intrinsic Value",
        description: "This stat helps determine whether a particular security is overvalued or undervalued.",
    },
    {
        long_form: "Inverted Yield Curve",
        description: "This curve indicates market conditions in which short-term debt instruments (like 2-year bonds) have a higher yield than long-term debt instruments (like a 10-year bond). Both the short-term and long-term instruments of debt must be of the same quality, for example, U.S. Treasury Bonds.",
    },
    {
        long_form: "Leveraged Buyout (LBO)",
        description: "This financial transaction involves acquiring a company through borrowed money. The buying party does not have to put their own capital at risk, and instead has leveraged capital from other sources.",
    },
    {
        long_form: "LIBOR",
        description: "The standard rate international banks would most likely charge one another for inter-bank lending is referred to as The London Interbank Offered Rate. This rate estimation is formulated by leading London Banks submitting their estimate of what other banks would charge them. The LIBOR is a benchmark for short-term interest rates around the world, but it will be discontinued in 2021.",
    },
    {
        long_form: "Liquidity",
        description: "A measurement of how easily an asset such as stocks, cash, real estate, or valuables could be bought or sold without affecting the standard price.",
    },
    {
        long_form: "Lock-Up Period Expiration",
        description: "Also known as a lock-up agreement, investors are restricted from buying or redeeming shares for a period of time usually numbering 90-180 days. ",
    },
    {
        long_form: "Management Fee",
        description: "Compensation for an investment manager to manage the fund for the trader.",
    },
    {
        long_form: "Margin",
        description: "Collateral supplied by a trader to their broker in order to trade securities, futures, and currencies.",
    },
    {
        long_form: "Market Capitalization",
        description: "The value of outstanding shares from an issuing company, used by investors to rank company sizes, in distinction to sales or total assets.",
    },
    {
        long_form: "Market Indexes",
        description: "A theoretical portfolio of investments that represents a specific market segment.",
    },
    {
        long_form: "Market Perform",
        description: "This rating suggests a neutral outlook on a stock’s projected performance when compared to benchmark indexes; it’s also referred to as a hold rating.",
    },
    {
        long_form: "Market Timing",
        description: "A trading strategy that focuses on changing the ratio of different assets within a portfolio to take advantage of changing prices within each asset class.",
    },
    {
        long_form: "Momentum Indicators",
        description: "These technical indicators help traders confirm the veracity of a buy or sell rating.",
    },
    {
        long_form: "Momentum Investing",
        description: "This strategy involves identifying charted patterns to find stocks that exhibit specific trends.",
    },
    {
        long_form: "Monthly Dividend Stocks ",
        description: "These securities are issued by companies that pay dividends every month, which some investors rely on as a regular source of income.",
    },
    {
        long_form: "Most Active Stocks",
        description: "Also called a road map for day traders, this list of the most active stocks is a useful index for those investors poised to buy and sell for profit.",
    },
    {
        long_form: "Moving Average",
        description: "This lagging indicator, also called a moving average or rolling average, gives investors a projected view of how a security is trending, without committing to particular prices.",
    },
    {
        long_form: "Moving Average Convergence Divergence (MACD)",
        description: "This oscillating analysis tool indicates the momentum of a particular security and provides a visual depiction of trends with buying and selling.",
    },
    {
        long_form: "Moving Average Convergence Divergence (MACD)",
        description: "Also known as 'munis,' these government-issued certificates of debt fund daily operating expenses or larger public works projects.",
    },
    {
        long_form: "Municipal Bonds ",
        description: "Also known as 'munis,' these government-issued certificates of debt fund daily operating expenses or larger public works projects.",
    },
    {
        long_form: "Mutual Funds",
        description: "A mutual fund is a company that pools money from multiple investors and uses a proprietary investing strategy to place that capital into select securities. Many mutual funds are stocks in a particular index or industry, while others offer more diversification. Mutual funds are a safer way for the average retail investor to place money in stocks while mitigating risk.",
    },
];


export const moneyPlannedPhilosophy = [{
    question: "Can we put our hand on our heart and say that we are 100% financially planned for the future?",

    answers: ["We are 100% planned for today; no doubt. We may be 100% planned for the next month or six months down the line.But we are NEVER really 100% financially planned for the future all by ourselves. No matter how well we think we may be doing, we might reach a 99.9% but never a 100%. ", "By definition and by design, financial planning starts with YOUR GOALS. It is not the investments, the deposits, the savings accounts, the stocks or the bonds that we care about. We care about OUR GOALS.", "Be it buying that dream home, that dream holiday home, that luxurious car, that plush retirement, that memorable vacation, or the best education for your child or for yourself.", "It is the vision that you have your life that you care about. Your dreams and aspirations for the life that you want to lead are your “end goals”. All else is but a means to achieve the same.", "Our mission at MoneyPlanned is to help you fulfill the vision that you have for your financial life. ", "We are here to help you live your dreams with effortless ease."]
}];

export const whyMoneyPlanned = [{

    question: "Why MoneyPlanned?",

    answers: ["Planning your finances may not be the most interesting thing you do. Many would prefer visiting a dentist over planning their finances on an excel sheet. It is indeed pain-staking, ineffective, non-optimal, and extremely boring.", "But what if optimizing your finances can feel like a breath of fresh air?", "MoneyPlanned is a super-app plan, execute, and track your financial goals; all in one place. ", "It brings your entire financial footprint under one app by allowing you to link any financial account to your goals thereby providing intelligent insights and recommendations to achieve your goals faster and smarter. ", "MoneyPlanned makes your financial life as easy as it can get. Our aim is to help you establish a beautiful and happy relationship with your money."]
}];


export const servicesOfMoneyPlanned = [{

    question: "What are the services offered by MoneyPlanned? ",

    answers: ["MoneyPlanned is an advanced technology platform that helps you plan like a pro without having to be one. ", "MoneyPlanned helps you plan your goals, intuitively and efficiently, in a way that suits you best. With parameters like market volatility, inflation, and tax implications factored in, you can only do better than your plans. ", "Link your bank accounts, investment accounts from other online platforms, trading accounts, etc to your goals with security and safety. You can execute your financial goal plans with the best investments and deposits along with tracking your goals real-time to see them through completion. ", "With the power of AI and ML, we also help create the best financial portfolio for you to unleash your full financial potential. ", "MoneyPlanned is perhaps the only App for finances you need on your phone.", "Whats more? Improve your Money IQ and EQ on the go with our nuggets of wisdom and fresh inspiration. ", "All this and much more at the price of lunch. "]
}];

export const freeSubscription = [{

    question: "Here's your 3-Month Free Subscription Coupon code! Get. Set. Go.",

    answers: ["Step 1 -> Only the above link below to install the app.", "Step 2 -> Sign Up on MoneyPlanned ", "Step 3 -> You will be directed to the screen, such as shown below on signing up.", "Enter the code 'UNLOCK21' here! ", "And click on 'Activate Reward'. ", "In case this screen doesn't appear, go to the 'Account' tab.", "Click on 'Invite and Earn'. ", "Go to tab 'My Rewards'. ", "Click on the button 'Get Sign Up Reward' ", "and enter the code 'UNLOCK21'"]
}];

export const bmb_about = [
    {
        highlighter: "What",
        question: "is a Bhartiya Mahila Bank Business Loan",
        description: "The Bhartiya Mahila Bank is one such bank that lends the business loan up to the maximum value of Rs. 20 Crores to the manufacturing enterprises. It offers a loan to the women entrepreneurs at the concessional rate of interest and grants collateral free loan up to Rs. 1 crore under CGTMSE cover. ",
        answer_in_steps:
            ["A fixed deposit refers to an investment scheme that banks and non-banking financing companies provide. FDs offer greater returns on the principal invested when compared to the returns generated from a regular savings account.", "Fixed deposits have a fixed tenure, hence the name. Depending on a consumer’s investment portfolio, the FD investment period can either be short-term or long-term. The interest rates on fixed deposits vary from one company or bank to another.", "Fixed deposit investors need to remember, however, that they cannot withdraw money before maturity without financial repercussions. In emergencies, early withdrawal is possible after the payment of penalties."],
    },
    {
        highlighter: "Who",
        question: "can avail the business loan from BMB?",
        description: "Any women business owner or women Entrepreneur is eligible to apply for a business loan from the Bharatiya Mahila Bank. The company should be registered as either one of these,",
        answer_in_steps: ["Sole Proprietor", "Partnership Firms", "Pvt. Ltd. / Ltd. Companies", "Co- op. Society"]
    },
    {
        highlighter: "Benefits",
        question: "of Bhartiya Mahila Bank Business Loan?",
        answer_in_steps: ["Concession of 0.25% in interest rate for women entrepreneurs", "Combo of working capital and term loan", "Flexible repayment tenure lasting up to 7 years", "No collateral required for the loan up to Rs. 1,00,00,000"],
    },
    {
        highlighter: "Loan",
        question: "details offered Bharatiya Mahila Bank?",
        answer_in_steps: ["Fixed deposits are perfect investment instruments for inexperienced investors. Further, risk-averse individuals can benefit greatly from such schemes. Since FDs offer assured returns, there is almost no risk of principal loss.", "However, investors should remember that the rate of return from such an investment is limited when compared to other high-risk options."]
    },
    {
        highlighter: "Types",
        question: "of Loans offered by Bhartiya Mahila Bank under CGTMSE Scheme",
        answer_in_steps: ["Interest Rate-10.15% p.a. -13.65% p.a.", "Loan Amount-For retail trader and service enterprises : Up to Rs. 5,00,00,000", "For manufacturing enterprises : Up to Rs. 20,00,00,000", "Collateral free loan: Up to Rs. 1,00,00,000", "Tenure -7 years", "Processing Fee - As per bank norms"]
    },
    {
        highlighter: "Eligibility Criteria",
        question: "to apply for the Bhartiya Mahila Bank Business Loan",
        description: "The following list of eligibility criterias that needs to be met to avail the loan",
        answer_in_steps: ["offered for Sole Proprietor, Partnership Firms, Public and Private Limited Companies & Co-operative Society", "Minimum 2 years of work experience; in case of self-employed, minimum of 2 years of business continuity", "Minimum age of 21 years and maximum age for 60 years (loan completion age)", "Minimum take home income of Rs. 25000/- per month", "Co-applicant applicable in case of HL and LAP, Spouse/Parents/Children"]
    },
    {
        highlighter: "Documents",
        question: "required for Salaried to avail the Loan",
        answer_in_steps: ["Identity Proof: PAN Card, Aadhar Card, Driving License, Voter ID Card Proprietor, partner of Director (if a company), etc.", "Address Proof: Aadhar Card, Valid Passport, Utility bill, Property tax bill, etc.", "Payslip of last 3 months", "2 Passport size photographs", "6 months Salary account bank statement", "Latest year Form 16."]
    },
    {
        highlighter: "Documents",
        question: "required for for Self-employed to avail the Loan",
        answer_in_steps: ["Identity Proof: PAN Card, Aadhar Card, Driving License, Voter ID Card of Proprietor, partner of Director (if a company), etc.", "Address Proof: Aadhar Card, Valid Passport, Utility bill, Property tax bill, etc.", "Latest 2 years ITR", "Financial Documents (P & L account, Balance Sheet, Schedules to Balance Sheet & Tax Audit report) & 6 months Company primary Current account statement", "Your current obligations, i.e. the other instalments (EMIs) that you are currently paying, the number of credit cards and the limits you have or use."]
    },
];

export const ay_about = [
    {
        highlighter: "What",
        question: "is Annapurna Yojana",
        answer_in_steps:
            ["The government encourages women who want to set up a food business through the Annapurna scheme. ", "Under the Annapurna Yojana, women Entrepreneurs are provided a loan of up to RS. 50,000 for the food catering business. "],
    },
    {
        highlighter: "Why",
        question: "should we opt for Annapurna Yojana?",
        description: "The amount granted as a loan under this scheme can be used for buying utensils, other kitchen tools, and equipment. The interest rate is depending upon the market rate and the concerned bank. A guarantor is required to secure the loan and it can be repaid in 3 years (36 monthly installments). Once the loan is approved, women don’t have to pay EMI for the first month.",
    },
    {
        highlighter: "Who",
        question: "can avail the Annapurna Scheme? ",
        answer_in_steps: ["The applicant should be a woman hailing from a village with a Gram Panchayat and Gram Sabha. ", "The applicant should not have any other source of income."]
    },
    {
        highlighter: "Features",
        question: "of Annapurna Scheme? ",
        answer_in_steps: ["A guarantor is required to take this loan. Assets of the business have to be pledged as the collateral.", "Once approved the loan must be repaid in 36 monthly installments.", "The rate of interest varies according to bank norms. ", "As an added benefit, the applicant can avail a 10 KG rice bag every month under this scheme. "]
    },
];

export const ssp_about = [
    {
        highlighter: "What",
        question: "is Stree Shakti Package",
        description: "Businesses operated by women Entrepreneurs in retail trade, manufacturing, or related services are eligible for a business loan under this scheme. As per definition, a Women Entrepreneur business is “A small scale industrial unit/industry related service or business enterprise managed by one or more women entrepreneurs in proprietary concerns or in which she/they individually or jointly have a share capital of not less than 51% as partners /shareholders/Directors of Private Limited Company/Members of Co-operative Society.",
    },
    {
        highlighter: "Loan",
        question: "details of the scheme?",
        description: "Loan can be in the form of term loan or working capital.The quantum of loan required would be based on the profile of the investor. ",
        answer_in_steps: ["Retail traders: Rs. 50000 to Rs.2 Lakhs", "Business enterprises: Rs. 50000 to Rs.2 Lakhs", "Professionals: Rs. 50000 to Rs.25 Lakhs", "SSI: Rs. 50000 to Rs.25 Lakhs"]
    },
    {
        highlighter: "Eligibility Criteria",
        question: "to apply for this scheme",
        description: "Eligibility for Stree Shakti Scheme The Stree Shakti Scheme requires the following to be eligible:",
        answer_in_steps: ["Occupation Women involved in retail, manufacturing, service activities are eligible for the loan. Self-employed women like architects, Chartered Accountants (CAs), doctors, etc., are also eligible for the loan. ", "Business Ownership The loan is provided for businesses that are solely held by women or at least have more than 50% stake. ", " EDP It is required that the applicants are a part of or at least are pursuing the Entrepreneurship Development Programmes (EDP) organised by state agencies to avail loan under this scheme."]
    },
    {
        highlighter: "Benefits",
        question: "and features of Stree Shakti Scheme",
        answer_in_steps: ["As the package is aimed at Women Entrepreneurs, concessions or relaxations in the margin are in-built into the scheme along with the low floating rate of interest, linked to the base rate of the bank.", "It does not require any collateral for a loan of upto Rs.10 lakhs, extended to MSME enterprises owned by Women Entrepreneurs. Further, loans to women entrepreneurs of upto Rs.100 lakhs that can be covered under the CGTMSE scheme also requires no collateral. However, collateral is a must for any loan above Rs.1 crore or based on the borrower profile.", "This loan can be availed for boosting working capital or to purchase equipment for day-to-day trade. Following are the popular fields that attract loan applications under the scheme.", "Clothing sector Women dealing in the manufacturing of the readymade clothing sector usually apply for loans under the Stree Shakti Scheme.  Dairy sector Women dealing with dairy products like milk, eggs, etc apply for loans under the Stree Shakti Loan scheme. ", "Farm products Women dealing with farm products like seeds, etc apply for a loan under this scheme.", "Home products Women dealing with unbranded soaps and detergents apply for a loan under the scheme. ", "Cottage Industries Women involved with cottage industries like the manufacturing of spices and incense sticks can apply for a loan under the scheme"]
    },
    {
        highlighter: "Interest Rates",
        question: "details of Stree Shakti Scheme",
        descripton: "Interest Rates The interest rates vary according to the amount one borrows. The margin will be lowered by 5% as applicable to separate categories.."
    },
    {
        highlighter: "Documents",
        question: "required to apply for Stree Shakti Scheme",
        answer_in_steps: ["Identity Proof : Voter Id Card Passport Driving License PAN Card", " Address Proof : Telephone bill Property tax receipt Electricity bill Voter’s ID card Company registration certificate Company partnership registration certificate (in case of partnership firms)", " Income Proof Balance Sheets (last 3 years) Income statements (last 3 years) GST Returns (last 3 years) ", " Business Plan Business plan with projected financials for at least 2years in case of working capital", "Profile of business enterprise -Promoter’s name, Directors’ names, Partners’ name, Business type, Business facilities and premises Shareholding ratios Lease agreements’ copy Ownership title deeds."]

    },
];

export const omvs_about = [
    {
        highlighter: "What",
        question: "is a Orient Mahila Vikas Scheme",
        description: "Orient Mahila Vikas Scheme was launched by Oriental Bank of Commerce in India. The main objective of this scheme is to provide minimum credit needs of female/women entrepreneurs. ",
    },
    {
        highlighter: "Benefits",
        question: "and features of the scheme?",
        description: "The Oriental Bank Of Commerce Has Two Schemes Designed For Self-Employed Women And Who Want To Set Up A Beauty Parlor / Salon.",
        answer_in_steps: ["Scheme For Beauty Parlors / Boutiques:- The Scheme Aims To Promote Entrepreneurial Spirit Among Women, And Provides Loans Up To Rs 10 Lakh To Set Up Salons, Boutiques, Sewing Centers Or Beauty Parlors. The Loan Amount Can Be Used To Meet Any Expenses Associated With Setting Up Such A Center And Daily Expenses.", "Scheme For Self-Employed Women:- This Loan Can Be Used To Purchase Immovable Properties To Expand Or Start A Business. The Maximum Loan Amount Is Limited To Rs 5 Lakh, With A Repayment Period Of Between 5 And 7 Years."],
    },
    {
        highlighter: "More",
        question: "details of the scheme",
        answer_in_steps: ["Amount: Rs 10 lakh to 25 lakh.", "Collateral security: not required.", "Repayment period: 7 years.", "Interest: decided by the bank which depends on market price. Concession on the interest rate of up to 2% is given."]
    },
    {
        highlighter: "Eligibility Criteria",
        question: "to apply for the Orient Mahila Vikas Scheme",
        description: "Beneficiaries: women who hold 51% share capital individually or jointly in a proprietary concern.",
    },
];

export const dss_about = [
    {
        highlighter: "What",
        question: "is a Dena Shakti Scheme",
        description: "Dena Bank is a public sector banking company started with the vision of promoting economic opportunities for women and to set a path towards the economic empowerment of women. The bank creates a platform for woman entrepreneurs to get business loans. These business loans are intended for most business purposes to meet the requirement of working capital or for business expansion.",
    },
    {
        highlighter: "Who",
        question: "can avail the scheme?",
        description: "People who are working in ,",
        answer_in_steps:
            ["Agriculture and allied activities", "Small Enterprises (Direct and Indirect Finance", "Micro and small (manufacturing) enterprises", "Micro and small (service) enterprises which include small road and water transport operators, small business professional and self employees and all other service enterprises", "Retail Trade", "Micro Credit", "Education", "Housing"]
    },
    {
        highlighter: "Features",
        question: "& Benefits of the scheme?",
        answer_in_steps: ["Interest Rate - 0.25% below base rates for women entrepreneurs who are majority stakeholders in the company", "Loan Amount for Agriculture - Up to Rs. 20 Lakhs", "Loan Amount for manufacturing, retail trader, or small enterprises (Direct and Indirect Finance) -Up to Rs. 20 Lakhs", "Loan Amount for Retail Trader and micro-credit -Up to Rs. 50,000 under micro credit schemes.", "Loan Tenure - Up to 7 years", "Processing Fee	0.50% of the loan amount"],
    },
    {
        highlighter: "More",
        question: "info of the scheme",
        description: "Microcredit and retail stores are also eligible entities along with enterprises operating in the agriculture and allied sector.",
    },
    {
        highlighter: "Documents",
        question: "required to avail this loan",
        description: "The required documents are to be submitted while applying for Dena Shakti Scheme.",
        answer_in_steps: ["Identity Proof: PAN Card, Aadhaar Card, Driving License, Voter ID Card, etc.", "Address Proof: Aadhar Card, Valid Passport, Utility bill, Property tax bill, etc.", "Bank account details.", "One passport size photo of the applicant."]
    },
];

export const us_about = [
    {
        highlighter: "What",
        question: "is a Udyogini scheme?",
        description: "Udyogini is a scheme to provide subsidised loans to aspiring women entrepreneurs from rural and underdeveloped areas. Various financial institutions provide loans under the scheme.",
    },
    {
        highlighter: "More",
        question: "details of the scheme?",
        description: "Submit the following documents to complete your Udyogini application.",
        answer_in_steps:
            ["Aadhaar Card, Ration Card and BPL Card", "Essential certificates like a certificate of income, birth and caste", "A photocopy of bank passbook including account holder’s name, name of the bank and concerned branch, account number, IFSC and MICR"]
    },
    {
        highlighter: "Features",
        question: "& Benefits of the scheme?",
        description: "Here are some essential Udyogini scheme details that you need to know.",
        answer_in_steps: ["Interest-free loans to women.", "The scheme extends interest-free loans to women, from all sections of the society, seeking to fund their small business.", " Women belonging to special categories such as widowed, disabled or destitute, enjoy specific privileges.", "High-value loan amount - Applicants can avail a loan of up to Rs.3 lakh under this scheme, provided they meet the basic eligibility criteria.", "Loans available for 88 small-scale industries", "The Udyogini loan can be availed to start businesses categorised under 88 small-scale industries. Some of them are fish business, bakery, grocery, sewing, library, incense stick manufacturing, trade related to poultry and dairy, etc. Interest-free loans under the scheme are also available for women starting a business in the agriculture sector.", "Training for skill development - This scheme also aims to impart functional skills to women regarding business planning, pricing, costing, feasibility, etc. alongside financial support.", "Up to 30% loan subsidy - The Government proposes up to 30% subsidy on loans extended under this scheme to make repayment affordable for women.", "Transparent evaluation of the beneficiary - The evaluation process of Udyogini Yojana application form ensures a transparent assessment for beneficiary selection."]

    },
    {
        highlighter: "Eligibility",
        question: "criteria to avail this scheme",
        description: "Aspiring women entrepreneurs can fill the Udyogini loan application form to apply for funding, once they have met the below eligibility criteria ,",
        answer_in_steps: ["Applicant must be a woman", "She should be between 18 and 55 years of age", "Family income of the applicant should be less than Rs.1.5 lakh", "The upper limit for income is not applicable for women falling under special categories like disabled, widowed or destitute."]
    },
    {
        highlighter: "Documents",
        question: "required to avail this scheme",
        description: "Here is the list",
        answer_in_steps: ["2 passport size photo", "Aadhaar Card", "Birth certificate (10th mark sheet, certified letter from local tehsildar or village head /local Zilla Parishad", "Letter written on the letter pad of MLA/local MP", "Photocopy of BPL Card (Below Poverty Line)", "Caste certificate (if in ST-ST category)", "Income certificate"]
    },
];

export const cks_about = [
    {
        highlighter: "What",
        question: "is a Cent Kalyani Scheme?",
        description: "Cent Kalyani Scheme was launched to encourage Women Entrepreneurs to start new project or expand / modernise the existing unit.It helps meet capital expenditure ( Plant & Machinery /Equipment etc). & To meet day to day expenditure",
    },
    {
        highlighter: "Purpose",
        question: "of Cent Kalyani Scheme Listed below ",
        description: "Submit the following documents to complete your Udyogini application.",
        answer_in_steps: ["To Cater - One of the main objectives of Cent Kalyani Scheme is to cater women entrepreneurs and help them through various government priorities such as offer them jobs, loans, subsidies, etc. ", "To Identify -  Another objective is to identify women with needs and encourage them by giving financial assistance to achieve their goals. ", "To Guide -  One of the major motto objectives behind the scheme is to guide women with business expansion and other business needs. ", "To Co-ordinate - One of the main objectives is to coordinate with government and non-government organisations to help more women to gain benefits from the bank’s scheme.. Read more at: https://www.fincash.com/l/loan/cent-kalyani-scheme"]
    },
    {
        highlighter: "Interest Rates",
        question: "offered under this scheme?",
        answer_in_steps: ["Loan of Rs. 10, 00, 000 - 9.70% + 0.25% = 9.95%", "Loan of Rs. 10, 00, 000 - 100, 00, 000	9.70% + 0.50% = 10.20"]
    },
    {
        highlighter: "Features",
        question: "& Benefits of the scheme?",
        description: "Here are some features that you need to know.",
        answer_in_steps: ["Term Loan Fund Based Working capital Facility - Overdraft/ Cash Credit Non Fund Based Working Capital - Letter of Credit /Letter Of Guarantee etc", "Quantum of finance - Rs. 100 Lacs ", "Margin - 20%", "Concession in Rate of interest - Loan up to Rs. 10 Lacs - MCLR + 0.25% Loan above Rs. 10 Lacs and up to Rs.100 Lacs - MCLR + 0.50% Additional Interest concession of 0.25% if account is rated by external agency.", "No collateral security ", "No third party guarantee necessary "]
    },
    {
        highlighter: "How",
        question: "to apply this loan?",
        description: "You could download the forms from the website to apply for the application, and along with supporting documents can apply at the nearest Central Bank of India branch.",
    },
    {
        highlighter: "What",
        question: "are the terms for Cent Kalyani?",
        description: "The Cent Kalyani will charge on stock, any plant and machinery, any receivables. Unencumbered assets and equipment as well. There is no collateral security as advance. No Third party Guarantee is required. There are processing fees. The loan tenure will be a maximum of 7 years which will include a moratorium period of 6 months to 1 year.",
    },
    {
        highlighter: "Eligibility",
        question: "criteria to avail this scheme",
        answer_in_steps: ["New as well as Existing Women entrepreneurs for her Micro / Small enterprise ( as defined under MSME Act- 2006) .i.e engaged in manufacturing and service activity for eg. Handloom weaving Handicraft, Food-Processing, Garment making etc. etc.", "Professionals & Self employed women - Doctors, Chartered Accountants, and Engineers or trained in Art or Craft etc. ", "Health /Beauty clinics/Dieticians/Fashion Designing Beauty Parlors .", "Small Business-Small lunch/canteen, mobile restaurant, circulating library/ tailoring /Day Creches for children ,Tailoring, Typing / STD/ Xerox booth etc.Transport Operators- Three wheeler /Four wheeler . (Retail Trade/ Education and training Institute/ Self Help Group not eligible)"]
    },
    {
        highlighter: "Documents",
        question: "for Cent Kalyani",
        description: "Here is the list",
        answer_in_steps: ["The KYC documents to be submitted, and if any name change that also should be recorded. Application form duly filled", "Balance Sheets, profit and loss accounts and other financial documents.", "The application will also need to provide letters of interest, understanding, continuity and hypothecation.", "Name of the applicants as well need to be disclosed."]
    },
];

export const muns_about = [
    {
        highlighter: "What",
        question: "is a Mahila Udyam Nidhi?",
        description: "Mahila Udyam Nidhi (MUN) Scheme is a scheme offered under Small industrial Development Bank of India (SIDBI) to encourage and empower women entrepreneurs and promote women Entrepreneurship by providing financial assistance at concessional interest rates. The funding provided by Mahila Udyam Nidhi Scheme can be used by MSMEs to undertake service, manufacturing and production related activities",
    },
    {
        highlighter: "More",
        question: "details about the scheme",
        answer_in_steps: ["Project cost must not exceed Rs. 10 lakh", "Loan limit of up to 25% of project cost, subject to maximum of Rs. 2.5 lakh per project is offered to deserving women entrepreneurs", "Repayment tenure of loan is up to 10 years, including moratorium period of 5 years", "Interest rate fixed by SIDBI and offered by banks that may vary from time to time and shall be communicated by SIDBI to women entrepreneurs", "Service charge of 1% per annum is charges by the respective bank, as per the sanctioned loan", "Service charge waiver depends on the lending office"]
    },
    {
        highlighter: "Interest Rate",
        question: "offered during Mahila Udyam Nidhi scheme?",
        descripton: "The Mahila Udyam Nidhi scheme interest rate is ",
        answer_in_steps: ["NSFDC to CA(s) - 4% p.a.", "Interest Spread to CA(s) - Up to 8%", "CA(s) to Beneficiaries - Up to 12% p.a."]
    },
    {
        highlighter: "Features",
        question: "& Benefits of the scheme?",
        description: "This scheme is advantageous to some of these small industries listed below ,",
        answer_in_steps: ["Auto-repairing and servicing centre", "Beauty parlour", "Cable TV Network", "Canteen and Restaurant", "Computerized Desk top publishing", "Cyber Café", "Day care centre", "ISD / STD Booth", "Laundry & Dry Cleaning", "Mobile Repairing", "Photocopying (Xerox) Centre", "Purchase of auto rickshaws, two-wheelers, cars", "TV Repairing", "Road transport operator", "Salon", "Servicing of Agricultural and Farm equipment", "Tailoring", "Training Institute", "Typing Centre", "Washing machine and other electronic and electrical gadgets, etc."]
    },
    {
        highlighter: "Eligibility",
        question: "criteria to avail this scheme",
        answer_in_steps: ["Women entrepreneurs who have initiated existing and new MSMEs, Tiny Units or SSIs", "Financial holding of women entrepreneurs should not be less than 51%, engaged in manufacturing and production purposes", "Existing or new MSMEs engaged in services, trading and manufacturing sectors", "MSMEs or tiny enterprises with minimum investment of at least Rs. 5 lakh", "MSMEs engaged in various activities, such as business expansion, improvement, diversification, and technology upgradation"]
    },
];

export const bsy_about = [
    {
        highlighter: "What",
        question: "is a Balika Samridhi Yojana",
        description: "Balika Samridhi Yojana was introduced by the Indian Government in 1997 under the policies for Women and Child Development to facilitate the girl child. It is widely known as a key initiative to support the birth and education of the girl child.",
    },
    {
        highlighter: "More",
        question: "details about this scheme",
        description: "Initially, under Balika Samridhi Yojana (BSY), there were incentives assimilated- a gift of Rs.500/- presented to the mother on delivery of the girl child. Also, annual scholarships were provided for the child’s education by the Government. However, the exact guidelines were not received for the scholarships.",
    },
    {
        highlighter: "Focus",
        question: "of this scheme",
        answer_in_steps: ["Improvising the enrolment as well as retention of the girl child in the schools", "Changing the negative attitude of the family and community towards the birth of a girl child", "Raising the girls till their legal age of marriage", "Assisting the girls in undertaking activities that can generate income"]
    },
    {
        highlighter: "Rewards",
        question: "offered by Balika Samridhi Yojana",
        descripton: "The amount of financial assistance given to the mother of the girl child at the time of her birth is a one-time grant of INR 500. Besides this, when the girl child starts attending her school, she becomes entitled to receive an annual scholarship as mentioned hereunder –",
        answer_in_steps: ["The scholarship amount for class 1 to 3 is INR 300 per annum.", "The scholarship amount for class 4 is INR 500 per annum.", "The scholarship amount for class 5 is INR 600 per annum.", "The scholarship amount for class 6 to 7 is INR 700 per annum.", "The scholarship amount for class 8 is INR 800 per annum.", "The scholarship amount for class 9 to 10 is INR 1000 per annum."]
    },
    {
        highlighter: "Features",
        question: "& Benefits of the scheme?",
        description: "This scheme works for the welfare of the girls with the following objectives:",
        answer_in_steps: ["Bring positive change in the attitude of the family, society or community towards the mother and the girl child", "Protecting and Improving the enrollment as well as retention of the girls in school", "Rightly raising the girl child till she reaches the legal age for marriage", "Helping the girls and motivating them to take up income generating activities for her own welfare"]
    },
    {
        highlighter: "Terms",
        question: "and Conditions associated with this scheme",
        description: "The candidates who avail benefits under this scheme have to follow all of them –",
        answer_in_steps: ["The amount of financial assistance is directly deposited into the account of the beneficiary. (Note: The beneficiary is the girl child in the name of whom an interest-bearing account has to be opened).", "It is preferable that the amount earns the maximum interest possible. Thus, it is advisable to the candidates to look up for schemes like Public Provident Fund or National Saving Certificate.", "The candidates can utilise a part of the grant or the scholarship amount towards paying the premium of the insurance policy under the Bhagyashri Balika Kalyan Bima Yojana in the girl’s name.", "The candidates can also utilise the scholarship amount for the purchasing the textbook or the uniform for the girl.", "There is also a provision of withdrawing the standing amount in the name of the child one her completion of 18 years subject to the production of a certificate issued by the Municipality/ Gram Panchayat stating that the girl is unmarried.", "In case the girl gets married before 18 years of age, she shall have to forego the scholarship amount and the interest accrued on it and shall be entitled to receive the post-birth grant and the interest accrued on it only.", "In the event of girl’s death before completing 18 years of age, the total accumulated amount available in her account can be withdrawn"]
    },
    {
        highlighter: "Eligibility",
        question: "criteria to avail this scheme",
        description: "As the name suggests, Balika Samridhi Yojana is applicable only for girl children. Besides this, there are certain key eligibility conditions that the candidate should fulfil to avail benefits under this scheme. These conditions include –",
        answer_in_steps: ["The post-birth grant is given to the mothers of the new-born girl child.", "The girl child should belong to a family below the poverty line.", "The scheme is implemented in both urban and rural areas.", "The girl should be born on or after 15th August 1997.", "All the benefits of this scheme are provided to only two girl children from every family irrespective of the number of children in the family.", "BSY covers both the rural and urban areas in all the districts of India. ", "For the residents of rural areas, families identified to be below the poverty line according to the criteria specified under Swarnajayanti Gram Swarozgar Yojana, will be taken as the target group.", "For the residents of urban areas, the families living in urban slums irrespective of their recognition, will be covered under Balika Samridhi Yojana. Also, families working as rag-pickers, vegetables & fruit sellers, payment vendors etc. are covered under the scheme. Moreover, according to the instructions of the Government of India, there are surveys conducted to check for the families BPL and lists are prepared under the Targeted Public Distribution System (TPDS) which can be followed to keep a record of the target groups"]
    },
];

export const mry_about = [
    {
        highlighter: "What",
        question: "is a Mukhyamantri Rajshree Yojana",
        description: "The Government of Rajasthan has initiated the scheme entitled ”Mukhyamantri Rajshree Yojana” in which the Government grants a subsidy of Rs.50,000 to the families on birth of the girl child.  This scheme aims to improve the economic empowerment of girls in promoting education and provide them with a better future. ",
        answer_in_steps: ["The primary objective of the Mukhyamantri Rajshree Yojana is to eradicate child mortality and child marriage in society.", " This scheme ensures to improve girl child ratio and also promotes girls education in the state.", " This scheme is implemented in both rural and urban areas."]
    },
    {
        highlighter: "More",
        question: "details about the scheme",
        descripton: "This fund will be provided to the beneficiary girls at various stages which are as follows –",
        answer_in_steps: ["1st Instalment - On the birth of a girl child, Amount offered	is Rs. 2500", "2nd Instalment -For the vaccination of one year, Amount offered is Rs. 2500", "3rd Instalment	If whole Immunisation is completed within two years, Amount offered is Rs. 2000", "4th Instalment - For admission in First Class, Amount offered is Rs. 4000", "5th Instalment	For admission in Class 6th Std, Amount offered is Rs. 5000", "6th Instalment - For admission in Class 10th Std, Amount offered is Rs. 11,000", "7th Instalment -After the completion of 12th Std, Amount offered is Rs. 25,000"]
    },
    {
        highlighter: "Features",
        question: "& Benefits of the scheme?",
        description: "Mukhyamantri Rajshree Yojana various benefits associated with its use, which is listed below:",
        answer_in_steps: ["Under this scheme, the Rajasthan government will provide financial assistance to the total amount of Rs. 50,000 from birth to the end of graduate education.", "The beneficiaries will get the subsidy in their account at the fixed time frame by the government.", "The government grants benefits in the form of financial assistance to the parents on the birth of the first girl child.", "The beneficiaries under this scheme will get the amount in their account at the fixed time frame by the government.", "The State Government has allotted funds of Rs. 2221 crore for the implementation of this scheme for benefiting the girl child of the state.", "This scheme aims to create sustainable growth of all these people that further helps with improving their standard of living in society."]
    },
    {
        highlighter: "Eligibility",
        question: "criteria to avail this scheme",
        description: "The below following are the eligibility criteria required to apply for the Mukhyamantri Rajshree Yojana:",
        answer_in_steps: ["All the beneficiaries must be a native of Rajasthan", "Only to those girls who were born in a private institution registered with the Government Hospital and Janani Suraksha Yojana (JSY).", "The parents will receive both the instalments whereas if the third child is a girl, then the parents would not receive the benefit of further instalments in the scheme.", "To get the benefit of the Rajasthan Mukhyamantri Rajshree Yojana, the beneficiaries should have the Bhamashah Card which will facilitate the applicants to get the benefit of this scheme."]
    }
];

export const ly_about = [
    {
        highlighter: "What",
        question: "is a Ladli Yojna?",
        description: "Ladli Yojna is a scheme launched by the Government of NCT of Delhi in the year 2008. With Ladli Yojna, the Delhi Government aims to empower a girl child by enhancing their social status and self-reliance through proper education, economic security and protection against discrimination and deprivation.",
        answer_in_steps: ["The girls who are born in the last 1 year can avail the benefits of Delhi Ladli Scheme.", "As for those girl children who are born before this time period, they can also be the recipients of financial assistance under Delhi Ladli Scheme if they are enrolled in class 1, 6, 9 or class 12 of a government recognised school."]
    },
    {
        highlighter: "Benefits",
        question: "of Ladli Yojna?",
        description: "Under Ladli Yojna, a girl child born in last 1 year receives INR 11,000 (if born in a hospital/nursing home of Delhi) or INR 10,000 (if born at home). In addition to the financial assistance received within the birth year, the girl children receive other financial benefits as well with the commencement of their education. The payments that are made in the name of the child are kept as fixed deposit in the child’s name. It can be redeemed along with the accrued interest when the girl reaches the age of maturity which is 18 years of age and has passed Class 10. Given below is the complete details about the benefits granted under the Delhi Ladli Scheme.",
        answer_in_steps: ["A girl child born in the last 1 year - INR 11,000 will be provided in case of institutional delivery & INR 10,000 in case of child’s birth at home", "On admission of the girl child in Class 1 - INR 5000 will be provided", "On admission of the child in Class 6 - INR 5000 will be provided", "On admission of the child in Class 9 - INR 5000 will be provided", "On passing of Class 10 - INR 5000 will be provided", "On admission of the child in Class 12 - INR 5000 will be provided"]
    },
    {
        highlighter: "Eligibility",
        question: "criteria to avail this scheme",
        answer_in_steps: ["The child must be born in Delhi.", "The parents of the child must be residing in Delhi for at least 3 years prior to the date of application of Delhi Ladli Scheme.", "The annual family income of the parents of the girl child must not exceed INR 1 Lakh.", "The girl student must be studying in a Government/MCD/NDMC or Government recognised school.", "The candidate must have been admitted in Class 1/6/9/12 or have passed Class 10.", "Also, the Ladli Yojna of Delhi is limited to 2 surviving girls per family."]
    },
    {
        highlighter: "Documents",
        question: "required to avail this scheme",
        description: "While applying for Delhi Ladli Scheme, some important documents need to be submitted in support of the details provided in the form as the lack of these documents may lead to rejection. Find below the list of all the key documents that must be submitted with the completed application form of Ladli Yojna.",
        answer_in_steps: ["Proof of residence to show that the parents of the candidate have been living in Delhi for at least 3 year (Copy of ration card/ Voter ID card/ any other related document)", "Birth certificate of the girl child issued by the Registrar (Births and Deaths) of National Capital Territory of Delhi to show that the candidate has been born in Delhi", "A joint photograph of the girl child and her parents", "A self-declaration form by the parents regarding their annual income", "Admission certificate of the child issued from Government/MCD/NDMC or Government recognised school in case the child is a school going candidate"]
    },];

export const cbse_about = [
    {
        highlighter: "What",
        question: "is a CBSE Single Girl Child Scholarship",
        description: "CBSE Scholarship for single girl children is one of the most popular schemes for post matriculation being provided by CBSE. The objective of the scheme is to provide financial scholarship for the education of girls who are the only child of their parents.",
    },
    {
        highlighter: "Features",
        question: "of this scheme?",
        description: "The scholarship is provided for every girl Rs. 6000 per annum for an entire period of 2 years.",
    },
    {
        highlighter: "Eligibility Criteria",
        question: "to apply this scheme",
        description: "Any single girl child who is the only child of their parents is eligible to apply. The age of the girl shouldn’t exceed 15 years. This is available only to Indian nationals. The student should have passed Class X examination with 60% marks and should be pursuing Class XI or XII in CBSE affiliated schools",
    },

];

export const sip_about = [
    {
        highlighter: "What",
        question: "is Systematic Investment Plan(SIP)",
        description: "Systematic Investment Plan is a type of investment method where you can invest a fixed amount at regular intervals in an investment scheme. When an investor chooses to pay his/her installment amount through SIP, a fixed amount is debited from their account, depending upon the frequency chosen by the investor and is transferred to the scheme which they’ve invested in. ",
        answer_in_steps: ["Systematic Investments are introduced with the intent to inculcate financial discipline in investors, basically SIPs are merely a method of investing in mutual funds. By enabling investors to invest small amounts regularly, systematic investments pave the way to get started with investments in mutual funds.", "Once you apply for one or more SIP plans, the amount is automatically debited from your bank account and invested in the mutual funds you have purchased at the predetermined time interval. At the end of the day, you will be allocated the units of mutual funds depending on the NAV of a mutual fund."]
    },
    {
        highlighter: "How",
        question: "much can I invest in a SIP?",
        description: "There is no limit to the amount you can invest in a SIP. The minimum amount that you can invest is Rs. 500 per month.",
    },
    {
        highlighter: "Are",
        question: "SIPs safe? Should I be investing in SIP ?",
        description: "SIP is just a mode of investment. The safety/risky component is related to the mutual fund you choose."
    },
    {
        highlighter: "Minimum",
        question: "tenure you can go for SIP?",
        description: "The minimum tenure you can go for is 3 years.",
    },
    {
        highlighter: "Types",
        question: "of SIP's available",
        answer_in_steps: ["Step-up or top-up SIP: A step-up or top-up SIP enables you to increase the SIP amount automatically at specified intervals at a particular amount or percentage. These plans provide an advantage to invest in mutual fund schemes that are performing well in the market. Moreover, by increasing the investment amount at regular intervals, you can accumulate a huge corpus to achieve your financial goals.", "Perpetual SIP: A perpetual SIP enables you to keep investing as long as you wish to without any end date.In Perpetual SIP, the investors can invest periodically in a mutual fund scheme of their own choice every month for a pre-determined tenure. While signing up the SIP mandate, the investors have a choice not to enter the end date in the SIP mandate.", "Trigger SIP: A trigger SIP lets you start investing during a specific index level, NAV, date, or event.This option is beneficial for those investors who are aware of the market volatility and who have a proper understanding of the financial market. To start this SIP, you can set an index level, event, NAV or a specific date to start this SIP.", "Flexible SIP: A flexible SIP lets you change the amount you are investing according to your preference or cash flow .So, in case, if you face any type of cash crunch due to any reason then you can skip paying a few installments of SIP till your financial situation normalizes."]
    },
    {
        highlighter: "Tax",
        question: "Implication of SIPs",
        description: "Only investments in ELSS mutual funds through SIP have tax exemption of up to Rs 1.5 lakh a year under Section 80C."
    },
    {
        highlighter: "Is Extension",
        question: "of SIP allowed",
        description: "At the end of your SIP term, you will get an option for renewal of your investment. You can fill out that form and then choose the desired duration of the investment."
    },
    {
        highlighter: "Can",
        question: "I miss a SIP payment?",
        description: "Yes, you can skip the bill, and your account won't be disabled. There are options for avoiding the payments in different mutual funds."
    },
    {
        highlighter: "Does",
        question: "SIP have a lock in period?",
        description: "If you are investing in an open-ended mutual fund, there will be no lock-in period for your SIP as well. It completely depends on the mutual fund you invest in. Some mutual funds, do have a lock-in period. ELSS mutual funds have a lock-in period of 3 years. Many other mutual funds have lock-in periods too. Mutual funds that have lock-in periods are called close-ended mutual funds.",
    },
];

export const sip_featfact = [{
    features:
        [
            {
                f_highlighter: 'Financial discipline',
                f_details: 'A well known reason why SIP is so widely used is the fact that this brings about some discipline in mutual funds investments. Since the SIP’s are highly flexible, investments may be halted at any point of time while investors may also opt to decrease or increase the amount being invested.',
            },

            {
                f_highlighter: 'Pocket-friendly',
                f_details: 'With SIP plans, you can start investing in mutual funds with an amount as little as 500 a month. Here are the best mutual funds to start SIP investment with 500. Even if your savings are not very large, you can still take advantage of the growth being experienced by India by investing in mutual funds!',
            },

            {
                f_highlighter: 'Compounding effect',
                f_details: 'When you invest using an SIP plan, your monthly SIP investment gives returns. Those returns are added to your actual investment amount and invested again! So over time, your continuous monthly SIP and the returns earned by them is subjected to a compounding effect that ensures exponential growth',
            },

            {
                f_highlighter: 'Options to start multiple SIPs',
                f_details: 'If you start earning more or if you are able to save more, you can always start a new SIP plan in the same mutual fund or a different mutual fund. That way, the extra money will also be invested for the future',
            },

            {
                f_highlighter: 'Stop the SIP Anytime',
                f_details: 'There is no fine if you decide to stop an SIP plan. If you want to stop it, you simply have to opt out of the SIP plan. This has a very big advantage over recurring deposits (RD) which usually put a fine on you if you want to stop it. After stopping your regular SIP investment, you can choose to get back the amount or let it continue to be invested in the mutual fund.',
            },
        ],
},
{
    factors:
        [
            {
                ff_highlighter: 'Determine the amount',
                ff_details: 'Calculate the right amount for SIP to meet your financial objectives through the investment. You can calculate the SIP amount using online SIP calculators on the basis of your financial goals, time horizon and expected rate of return. Also ensure that you should be able to easily pay the amount for the entirety of the SIP tenor.',
            },
            {
                ff_highlighter: 'Select scheme options',
                ff_details: 'One of the important factors to consider is the selection of the scheme option for regular or direct plan. If you want to get increase wealth and reinvest the surplus, go for the growth option. But if you want to get profit payouts at regular intervals, choose the dividend option.',
            },
            {
                ff_highlighter: 'Select the asset class',
                ff_details: 'Mutual funds are designed for different types of investors. Select a suitable asset class based on your risk appetite. Equity funds are suitable for aggressive investors as they carry high risk along with higher returns. Debt and balanced funds carry less risk when compared to equity and are thus more suitable for conservative investors.',
            },
            {
                ff_highlighter: 'Analyze the performance',
                ff_details: 'Understand the mutual fund in which you want to invest your money. Analyze the performance of funds over the last three to five years. A thorough comparison with other funds will help you understand the stability of the selected mutual fund. The key is to select funds which can survive market volatility and perform even when the market is down.',
            },
        ],
}
];

export const mf_featfact = [{
    features:
        [
            {
                f_highlighter: 'A systematic approach to investments',
                f_details: 'Through plans that are regular and timely, mutual funds help people develop a disciplined approach to financial investments. Systematic Investment Plan (SIP), Systematic Transfer Plan (STP), and Systematic Withdrawal Plan (SWP)',
            },
            {
                f_highlighter: 'Liquidity',
                f_details: 'You can easily move your money in and out of mutual funds',
            },
            {
                f_highlighter: 'Cost-effective',
                f_details: 'Cost of investment research, infrastructure, and operational charges are not borne by the investor due to end-to-end management of the fund being taken up by the AMC.',
            },
            {
                f_highlighter: 'Tax benefits',
                f_details: 'Various tax benefits are available for investments in Mutual Funds.',
            },
            {
                f_highlighter: 'Diversification',
                f_details: 'The biggest advantage of Mutual Funds is the diversification. It spreads your money across various sectors and hence reduces risk.',
            },
            {
                f_highlighter: 'Professional management',
                f_details: 'They are managed professionally by Asset Management Companies that reduce the burden on your part as an investor leaving the main task of fund allocation to the experts who know it best',
            },
            {
                f_highlighter: 'Convenient Options',
                f_details: 'Investors can structure their liquidity preference and tax position according to their convenience.',
            },
            {
                f_highlighter: 'Regulatory comfort',
                f_details: 'Mutual Funds are regulated by SEBI which has mandated strict checks and balances in the structure of the Mutual funds. Mutual funds hence offer that kind of protection to the investor.',
            },
        ],
},
{
    factors:
        [
            {
                ff_highlighter: 'Lump-sum or SIP',
                ff_details: 'Lump sum is a one-time investment. If one has a substantial disposable amount in hand, then they may opt for making a lump sum investment. However, only an individual with a higher risk tolerance should do that. Regular investors are, however, advised to invest through a Systematic Investment Plan, or an SIP. For example, instead of investing Rs 60,000 in one go, one can invest Rs 5,000 every month through an SIP. SIPs diversify over time and meet the risk of timing cost. Benefit of rupee cost averaging kicks in over a period of time, especially when one gets an opportunity to buy at lows.',
            },
            {
                ff_highlighter: 'Selecting a good mutual fund',
                ff_details: 'When making investments, one should consider risk-adjusted returns rather than focusing on maximising returns, since the latter could result in taking undue risks. The factors to be mindful of are the pedigree of the fund house, their track record in managing investments across market cycles, to name a few. Being invested in a good scheme over the long-term can surely aid the investor in having a good investment experience',
            },
            {
                ff_highlighter: 'Check the Investment Allocation',
                ff_details: 'One should check about where is the money actually being invested in? Is only debt or debt + equity or only equity? And in that too in which bifurcation. The choice of asset class is very important and it should have features aligned with the investment objectives.',
            },
            {
                ff_highlighter: 'Opportunity cost',
                ff_details: 'Opportunity cost refers to a benefit that you as an investor could have achieved, if you hadn’t to take this course of action. Basically, it means checking out returns of other avenues of investments which you can invest in instead of investing in a mutual fund.',
            },
            {
                ff_highlighter: 'Volatility Measure',
                ff_details: 'One should check returns given by a fund during different time periods and compare them with a benchmark, usually an index and other funds in the same category. “This will help you to know how volatile is the fund as compared to other funds and if it is a correct fund for you, considering your investments attributes,” informs Angirish.',
            },
            {
                ff_highlighter: 'Inquire regarding the taxation effect',
                ff_details: 'It is important that you inquire of the tax liability arising from a particular scheme, before you plan to invest your money in it.',
            },
        ],
}
];

export const htoba_about = [
    {
        highlighter: "What",
        question: "is Bank Account?",
        description: "A bank account is a financial account maintained by a bank or other financial institution in which the financial transactions between the bank and a customer are recorded.",
    },
    {
        highlighter: "Why",
        question: "should you open a Bank Account?",
        description: "Having a bank account can be a great tool for managing your personal finances. Account statements can help you determine how and where you are spending your money and put you on track for a reasonable savings plan. Additionally, personal savings accounts often pay interest, which basically earns you free money.",
    },
    {
        highlighter: "Benefits",
        question: "of having a Bank Account",
        answer_in_steps: ["Bank accounts are safe - Your money will be protected from theft and fires. Plus, your money will be insured by government so if your bank closes, you will get your money back. The maximum amount of money that can be insured is 1Lakh.", "Bank accounts offer convenience - if you have a checking/savings account, you can easily pay by check or through online bill pay.If you get a debit card for the account, you can withdraw money easily or make payments at stores. A debit card is usually accepted for purchases anywhere credit cards are accepted.", "It's an easy way to save money - Many banks offer an interest rate when you put your money in a savings account. The interest will help your money grow over time. Be sure to shop around and check what fees are involved - you don't want to wind up paying more in fees than you are gaining in interest.", " Bank accounts are cheaper - Banks generally offer their account holders free or low-cost services", "Bank accounts can help you access credit - Banks help you access credit to acquire a home, a car, student or personal loan, because banks tend to favor existing customers, particularly those who manage their money well. Plus, going to small loan lenders that lend you cash quickly can be quite expensive because they charge lending fees and high interest rates."]
    },

    {
        highlighter: "Types",
        question: "of Bank Account",
        description: "Here are some basic Bank Account types in India",
        answer_in_steps: ["Current account : The current account includes deposits, withdrawals, and contra transactions. Such accounts are also called the Demand Deposit Account. A Current account can be opened in most of the commercial banks. A current account being a zero-account, is generally associated with huge transactions on a regular basis", "Savings Account: A savings account is a bank account at a retail bank whose features include the requirements that only a limited number of withdrawals can take place, it does not have cheque facilities and usually do not have a linked debit card facility, it has limited transfer facilities and cannot be overdrawn. Banks offer a variety of savings accounts based on the type of depositor, features of the product, age or purpose of holding the account, and so on. There are regular savings accounts, savings accounts for children, senior citizens or women, institutional savings accounts, family savings accounts, and so many more", "Salary Account :A Salary Account is an account to which your salary gets credited. Usually, banks open these accounts on request of corporations and major companies. Employees can pick their type of salary account based on the features they want. The bank, where you have a salary account, also maintains reimbursement accounts; this is where your allowances and reimbursements are credited to."]
    },
    {
        highlighter: "How",
        question: "to open a Bank Account Offline?",
        description: "Here are some easy steps which you need to follow to open a savings account",
        answer_in_steps: ["Proof of identity – Passport, Driving license, Voter’s ID card, etc.", "Proof of address – Passport, Driving license, Voter’s ID card, etc.", "PAN card", "Form 16 (only if PAN card is not available)", "2 latest passport size photographs"],
    },
];


