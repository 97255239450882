import React from 'react';
import { Tab, Tabs, TabPanel, TabList } from 'react-tabs';
import { Box } from 'grommet-v2';
import PageHeader from '../../common/page-header';
import WWWH from './components/0_WWWHAccordian';
import FF from './components/0_FeaturesFactors';
import HowDoesItWork from './components/0_HowDoesItWork';
import { epf_about, epf_FeatFact, epf_withdraw, epf_working } from '../../../mock_data/data.jsx';

const switch_panel = ["About", "Working of EPF", "Features & Factors", "Withdrawal"];

export default function MultiTab_EPF() {
    return (
        <React.Fragment>
            <Box direction="column" gap="large">
                <PageHeader title="Employee Provident Fund" subTitle="a Governement Scheme" />
                <Tabs selectedTabClassName="show">
                    <TabList className="nav nav-pills nav-border-anim nav-big justify-content-center mb-3">
                        {
                            switch_panel.length > 0 && switch_panel.map((item) =>
                                <Tab className="nav-item">
                                    <span className="nav-link">{item}</span>
                                </Tab>
                            )
                        }
                    </TabList>
                    <TabPanel>
                        <WWWH
                            about_data={epf_about} />
                    </TabPanel>
                    <TabPanel>
                        <HowDoesItWork
                            working_data={epf_working} 
                            image="assets/images/demos-img/epf_working.jpg"/>
                    </TabPanel>
                    <TabPanel>
                        <FF featfact_data={epf_FeatFact}
                            feat_name="Features of Employee Provident Fund(EPF)" />
                    </TabPanel>
                    <TabPanel>
                    <HowDoesItWork
                            working_data={epf_withdraw} />
                    </TabPanel>
                </Tabs>
            </Box>
        </React.Fragment>
    )
}