import React from 'react';
import { Tab, Tabs, TabPanel, TabList } from 'react-tabs';
import Link from 'react-router-dom/Link';
import { Box } from 'grommet-v2';
import PageHeader from '../../common/page-header';
import WWWH from './components/0_WWWHAccordian';
import Connections from './components/Connections';
import Calculator from './calculators/FD_Calculator'
import { epf_about } from '../../../mock_data/data.jsx';


const switch_panel = ["About", "Types, Features & Factors", "Calculator"];

export default function MultiTab_Test({ about_ppf }) {
    return (
        <React.Fragment>
            <Box direction="column" gap="large">
                {/* <PageHeader title="Public Provident Fund" subTitle="a Governement Scheme" /> */}
                <PageHeader title="Testing" subTitle="test" />
                <Tabs selectedTabClassName="show">
                    <TabList className="nav nav-pills nav-border-anim nav-big justify-content-center mb-3">
                        {
                            switch_panel.length > 0 && switch_panel.map((item) =>
                                <Tab className="nav-item">
                                    <span className="nav-link">{item}</span>
                                </Tab>
                            )
                        }
                    </TabList>
                    <TabPanel>
                    </TabPanel>
                    <TabPanel>
                        <Connections />
                    </TabPanel>
                    <TabPanel>
                        <Calculator />
                    </TabPanel>
                </Tabs>
            </Box>
        </React.Fragment>
    )
}