import React from 'react';
import { Box, Text } from 'grommet-v2';
import PropTypes from 'prop-types';
import { FormNextLink, Notes } from 'grommet-icons';

const propTypes = {
    stocks_abbreviation_data: PropTypes.string.isRequired,
};

const defaultProps = {
    stocks_abbreviation_data: '',
};

const resizeWidth = window.innerWidth || document.body.clientWidth;
var resizer;
if (resizeWidth > 1000) {
    resizer = "15%";
} else {
    resizer = "0px";
}

export default function Abbreviation({ stocks_abbreviation_data }) {
    return (
        <React.Fragment>
            <Box direction="column" gap="large" pad={{ horizontal: resizer }} margin={{ top: "25px", bottom: "25px" }} background="light-1">
                <Text size="24px" weight={450} margin={{ top: "30px"}} pad="small" style={{ textAlign: "center" }}> Here are some useful info </Text>
                {
                    stocks_abbreviation_data.map((item, index) => (
                        <Box key={index} responsive elevation="small" round="5px" background="white">
                            <Box direction="row-responsive" align="baseline" margin={{ top: "10px", left: "4px" }} >
                                <Text size="24px" weight={400}>{item.short_form}</Text>
                                &nbsp;
                                <Text size="24px" weight={400}> <FormNextLink color="#c96" /> {item.long_form}</Text>
                            </Box>
                            <Text size="18px" wordBreak="keep-all" margin="small" weight={380}>{item.description}</Text>
                            {
                                item.example && item.example.length > 0 && (
                                    <Box background="light-1" margin="small" direction="row" pad="small" border={{ color: "#c96" }} align="center" justify="center">
                                        <Notes color="#c96" />
                                        <Text size="16px" wordBreak="keep-all" margin="medium" weight={380}> &nbsp; {item.example}</Text>
                                    </Box>
                                )
                            }
                        </Box>
                    ))}
            </Box>
        </React.Fragment>
    );
}

Abbreviation.propTypes = propTypes;
Abbreviation.defaultProps = defaultProps;
