import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import BaseMenu from './base';
import { Box, Text } from 'grommet-v2';
import { Book } from 'grommet-icons';

const resizeWidth = window.innerWidth || document.body.clientWidth
// const resizeHeight = window.innerHeight || document.body.clientHeight;
var bigScreen;
if (resizeWidth > 1000) {
    bigScreen = true;
} else {
    bigScreen = false;
}

class MainMenu extends BaseMenu {

    render() {
        const { demo } = this.props;
        return (
            // <nav onClick={this.activeNav}>
            /* <ul className="mymenu"> */
            /* <li className="megamenu-container active">
                <Link to={`${process.env.PUBLIC_URL}/demo-${demo}`} className="sf-with-ul">About US</Link>
                <div className="megamenu demo">
                        <div className="megamenu-action text-center">
                            <Link to="#ViewAll" className="btn btn-outline-primary-2 view-all-demos" onClick={this.showAllDemos}><span>View All Demos</span><i className="icon-long-arrow-right"></i></Link>
                        </div>
                </div>
            </li> */
            // <Box direction="row" gap="xlarge" margin="medium">
            //     <Link to={`${process.env.PUBLIC_URL}/learn`}>
            //         <Text color="#ffa040" size="xlarge" weight="500" className="sample" style={{ letterSpacing: "0.05em" }}>
            //             <Spa color="#ffa040" size="medium" margin={{bottom: "10px"}}/> Learn
            //         </Text>
            //     </Link>

            //     <Link to={`${process.env.PUBLIC_URL}/plan`}>
            //         <Text color="#ffa040" size="xlarge" weight="500" className="sample" style={{ letterSpacing: "0.05em" }}>
            //             <Grow color="#ffa040" size="medium" /> Planner
            //         </Text>
            //     </Link>

            //     <Link to={`${process.env.PUBLIC_URL}/MoneyPlanned`}>
            //         <Text color="#ffa040" size="xlarge" weight="500" className="sample" style={{ letterSpacing: "0.05em" }}>
            //             <TreeOption color="#ffa040" size="medium" /> Grow
            //         </Text>
            //     </Link>
            // </Box>
            <ul className="menu">
                {/* <li className="megamenu-container active">
                            <Link to={`${process.env.PUBLIC_URL}/demo-${demo}`} className="sf-with-ul">About US</Link>
                            <div className="megamenu demo">
                                    <div className="megamenu-action text-center">
                                        <Link to="#ViewAll" className="btn btn-outline-primary-2 view-all-demos" onClick={this.showAllDemos}><span>View All Demos</span><i className="icon-long-arrow-right"></i></Link>
                                    </div>
                            </div>
                        </li> */}
                <li>
                    { bigScreen === true ? 
                    (<Box pad={{ top:"03px", bottom:"03px", left: "03px" , right: "05px"}} margin="small" border={{ color: "#c96" }} round="medium">
                        <Link to={`${process.env.PUBLIC_URL}/`}>
                            <Box align="baseline" direction="row">
                                <Book size="medium" color="green" /><Text size="34px" color="#c96" weight={550}>She</Text>
                                <Text size="16px" color="black" weight={450}>finances</Text>
                            </Box>
                        </Link>
                    </Box>) : (<Box pad="xxsmall"  border={{ color: "#c96" }} margin="small"round="medium">
                        <Link to={`${process.env.PUBLIC_URL}/`}>
                            <Box align="baseline" direction="row">
                                <Book size="medium" color="green" /><Text size="24px" color="#c96" weight={550}>She</Text>
                                <Text size="12px" color="black" weight={450}>finances</Text>
                            </Box>
                        </Link>
                    </Box>)
    }

                </li>
                {/* 
                    <li>
                        <Link to={`${process.env.PUBLIC_URL}/pages/about`} className="sf-with-ul">Plan</Link>
                    </li>

                    <li>
                        <Link to={`${process.env.PUBLIC_URL}/blog/classic`} className="sf-with-ul">Execute</Link> */}
                {/* </li> */}
            </ul>

            /* </ul>
                </nav> */
        );
    }
}

export const mapStateToProps = (state) => ({
    demo: state.demo.current
})

export default connect(mapStateToProps)(MainMenu);