import React from 'react';
import { Box, Text, Heading } from 'grommet-v2';
import PropTypes from 'prop-types';
import { FormNext } from 'grommet-icons';

const propTypes = {
    featfact_data: PropTypes.string.isRequired,
    feat_name: PropTypes.string.isRequired,
    fact_name: PropTypes.string.isRequired,
};

const defaultProps = {
    featfact_data: ' ',
    feat_name: ' ',
    fact_name: ' ',
};

const resizeWidth = window.innerWidth || document.body.clientWidth;
var resizer;
if (resizeWidth > 1000) {
    resizer = "15%";
} else {
    resizer = "0px";
}


export default function FF({ featfact_data, feat_name, fact_name }) {
    return (
        <React.Fragment>
            <Box pad={{ horizontal: resizer }} margin={{ top: "25px", bottom: "25px" }} background="light-1">
                <Box background="light-1" round="05px" margin="small" elevation="medium" gap="medium" border={{ color: "rgba(204, 153, 102, 0.2)", size: "5px" }} pad="small" >
                    <Heading level={2} size="medium" margin="small" style={{ textAlign: "center" }} alignSelf="center">{feat_name} </Heading>
                    {
                        featfact_data.map((item) => (
                            item.features && item.features.map((it) => it.f_highlighter && it.f_highlighter.length > 0 ?
                                (
                                    <Box elevation="small" margin="small" pad="xsmall" background="white">
                                        <Text size="16px" wordBreak="keep-all" margin="xsmall" weight={550}><FormNext size="medium" color="#c96" />{it.f_highlighter}</Text>
                                        <Text size="16px" wordBreak="keep-all" margin={{ left: "30px" }}>{it.f_details}</Text>
                                    </Box>
                                )
                                :
                                (
                                    <Box elevation="small" margin="small" pad="xsmall" background="white">
                                        <Text size="16px" wordBreak="keep-all" margin={{ left: "30px" }}><FormNext size="medium" color="#c96" />{it.f_details}</Text>
                                    </Box>
                                )
                            )))
                    }
                     <Heading level={2} size="medium" margin="small" style={{ textAlign: "center"}} alignSelf="center">{fact_name} </Heading>
                    {
                        featfact_data.map((item) => (

                            item.factors && item.factors.map((it) => it.ff_highlighter && it.ff_highlighter.length > 0 ?
                                (
                                    <Box elevation="small" margin="small" pad="xsmall" background="white">
                                        <Text size="16px" wordBreak="keep-all" margin="xsmall" weight={550}><FormNext size="medium" color="#c96" />{it.ff_highlighter}</Text>
                                        <Text size="16px" wordBreak="keep-all" margin={{ left: "30px" }}>{it.ff_details}</Text>
                                    </Box>
                                )
                                :
                                (
                                    <Box elevation="small" margin="small" pad="xsmall" background="white">
                                        <Text size="16px" wordBreak="keep-all" margin={{ left: "30px" }}><FormNext size="medium" color="#c96" />{it.ff_details}</Text>
                                    </Box>
                                )
                            )))
                    }
                </Box>
            </Box>
        </React.Fragment>
    );
}

FF.propTypes = propTypes;
FF.defaultProps = defaultProps;
