
import React from 'react';
import { Box, Text, Heading } from 'grommet-v2';
import PropTypes from 'prop-types';
import { FormNext } from 'grommet-icons';

const propTypes = {
    types_data: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
};

const defaultProps = {
    types_data: ' ',
    name: ' ',
};

const resizeWidth = window.innerWidth || document.body.clientWidth;
var resizer;
if (resizeWidth > 1000) {
    resizer = "15%";
} else {
    resizer = "0px";
}


export default function Types({ types_data, name }) {
    return (
        <React.Fragment>
            <Box pad={{ horizontal: resizer }} margin={{ top: "25px", bottom: "25px" }} background="light-1">
                <Box background="light-1" round="05px" margin="small" elevation="medium" gap="medium"  border={{ color: "rgba(204, 153, 102, 0.2)", size: "5px" }} pad="small" >
                     <Text size="34px" textAlign="center" margin="small" weight={550}> Types in {name} </Text>
                    {
                        types_data.map((item) => (
                            item.types && item.types.map((it) => it.t_highlighter && it.t_highlighter.length > 0 ?
                                (
                                    <Box elevation="small" margin="small" pad="xsmall" background="white">
                                        <Text size="16px" wordBreak="keep-all" margin="xsmall" weight={550}><FormNext size="medium" color="#c96" />{it.t_highlighter}</Text>
                                        <Text size="16px" wordBreak="keep-all" margin={{ left: "30px" }}>{it.t_details}</Text>
                                    </Box>
                                )
                                :
                                (
                                    <Box elevation="small" margin="small" pad="xsmall" background="white">
                                        <Text size="16px" wordBreak="keep-all" margin={{ left: "30px" }}><FormNext size="medium" color="#c96" />{it.t_details}</Text>
                                    </Box>
                                )
                            )))
                    }
                </Box>
            </Box>
        </React.Fragment>
    );
}

Types.propTypes = propTypes;
Types.defaultProps = defaultProps;
