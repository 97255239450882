import React from 'react';
import { Tab, Tabs, TabPanel, TabList } from 'react-tabs';
import { Box } from 'grommet-v2';
import PageHeader from '../../common/page-header';
import WWWH from './components/0_WWWHAccordian';
import FF from './components/0_FeaturesFactors';
import { ulip_about, ulip_featfact } from '../../../mock_data/data.jsx';

const switch_panel = ["About", "Features & Factors"];

export default function MultiTab_ULIP({ }) {
    return (
        <React.Fragment>
            <Box direction="column" gap="large">
                <PageHeader title="Unit Linked Insurance Plan" subTitle="a goal-based financial solution" />
                <Tabs selectedTabClassName="show">
                    <TabList className="nav nav-pills nav-border-anim nav-big justify-content-center mb-3">
                        {
                            switch_panel.length > 0 && switch_panel.map((item) =>
                                <Tab className="nav-item">
                                    <span className="nav-link">{item}</span>
                                </Tab>
                            )
                        }
                    </TabList>
                    <TabPanel>
                        <WWWH
                            about_data={ulip_about} />
                    </TabPanel>
                    <TabPanel>
                        <FF featfact_data={ulip_featfact}
                            feat_name="Features and Benefits of Unit Linked Insurance Plan(ULIP)" />
                    </TabPanel>
                </Tabs>
            </Box>
        </React.Fragment>
    )
}