import React from 'react';
import { Box, DataTable, grommet, Grommet, Text, Meter } from "grommet-v2";
import { FormAttachment } from 'grommet-icons';


class MainTable extends React.Component {
    constructor(props) {
        super(props);
        this.formatter = this.formatter.bind(this);
        this.state = {
            columns: [
                // { property: 'loan', header: 'Loan Limit', align: 'center' },
                // { property: 'withdraw', header: 'Withdrawal Limit', align: 'center' },
            ],
            data: [],
            passed_data: [],
        };
    }

    refreshData = (data, columns) => {
        this.setState({
            data: data,
            columns: columns
        })
    }

    formatter(value) {
        const format = new Intl.NumberFormat('en-IN', {
            style: 'currency',
            currency: 'INR',
            minimumFractionDigits: 2
        })

        return format.format(value);
    };


    render() {
        return (
            <React.Fragment>
                <Grommet theme={grommet}>
                    <Box background="light-1" align="center" pad="large">
                        {this.state.data.length > 0 &&
                            <Box background="light-1" gap="large" margin={{ bottom: 'medium' }} pad="small" direction="row" align="center" border={{ color: "rgba(204, 153, 102, 0.2)", size: "5px" }}>
                                <Box gap="small" direction="column" width="400px">
                                    <Box direction="row" align="baseline">
                                        <Text size="22px" weight={600} style={{ width: "170px"}} style={{ textAlign: "center"}}> Total Investment</Text>&nbsp;&nbsp;
                                        <Text size="22px" weight="bold" style={{ textAlign: "center"}}> {this.formatter(this.props.passed_data[0])} </Text>
                                    </Box>
                                    <Box direction="row" align="baseline">
                                        <Text size="22px" weight={600} style={{ width: "170px"}} style={{ textAlign: "center"}}>Total Interest</Text>&nbsp;&nbsp;
                                        <Text size="22px" weight="bold" style={{ textAlign: "center"}}> {this.formatter(this.props.passed_data[1])} </Text>
                                    </Box>
                                    <Box direction="row" align="baseline">
                                        <Text size="22px" weight={600} style={{ width: "170px"}} style={{ textAlign: "center"}}>Maturity Value</Text>&nbsp;&nbsp;
                                    <Text size="22px" weight="bold" style={{ textAlign: "center"}}> {this.formatter(this.props.passed_data[2])} </Text>
                                    </Box>
                                </Box>
                                <Meter
                                    values={[{
                                        value: 375000,
                                        label: 'sixty',
                                        "color": "dark-3",
                                        onClick: () => { }
                                    },
                                    {
                                        value: 303035,
                                        label: 'sixty',
                                        "color": "#c96",
                                        onClick: () => { }
                                    }]}
                                    type="circle"
                                    aria-label="meter"
                                    size="small"
                                    thickness="large"
                                />
                            </Box>
                        }

                        <DataTable
                            columns={this.state.columns}
                            data={this.state.data}
                            pad={{ horizontal: 'large', vertical: 'medium' }}
                            background={{
                                header: 'dark-3',
                                body: ['light-1', 'light-3'],
                                footer: 'dark-3',
                            }}
                            border={{ body: 'bottom' }}
                            rowProps={{ Eric: { background: 'accent-2', pad: 'large' } }}
                        />
                    </Box>
                </Grommet>
            </React.Fragment>
        );
    }
}

export default MainTable;
