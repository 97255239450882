import React from 'react';
import { Box, Text, Image } from 'grommet-v2';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { FormNext } from 'grommet-icons';

const propTypes = {
    qa_data: PropTypes.string.isRequired,
    image: PropTypes.bool,
    link: PropTypes.bool,
};

const defaultProps = {
    qa_data: ' ',
    image: false,
    link: false,
};

const resizeWidth = window.innerWidth || document.body.clientWidth;
var resizer;
if (resizeWidth > 1000) {
    resizer = "15%";
} else {
    resizer = "0px";
}

export default function QA({ qa_data, image, link }) {
    return (
        <React.Fragment>
            <Box elevation="medium" background="light-1" gap="small" pad="xsmall">
                <Box elevation="small" gap="xsmall" pad="small" background="white" margin="xsmall">
                    <Text size="20px" wordBreak="keep-all" margin="xsmall" weight={450} color="#c96" style={{ textAlign: "center"}}>{qa_data[0].question}</Text>
                </Box>
                {
                    (link === true &&
                        <Box>
                            <a href="https://share.moneyplanned.com/bjYi" target="_blank">
                                Tap Here to visit the MoneyPlanned Section (Download app from this referral Link only)
                                </a>
                        </Box>)
                }
                {
                    qa_data.map((item) => item.answers.map((it) => (
                        <Box pad="small" background="white" margin="xsmall" elevation="small">
                            <Text size="16px" wordBreak="keep-all" margin={{ left: "10px" }}><FormNext size="medium" color="#c96" />{it}</Text>
                        </Box>)))
                }
                {
                    (image === true &&
                        <Box>
                            <Image
                                label="test"
                                a11yTitle="a user friendly label for screen readers"
                                fit="cover"
                                src="assets/images/demos-img/referral.jpg"
                                width="300px"
                                height="200px"
                                alignSelf="center"
                            />
                        </Box>)
                }
            </Box>
        </React.Fragment>
    );
}

QA.propTypes = propTypes;
QA.defaultProps = defaultProps;
