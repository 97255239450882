import React from 'react';
import { Tab, Tabs, TabPanel, TabList } from 'react-tabs';
import { Box } from 'grommet-v2';
import PageHeader from '../../common/page-header';
import WWWH from './components/0_WWWHAccordian';
import { mobilei_about, mobilei_FeatFact  } from '../../../mock_data/data.jsx';
import Types from './components/0_Types';
import FF from './components/0_FeaturesFactors';

const switch_panel = ["About", "Features & Factors"];

export default function MultiTab_HealthI() {
    return (
        <React.Fragment>
            <Box direction="column" gap="large">
                {/* <PageHeader title="Public Provident Fund" subTitle="a Governement Scheme" /> */}
                <PageHeader title="Mobile Insurance Policy" subTitle="an insurance for your smartphone" />
                <Tabs selectedTabClassName="show">
                    <TabList className="nav nav-pills nav-border-anim nav-big justify-content-center mb-3">
                        {
                            switch_panel.length > 0 && switch_panel.map((item) =>
                                <Tab className="nav-item">
                                    <span className="nav-link">{item}</span>
                                </Tab>
                            )
                        }
                    </TabList>
                    <TabPanel>
                    <WWWH
                            about_data={mobilei_about} />
                    </TabPanel>

                    <TabPanel>
                        <FF featfact_data={mobilei_FeatFact}
                            feat_name="Features and Benefits of Mobile Insurance Policy" />
                    </TabPanel>
                </Tabs>
            </Box>
        </React.Fragment>
    )
}