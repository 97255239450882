import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Testimonial from '../../features/testimonial/testimonial';
import OwlCarousels from '../../features/owl-carousel';
import { ctaSlider } from './settings';
import { brandSlider, testiSlider } from './settings';
import _data from '../../../mock_data/data';

class CTA extends Component {

    render() {
        return (
            // <div className="cta cta-separator">
                <div className="row">
                    <div className="col-lg-6">
                        <div className="cta-wrapper cta-text text-center">
                            <br />
                            {/* <div className="bg-light-2 pt-7 pb-6 testimonials"> */}
                                <div className="container">
                                    <h2 className="title text-center mb-2">Our Customers Say</h2>

                                    <OwlCarousels adClass="owl-simple owl-testimonials" carouselOptions={testiSlider} >
                                        {
                                            _data.demo7.testimonials.map((item, index) =>
                                                <Testimonial key={index} lead={item.lead} content={item.content} name={item.name} job={item.job} />
                                            )}
                                    </OwlCarousels>
                                </div>
                            </div>
                        </div>
                    </div>
                // </div>
            // </div>
        )
    }
}

export default CTA;