import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { StatusCriticalSmall } from 'grommet-icons';

import Product from './product';
import Banner from './banner';

// import Action
import { addToCart, toggleWishlist, showQuickViewModal, addToCompare } from '../../../actions';
import { getDealProducts, getProductsByDemo } from '../../../services';

import data from '../../../mock_data/data';

class FirstCollection extends Component {
    render() {
        const { addToCart, toggleWishlist, showQuickViewModal, addToCompare } = this.props;

        let products = getDealProducts(getProductsByDemo(this.props.products, "demo15"), "men");

        return (
            <div className="display-row" id="scroll-to-content">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-lg-6 order-lg-last">
                            <Banner banner={data.demo15.categoryBanners[0]} />
                        </div>

                        <div className="col-lg-6">
                            <div className="heading text-center">
                                <h1 className="black-subtitle">Your First Step towards Financing.</h1>
                                <h2 className="big-title">Plan your financial goals effortlessly using our <strong>Learn Module</strong></h2>
                                <p className="title-desc">Are you a strong woman who wants to take care of her finances but doesn't know how to get started? Here's your answer, We have streamlined and customized content written for each module by moneyPlanned organization's financial advisor to help you understand the Money subject more easily we have made lot of research and have divided the module into 6 divisions as below.</p>
                                <br />
                                <div className="widget">
                                    {/* <h2 className="title"> we have made lot of research and have divided the module into 6 divisions as below</h2> */}
                                    <ul className="widget-list">
                                        <li className="testing"><StatusCriticalSmall color=" #cc9966" size="small" /><Link to="#">Govt Schemes offered for Women</Link></li>
                                        <li><StatusCriticalSmall color=" #cc9966" size="small" /><Link to="#">Taxation and Tax Exemption Schemes </Link></li>
                                        <li><StatusCriticalSmall color=" #cc9966" size="small" /><Link to="#">Opening bank account and Fixed Deposits </Link></li>
                                        <li><StatusCriticalSmall color=" #cc9966" size="small" /><Link to="#">Popular investment options </Link></li>
                                        <li><StatusCriticalSmall color=" #cc9966" size="small" /><Link to="#">All About <strong>Insurance</strong></Link></li>
                                        <li><StatusCriticalSmall color=" #cc9966" size="small" /><Link to="#">Let me learn about <strong>Stocks</strong></Link></li>
                                    </ul>
                                </div>
                                <div className="heading text-center">
                                    <Link to={`${process.env.PUBLIC_URL}/shop/sidebar/list`} className="btn btn-outline-primary-2"><span>Click on me to get Started</span><i className="icon-long-arrow-right"></i></Link>
                                </div>

                                {/* <div className="row">
                                <div className="col-lg-8 offset-lg-2">
                                    <div className="row">
                                        {products.map((item, index) =>
                                            <div className="col-6" key={`1_${index}`}>
                                                <Product
                                                    product={item}
                                                    onAddToCartClick={addToCart}
                                                    onToggleWishlistClick={toggleWishlist}
                                                    onAddToCompareClick={addToCompare}
                                                    showQuickViewModal={showQuickViewModal}
                                                />
                                            </div>
                                        )}
                                    </div>
                                    <br/>   
                                </div>
                            </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export const mapStateToProps = (state) => ({
    products: state.data.products ? state.data.products : []
})

export default connect(mapStateToProps, { addToCart, toggleWishlist, showQuickViewModal, addToCompare })(FirstCollection);