import React, { Component } from 'react';
import { Tabs, TabList, TabPanel, Tab } from 'react-tabs';
import { Box } from 'grommet-v2';

// import Custom Components
import PageHeader from '../../common/page-header';
// import Breadcrumb from '../../common/breadcrumb';
import QA from '../pages/components/0_QuestionAnswer';
import { moneyPlannedPhilosophy, whyMoneyPlanned, servicesOfMoneyPlanned, freeSubscription } from '../../../mock_data/data.jsx';

class DashBoard extends Component {
    render() {
        return (
            <div className="main">
                <PageHeader title="Wondering where to begin?" subTitle="We're introducing you MoneyPlanned... to begin your investment!" />
                <Box pad="small" margin="small">
                    <div className="page-content">
                        <div className="dashboard">
                            <div className="container">
                                <ul className="nav nav-dashboard flex-column mb-3 mb-md-0" role="tablist">
                                    <Tabs selectedTabClassName="active show">
                                        <div className="row">
                                            <aside className="col-md-4 col-lg-3">
                                                <TabList>
                                                    <Tab className="nav-item">
                                                        <span className="nav-link">Philosophy behind MoneyPlanned</span>
                                                    </Tab>

                                                    <Tab className="nav-item">
                                                        <span className="nav-link">Why MoneyPlanned?</span>
                                                    </Tab>

                                                    <Tab className="nav-item">
                                                        <span className="nav-link">Services offered by MoneyPlanned</span>
                                                    </Tab>

                                                    <Tab className="nav-item">
                                                        <span className="nav-link">Free Subscripton Details</span>
                                                    </Tab>

                                                    <Tab className="nav-item">
                                                        <span className="nav-link">Contact MoneyPlanned</span>
                                                    </Tab>
                                                </TabList>
                                            </aside>

                                            <div className="col-md-8 col-lg-9" style={{ marginTop: "1rem" }}>
                                                <div className="tab-pane">
                                                    <TabPanel>
                                                        <QA qa_data={moneyPlannedPhilosophy} />
                                                    </TabPanel>

                                                    <TabPanel>
                                                        <QA qa_data={whyMoneyPlanned} />
                                                    </TabPanel>

                                                    <TabPanel>
                                                        <QA qa_data={servicesOfMoneyPlanned} />
                                                    </TabPanel>
                                                    <TabPanel>
                                                        <QA qa_data={freeSubscription}
                                                            link={true}
                                                            image={true} />
                                                    </TabPanel>
                                                    <TabPanel>
                                                       Contact MoneyPlanned here - info@moneyplanned.com
                                                    </TabPanel>
                                                </div>
                                            </div>
                                        </div>
                                    </Tabs>
                                </ul>
                            </div>
                        </div>
                    </div>
                </Box>
            </div>
        )
    }
}

export default DashBoard;