import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Grommet, grommet, ThemeContext } from 'grommet-v2';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { ScrollContext } from 'react-router-scroll-4';
import 'react-app-polyfill/ie11';
import firebase from 'firebase';
// Store
import store from './store';

// Actions
import { getAllProducts, getAllPosts, closeQuickViewModal } from './actions';

// Layouts
import Layout from './components/app';

// Demoes
import Index1 from './components/demoes/index1';
import Index2 from './components/demoes/index2';
import Index3 from './components/demoes/index3';
import Index4 from './components/demoes/index4';
import Index5 from './components/demoes/index5';
import Index6 from './components/demoes/index6';
import Index7 from './components/demoes/index7';
import Index8 from './components/demoes/index8';
import Index9 from './components/demoes/index9';
import Index10 from './components/demoes/index10';
import Index11 from './components/demoes/index11';
import Index12 from './components/demoes/index12';
import Index13 from './components/demoes/index13';
import Index14 from './components/demoes/index14';
import Index15 from './components/demoes/index15';
import Index16 from './components/demoes/index16';
import Index17 from './components/demoes/index17';
import Index18 from './components/demoes/index18';
import Index19 from './components/demoes/index19';
import Index20 from './components/demoes/index20';
import Index21 from './components/demoes/index21';
import Index22 from './components/demoes/index22';
import Index23 from './components/demoes/index23';
import Index24 from './components/demoes/index24';

// Elements
import List from './components/main/elements/list';
import Buttons from './components/main/elements/buttons';
import Banners from './components/main/elements/banners';
import Products from './components/main/elements/products';
import Categories from './components/main/elements/categories';
import Titles from './components/main/elements/titles';
import Tabs from './components/main/elements/tabs';
import Accordions from './components/main/elements/accordions';
import Portfolioes from './components/main/elements/portfolioes';
import Testimonials from './components/main/elements/testimonials';
import Typography from './components/main/elements/typography';
import CTA from './components/main/elements/cta';
import IconBoxes from './components/main/elements/iconboxes';
import BlogPosts from './components/main/elements/blogposts';
import VideoBanners from './components/main/elements/videobanners';
import Listing from './components/main/blog/listing';

// Pages
import PageNotFound from './components/main/pages/404';

// A
import MultiTab_Test from './components/main/pages/0_MultiTab_Test';
import MultiTab_EPF from './components/main/pages/A_MultiTab_EPF';
import MultiTab_PPF from './components/main/pages/A_MultiTab_PPF';
import MultiTab_NPS from './components/main/pages/A_MultiTab_NPS';
import MultiTab_POTD from './components/main/pages/A_MultiTab_POTD';
import MultiTab_PORD from './components/main/pages/A_MultiTab_PORD';
import MultiTab_POMIS from './components/main/pages/A_MultiTab_POMIS';
import MultiTab_ELSS from './components/main/pages/A_MultiTab_ELSS';
import MultiTab_ULIP from './components/main/pages/A_MultiTab_ULIP';
import MultiTab_SSY from './components/main/pages/A_MultiTab_SSY';
import MultiTab_SCSS from './components/main/pages/A_MultiTab_SCSS';
import MultiTab_NSC from './components/main/pages/A_MultiTab_NSC';
import MultiTab_TFD from './components/main/pages/A_MultiTab_TFD';

// B
import MultiTab_MF from './components/main/pages/B_MultiTab_MF';
import MultiTab_SIP from './components/main/pages/B_MultiTab_SIP';

// C

import MultiTab_BMB from './components/main/pages/C_MultiTab_BMB';
import MultiTab_AY from './components/main/pages/C_MultiTab_AY';

import MultiTab_SSP from './components/main/pages/C_MultiTab_SSP';
import MultiTab_OMVS from './components/main/pages/C_MultiTab_OMVS';
import MultiTab_US from './components/main/pages/C_MultiTab_US';
import MultiTab_MUNS from './components/main/pages/C_MultiTab_MUNS';
import MultiTab_DSS from './components/main/pages/C_MultiTab_DSS';
import MultiTab_CKS from './components/main/pages/C_MultiTab_CKS';
import MultiTab_BSY from './components/main/pages/C_MultiTab_BSY';
import MultiTab_MRY from './components/main/pages/C_MultiTab_MRY';
import MultiTab_CBSE from './components/main/pages/C_MultiTab_CBSE';
import MultiTab_LY from './components/main/pages/C_MultiTab_LY';



import MultiTab_Lifei from './components/main/pages/E_MultiTab_Lifei';
import MultiTab_Healthi from './components/main/pages/E_MultiTab_Healthi';
import MultiTab_Traveli from './components/main/pages/E_MultiTab_Traveli';
import MultiTab_Motori from './components/main/pages/E_MultiTab_Motori';
import MultiTab_Mobilei from './components/main/pages/E_MultiTab_Mobilei';
import MultiTab_HTOBA from './components/main/pages/E_multiTab_HTOBA';
import MultiTab_Stocks from './components/main/pages/F_MultiTab_Stocks';


import ComingSoon from './components/main/pages/coming-soon';

// Calculators
import NPS_Calculator from './components/main/pages/calculators/NPS_Calculator';
import EMI_Calculator from './components/main/pages/calculators/EMI_Calculator';
import PPF_Calculator from './components/main/pages/ppf-calc';
import MF_Calculator from './components/main/pages/calculators/MF_Calculator';
import FD_Calculator from './components/main/pages/calculators/FD_Calculator';
import SIP_Calculator from './components/main/pages/calculators/SIP_Calculator';
import SSY_Calculator from './components/main/pages/calculators/SSY_Calculator'
import RD_Calculator from './components/main/pages/calculators/RD_Calculator';
import POTD_Calculator from './components/main/pages/calculators/POTD_Calculator';
import PORD_Calculator from './components/main/pages/calculators/PORD_Calculator';
import POMIS_Calculator from './components/main/pages/calculators/POMIS_Calculator';
import NSC_Calculator from './components/main/pages/calculators/NSC_Calculator';


// Blogs
import classic from './components/main/blog/classic';
import Grid2Cols from './components/main/blog/grid-2cols';
import Grid3Cols from './components/main/blog/grid-3cols';
import Grid4Cols from './components/main/blog/grid-4cols';
import GridSidebar from './components/main/blog/grid-sidebar';
import Masonry2Cols from './components/main/blog/masonry-2cols';
import Masonry3Cols from './components/main/blog/masonry-3cols';
import Masonry4Cols from './components/main/blog/masonry-4cols';
import MasonrySidebar from './components/main/blog/masonry-sidebar';
import MaskGrid from './components/main/blog/mask-grid';
import MaskMasonry from './components/main/blog/mask-masonry';
import SingleDefaultPost from './components/main/blog/single-default';
import SingleFullWidth from './components/main/blog/single-fw';
import SingleFwSidebar from './components/main/blog/single-fw-sidebar';

//products
import FullwidthProduct from './components/main/product/fullwidth';

//Shop
import NoSideBar from './components/main/shop/nosidebar';
import ShopList from './components/main/shop/sidebar';
import ShopMarket from './components/main/shop/market';
import ProductCategory from './components/main/shop/product-category';
import MyAccount from './components/main/shop/dashboard';
import Wishlist from './components/main/shop/wishlist';
import Cart from './components/main/shop/cart';
import Checkout from './components/main/shop/checkout';

//Landing Page
import Landing from './components/landing';
import Documentation from './components/docs';

// import Utils
import { initFunctions } from './utils/utils';
import DashBoard from './components/main/shop/dashboard';

export class Root extends Component {
    constructor(props) {
        super(props);
        initFunctions();
    }

    componentDidMount() {
        // close quick view modal if it is opened in new page
        var firebaseConfig = {
            apiKey: "AIzaSyAGF78xp7Gtl4KqiOnQzkNlTKUXEDrgVaA",
            authDomain: "shefinance.firebaseapp.com",
            projectId: "shefinance",
            storageBucket: "shefinance.appspot.com",
            messagingSenderId: "2409412221",
            appId: "1:2409412221:web:8f1d4451a4f8eecca22314",
            measurementId: "G-PMEL4KEFZ0"
        };
        firebase.initializeApp(firebaseConfig);
        firebase.analytics();
        firebase.performance();
        
        store.dispatch(closeQuickViewModal());
    }

    render() {
        store.dispatch(getAllProducts());
        store.dispatch(getAllPosts());

        return (
            <Provider store={store} >
                <BrowserRouter basename={'/'} >
                    <ScrollContext>
                        <Grommet theme="grommet">
                            <ThemeContext.Extend
                                value={{ global: { colors: { brand: '#c96', focus: '#c96' } } }}
                            >
                                <Switch>
                                    {/* Demoes and Individual Pages */}
                                    <Route exact path={`${process.env.PUBLIC_URL}/`} component={Landing} />
                                    {/* <Route exact path={`${process.env.PUBLIC_URL}/documentation`} component={Documentation} />
                                    <Route exact path={`${process.env.PUBLIC_URL}/demo-1`} component={Index1} />
                                    <Route exact path={`${process.env.PUBLIC_URL}/demo-2`} component={Index2} />
                                    <Route exact path={`${process.env.PUBLIC_URL}/demo-3`} component={Index3} />
                                    <Route exact path={`${process.env.PUBLIC_URL}/demo-4`} component={Index4} />
                                    <Route exact path={`${process.env.PUBLIC_URL}/demo-5`} component={Index5} />
                                    <Route exact path={`${process.env.PUBLIC_URL}/demo-6`} component={Index6} />
                                    <Route exact path={`${process.env.PUBLIC_URL}/demo-7`} component={Index7} />
                                    <Route exact path={`${process.env.PUBLIC_URL}/demo-8`} component={Index8} />
                                    <Route exact path={`${process.env.PUBLIC_URL}/demo-9`} component={Index9} />
                                    <Route exact path={`${process.env.PUBLIC_URL}/demo-10`} component={Index10} />
                                    <Route exact path={`${process.env.PUBLIC_URL}/demo-11`} component={Index11} />
                                    <Route exact path={`${process.env.PUBLIC_URL}/demo-12`} component={Index12} />
                                    <Route exact path={`${process.env.PUBLIC_URL}/demo-13`} component={Index13} />
                                    <Route exact path={`${process.env.PUBLIC_URL}/demo-14`} component={Index14} />
                                    <Route exact path={`${process.env.PUBLIC_URL}/demo-15`} component={Index15} />
                                    <Route exact path={`${process.env.PUBLIC_URL}/demo-16`} component={Index16} />
                                    <Route exact path={`${process.env.PUBLIC_URL}/demo-17`} component={Index17} />
                                    <Route exact path={`${process.env.PUBLIC_URL}/demo-18`} component={Index18} />
                                    <Route exact path={`${process.env.PUBLIC_URL}/demo-19`} component={Index19} />
                                    <Route exact path={`${process.env.PUBLIC_URL}/demo-20`} component={Index20} />
                                    <Route exact path={`${process.env.PUBLIC_URL}/demo-21`} component={Index21} />
                                    <Route exact path={`${process.env.PUBLIC_URL}/demo-22`} component={Index22} /> */}
                                    {/* <Route exact path={`${process.env.PUBLIC_URL}/learn`} component={Index23} /> */}
                                    {/* <Route exact path={`${process.env.PUBLIC_URL}/demo-24`} component={Index24} /> */}

                                    {/* <Route exact path={`${process.env.PUBLIC_URL}/pages/coming-soon`} component={ComingSoon} />
                                    <Route exact path={`${process.env.PUBLIC_URL}/product/fullwidth/:id`} component={FullwidthProduct} />
                                    <Route exact path={`${process.env.PUBLIC_URL}/shop/nosidebar/:type`} component={NoSideBar} />
                                    <Route exact path={`${process.env.PUBLIC_URL}/shop/market`} component={ShopMarket} />
                                    <Route exact path={`${process.env.PUBLIC_URL}/shop/category/:type`} component={ProductCategory} /> */}
                                    <Layout>
                                        {/* Element Pages */}
                                        {/* <Route exact path={`${process.env.PUBLIC_URL}/elements/list`} component={List} />
                                        <Route exact path={`${process.env.PUBLIC_URL}/elements/products`} component={Products} />
                                        <Route exact path={`${process.env.PUBLIC_URL}/elements/categories`} component={Categories} />
                                        <Route exact path={`${process.env.PUBLIC_URL}/elements/buttons`} component={Buttons} />
                                        <Route exact path={`${process.env.PUBLIC_URL}/elements/banners`} component={Banners} />
                                        <Route exact path={`${process.env.PUBLIC_URL}/elements/titles`} component={Titles} />
                                        <Route exact path={`${process.env.PUBLIC_URL}/elements/tabs`} component={Tabs} />
                                        <Route exact path={`${process.env.PUBLIC_URL}/elements/accordions`} component={Accordions} />
                                        <Route exact path={`${process.env.PUBLIC_URL}/elements/portfolios`} component={Portfolioes} />
                                        <Route exact path={`${process.env.PUBLIC_URL}/elements/testimonials`} component={Testimonials} />
                                        <Route exact path={`${process.env.PUBLIC_URL}/elements/typography`} component={Typography} />
                                        <Route exact path={`${process.env.PUBLIC_URL}/elements/cta`} component={CTA} />
                                        <Route exact path={`${process.env.PUBLIC_URL}/elements/icon-boxes`} component={IconBoxes} />
                                        <Route exact path={`${process.env.PUBLIC_URL}/elements/blog-posts`} component={BlogPosts} />
                                        <Route exact path={`${process.env.PUBLIC_URL}/elements/video-banners`} component={VideoBanners} /> */}

                                        {/* Blog Pages */}
                                        {/* <Route exact path={`${process.env.PUBLIC_URL}/blog/listing`} component={Listing} />
                                        <Route exact path={`${process.env.PUBLIC_URL}/blog/classic`} component={classic} />
                                        <Route exact path={`${process.env.PUBLIC_URL}/blog/grid/2cols`} component={Grid2Cols} />
                                        <Route exact path={`${process.env.PUBLIC_URL}/blog/grid/3cols`} component={Grid3Cols} />
                                        <Route exact path={`${process.env.PUBLIC_URL}/blog/grid/4cols`} component={Grid4Cols} />
                                        <Route exact path={`${process.env.PUBLIC_URL}/blog/grid/sidebar`} component={GridSidebar} />
                                        <Route exact path={`${process.env.PUBLIC_URL}/blog/masonry/2cols`} component={Masonry2Cols} />
                                        <Route exact path={`${process.env.PUBLIC_URL}/blog/masonry/3cols`} component={Masonry3Cols} />
                                        <Route exact path={`${process.env.PUBLIC_URL}/blog/masonry/4cols`} component={Masonry4Cols} />
                                        <Route exact path={`${process.env.PUBLIC_URL}/blog/masonry/sidebar`} component={MasonrySidebar} />
                                        <Route exact path={`${process.env.PUBLIC_URL}/blog/mask/grid`} component={MaskGrid} />
                                        <Route exact path={`${process.env.PUBLIC_URL}/blog/mask/masonry`} component={MaskMasonry} />
                                        <Route exact path={`${process.env.PUBLIC_URL}/blog/single/:id`} component={SingleDefaultPost} />
                                        <Route exact path={`${process.env.PUBLIC_URL}/blog/single-2/:id`} component={SingleFullWidth} />
                                        <Route exact path={`${process.env.PUBLIC_URL}/blog/single-3/:id`} component={SingleFwSidebar} /> */}

                                        {/* Pages not Found */}
                                        <Route exact path={`${process.env.PUBLIC_URL}/learn/404`} component={PageNotFound} />

                                        {/* Section A*/}
                                        <Route exact path={`${process.env.PUBLIC_URL}/learn/test`} component={MultiTab_Test} />
                                        <Route exact path={`${process.env.PUBLIC_URL}/learn/ppf`} component={MultiTab_PPF} />
                                        <Route exact path={`${process.env.PUBLIC_URL}/learn/epf`} component={MultiTab_EPF} />
                                        <Route exact path={`${process.env.PUBLIC_URL}/learn/elss`} component={MultiTab_ELSS} />
                                        <Route exact path={`${process.env.PUBLIC_URL}/learn/ulip`} component={MultiTab_ULIP} />
                                        <Route exact path={`${process.env.PUBLIC_URL}/learn/ssy`} component={MultiTab_SSY} />
                                        <Route exact path={`${process.env.PUBLIC_URL}/learn/nps`} component={MultiTab_NPS} />
                                        <Route exact path={`${process.env.PUBLIC_URL}/learn/nsc`} component={MultiTab_NSC} />
                                        <Route exact path={`${process.env.PUBLIC_URL}/learn/potd`} component={MultiTab_POTD} />
                                        <Route exact path={`${process.env.PUBLIC_URL}/learn/pord`} component={MultiTab_PORD} />
                                        <Route exact path={`${process.env.PUBLIC_URL}/learn/pomis`} component={MultiTab_POMIS} />
                                        <Route exact path={`${process.env.PUBLIC_URL}/learn/scss`} component={MultiTab_SCSS} />
                                        <Route exact path={`${process.env.PUBLIC_URL}/learn/tfd`} component={MultiTab_TFD} />

                                        {/* Section - B */}
                                        <Route exact path={`${process.env.PUBLIC_URL}/learn/mutualfunds`} component={MultiTab_MF} />

                                        {/* Section - C */}
                                        <Route exact path={`${process.env.PUBLIC_URL}/learn/bmb`} component={MultiTab_BMB} />
                                        <Route exact path={`${process.env.PUBLIC_URL}/learn/ay`} component={MultiTab_AY} />
                                        <Route exact path={`${process.env.PUBLIC_URL}/learn/ssp`} component={MultiTab_SSP} />
                                        <Route exact path={`${process.env.PUBLIC_URL}/learn/omvs`} component={MultiTab_OMVS} />
                                        <Route exact path={`${process.env.PUBLIC_URL}/learn/us`} component={MultiTab_US} />
                                        <Route exact path={`${process.env.PUBLIC_URL}/learn/muns`} component={MultiTab_MUNS} />
                                        <Route exact path={`${process.env.PUBLIC_URL}/learn/dss`} component={MultiTab_DSS} />
                                        <Route exact path={`${process.env.PUBLIC_URL}/learn/cks`} component={MultiTab_CKS} />
                                        <Route exact path={`${process.env.PUBLIC_URL}/learn/bsy`} component={MultiTab_BSY} />
                                        <Route exact path={`${process.env.PUBLIC_URL}/learn/mry`} component={MultiTab_MRY} />
                                        <Route exact path={`${process.env.PUBLIC_URL}/learn/cbse`} component={MultiTab_CBSE} />
                                        <Route exact path={`${process.env.PUBLIC_URL}/learn/ly`} component={MultiTab_LY} />
                                        
                                        {/* Section E */}
                                        <Route exact path={`${process.env.PUBLIC_URL}/learn/lifei`} component={MultiTab_Lifei} />
                                        <Route exact path={`${process.env.PUBLIC_URL}/learn/healthi`} component={MultiTab_Healthi} />
                                        <Route exact path={`${process.env.PUBLIC_URL}/learn/traveli`} component={MultiTab_Traveli} />
                                        <Route exact path={`${process.env.PUBLIC_URL}/learn/motori`} component={MultiTab_Motori} />
                                        <Route exact path={`${process.env.PUBLIC_URL}/learn/mobilei`} component={MultiTab_Mobilei} />
                                        <Route exact path={`${process.env.PUBLIC_URL}/learn/bankacc`} component={MultiTab_HTOBA} />


                                        {/* <Route exact path={ `${process.env.PUBLIC_URL}/learn/mutualfunds`} component={ MultiTab_MutualFunds }/> */}
                                        <Route exact path={`${process.env.PUBLIC_URL}/learn/stocks`} component={MultiTab_Stocks} />
                                        <Route exact path={`${process.env.PUBLIC_URL}/learn/sip`} component={MultiTab_SIP} />

                                        {/* Calculators */}
                                        {/* Section A */}
                                        <Route exact path={`${process.env.PUBLIC_URL}/calculate/ppf`} component={PPF_Calculator} />
                                        <Route exact path={ `${process.env.PUBLIC_URL}/calculate/nps`} component={ NPS_Calculator }/>
                                        <Route exact path={`${process.env.PUBLIC_URL}/calculate/sip`} component={SIP_Calculator} />
                                        <Route exact path={`${process.env.PUBLIC_URL}/calculate/mf`} component={MF_Calculator} />
                                        {/* Section B */}
                                        <Route exact path={`${process.env.PUBLIC_URL}/calculate/ssy`} component={SSY_Calculator} />
                                        <Route exact path={`${process.env.PUBLIC_URL}/calculate/fd`} component={FD_Calculator} />
                                        <Route exact path={`${process.env.PUBLIC_URL}/calculate/rd`} component={RD_Calculator} />
                                        <Route exact path={`${process.env.PUBLIC_URL}/calculate/emi`} component={EMI_Calculator} />
                                        {/* Section C */}
                                        <Route exact path={`${process.env.PUBLIC_URL}/calculate/nsc`} component={NSC_Calculator} />
                                        <Route exact path={`${process.env.PUBLIC_URL}/calculate/potd`} component={POTD_Calculator} />
                                        <Route exact path={`${process.env.PUBLIC_URL}/calculate/pord`} component={PORD_Calculator} />
                                        <Route exact path={`${process.env.PUBLIC_URL}/calculate/pomis`} component={POMIS_Calculator} />

                                        <Route exact path={`${process.env.PUBLIC_URL}/invest/moneyplanned`} component={ DashBoard } />
                                        
                                        {/* Shop Pages */}
                                        {/* <Route exact path={`${process.env.PUBLIC_URL}/shop/dashboard`} component={MyAccount} />
                                        <Route exact path={`${process.env.PUBLIC_URL}/shop/sidebar/:grid`} component={ShopList} />
                                        <Route exact path={`${process.env.PUBLIC_URL}/shop/wishlist`} component={Wishlist} />
                                        <Route exact path={`${process.env.PUBLIC_URL}/shop/cart`} component={Cart} />
                                        <Route exact path={`${process.env.PUBLIC_URL}/shop/checkout`} component={Checkout} /> */}
                                    </Layout>
                                </Switch>
                            </ThemeContext.Extend>
                        </Grommet>
                    </ScrollContext>
                </BrowserRouter>
            </Provider>
        );
    }
}

ReactDOM.render(<Root />, document.getElementById('root'));