import React from 'react';
import { Box, Grid, Text, Heading } from 'grommet-v2';
import PropTypes from 'prop-types';
import { FormNext, CircleAlert, Alert } from 'grommet-icons';

const propTypes = {
    stocks_types_data: PropTypes.string.isRequired,
};

const defaultProps = {
    stocks_types_data: '',
};

const resizeWidth = window.innerWidth || document.body.clientWidth;
var resizer, marginBox;
if (resizeWidth > 1000) {
    resizer = "15%";
    marginBox = "20px";
} else {
    resizer = "0px";
    marginBox = "10px";
}

export default function TraderTypes({ stocks_types_data }) {
    return (
        <React.Fragment>
            <Box pad={{ horizontal: resizer }}  background="light-1" margin={{ top: marginBox }}>
                <Grid
                    rows={{
                        count: 4,
                        size: 'auto',
                    }}
                    gap="medium"
                >
                   <Text size="24px" weight={450} margin="small" style={{ textAlign: "center" }}> List of Stock Orders available in the Stock Market </Text>
                    {
                        stocks_types_data.map((item, index) => (
                            <Box key={index} background="white" responsive elevation="small" round="5px">
                                <Box direction="row-responsive" align="baseline" margin={{ top: "10px", left: "4px" }} >
                                <Text size="22px" weight={450}>{item.long_form}</Text>
                                </Box>
                                <Text size="18px" wordBreak="keep-all" margin="xsmall">{item.description}</Text>
                                {
                                    item.steps && item.steps.length > 0 && item.steps.map((item) => (
                                        <Text size="18px" wordBreak="keep-all" margin="small"><FormNext color="#c96" /> &nbsp; {item}</Text>
                                    ))
                                }
                                {
                                    item.note && item.note.length > 0 && (
                                        <Box border={{ color: "#c96", size: "small" }} margin="small" direction="row-responsive">
                                            <Text size="17px" wordBreak="keep-all" margin="small"><Alert size="medium" color="#c96" />&nbsp; {item.note}</Text>
                                        </Box>
                                    )
                                }
                            </Box>
                        ))}
                </Grid>
            </Box>
        </React.Fragment>
    );
}


TraderTypes.propTypes = propTypes;
TraderTypes.defaultProps = defaultProps;