import React from 'react';
import { Box, Grid, RangeInput, TextInput, Heading, Text, Meter } from 'grommet-v2';
import PageHeader from '../../../common/page-header';

export default function NSC_Calculator({ headerdisplay }) {
    const [investvalue, setinvestvalue] = React.useState(1000);
    const [interest, setinterest] = React.useState(7);
    const [time, settime] = React.useState(2);

    var totalInterest = null;
    var maturityValue = null;
    if (investvalue !== 0) {
        totalInterest = (investvalue * ((interest) / 100)) * time;
        maturityValue = totalInterest + investvalue;
    }
    else {
        // Get the formula code
    }

    const resizeWidth = window.innerWidth || document.body.clientWidth;
    var resizer;
    var bigScreen;
    if (resizeWidth > 1000) {
        resizer = "15%";
        bigScreen = true;
    } else {
        resizer = "0px";
        bigScreen = false;
    }

    const formatter = (value) => {
        const format = new Intl.NumberFormat('en-IN', {
            style: 'currency',
            currency: 'INR',
            minimumFractionDigits: 2
        })
        return format.format(value);
    };

    const grid1 = (<Box pad="small" background="light-1">
        <Box pad="large" gap="large">
            <Box direction="row" align="baseline" gap="medium">
                <Heading level={5}> Monthly Investment </Heading>
                <TextInput
                    size="small"
                    placeholder="type here"
                    value={investvalue}
                    onChange={event => setinvestvalue(event.target.value)}
                />
            </Box>
            <RangeInput
                style={{ width: "400px" }}
                min={0}
                max={1000000}
                step={1}
                value={investvalue}
                onChange={event => setinvestvalue(event.target.value)}
            />
            <Box direction="column" align="baseline" gap="small">
                <Box direction="row" align="baseline" gap="medium">
                    <Heading level={5}> Rate of Interest (P.A) </Heading>
                    <TextInput
                        size="small"
                        placeholder="type here"
                        value={interest}
                        onChange={event => setinterest(event.target.value)}
                    />
                </Box>
                <RangeInput
                    style={{ width: "400px" }}
                    min={0}
                    max={30}
                    step={0.5}
                    value={interest}
                    onChange={event => setinterest(event.target.value)}
                />
            </Box>
            <Box direction="column" align="baseline" gap="small">
                <Box direction="row" align="baseline" gap="medium">
                    <Heading level={5}> Time Period </Heading>
                    <TextInput
                        size="small"
                        placeholder="type here"
                        value={time}
                        onChange={event => settime(event.target.value)}
                    />
                </Box>
                <RangeInput
                    style={{ width: "400px" }}
                    min={1}
                    max={30}
                    step={1}
                    value={time}
                    onChange={event => settime(event.target.value)}
                />
            </Box>
        </Box>
    </Box>)

    const grid2 = (
        <Box background="light-1">
            {
                (maturityValue !== null) ? (
                    <Box background="light-1" margin="small" align="center" justify="center" gap="medium" pad="medium"> Calculated Data
                        <Text color="#c96" size="18px " weight={550}>Total Investment - {formatter(investvalue)} </Text>
                        <Text color="#c96" size="18px " weight={550}>Total Interest - {formatter(totalInterest)} </Text>
                        <Text color="#c96" size="18px" weight={550}>Maturity Value - {formatter(maturityValue)} </Text>
                        <Meter
                            values={[{
                                value: totalInterest,
                                label: 'totalInterest',
                                "color": "grey",
                            },
                            {
                                value: maturityValue,
                                label: 'maturityValue',
                                "color": "#c96",
                            }]}
                            type="circle"
                            aria-label="meter"
                            size="300px"
                            thickness="60px"
                        />
                    </Box>
                ) :
                    (
                        null
                    )
            }
        </Box>)
    return (
        <React.Fragment>
            {
                headerdisplay === false ? null :
                    (<PageHeader title="National Saving Certificate (NSC) Calculator" subTitle="Calculate your future investments" />)
            }
            {/* https://groww.in/calculators/nps-calculator/ */}
            <Box pad={{ horizontal: resizer }} margin={{ top: "25px", bottom: "25px" }} align="center" justify="center" gap="medium">
                {bigScreen === true ?
                    <Grid
                        columns={{
                            count: 2,
                            size: 'auto',
                        }}
                        gap="medium"
                    >
                        {grid1}
                        {grid2}
                    </Grid>
                    :
                    < Grid
                        rows={{
                            count: 2,
                            size: 'auto',
                        }}
                        gap="medium"
                    >
                        {grid1}
                        {grid2}
                    </Grid>
                }
            </Box >
        </React.Fragment>
    );
}