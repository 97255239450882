import React from 'react';
import { Tab, Tabs, TabPanel, TabList } from 'react-tabs';
import Link from 'react-router-dom/Link';
import { Box } from 'grommet-v2';
import PageHeader from '../../common/page-header';
import WWWH from './components/0_WWWHAccordian';
import { healthi_about, healthi_FeatFact, healthi_types } from '../../../mock_data/data.jsx';
import FF from './components/0_FeaturesFactors';
import Types from './components/0_Types';

const switch_panel = ["About", "Feattures & Factors", "Types"];

export default function MultiTab_HealthI() {
    return (
        <React.Fragment>
            <Box direction="column" gap="large">
                <PageHeader title="Health Insurance" subTitle="a must need" />
                <Tabs selectedTabClassName="show">
                    <TabList className="nav nav-pills nav-border-anim nav-big justify-content-center mb-3">
                        {
                            switch_panel.length > 0 && switch_panel.map((item) =>
                                <Tab className="nav-item">
                                    <span className="nav-link">{item}</span>
                                </Tab>
                            )
                        }
                    </TabList>
                    <TabPanel>
                        <WWWH
                            about_data={healthi_about} />
                    </TabPanel>
                    <TabPanel>
                        <FF featfact_data={healthi_FeatFact}
                            feat_name="Features and Benefits of Health Insurance"
                            fact_name="Factors to consider before buying Health Insurance" />
                    </TabPanel>
                    <TabPanel>
                        <Types types_data={healthi_types}
                            name="Health Insurance Policy" />
                    </TabPanel>
                </Tabs>
            </Box>
        </React.Fragment>
    )
}