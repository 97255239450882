import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import BaseMenu from './base';

class MainMenuTwo extends BaseMenu {

    render() {
        const { demo } = this.props;
        return (
            <nav onClick={this.activeNav}>
                <ul className="menu">
                    {/* <li className="megamenu-container active">
                            <Link to={`${process.env.PUBLIC_URL}/demo-${demo}`} className="sf-with-ul">About US</Link>
                            <div className="megamenu demo">
                                    <div className="megamenu-action text-center">
                                        <Link to="#ViewAll" className="btn btn-outline-primary-2 view-all-demos" onClick={this.showAllDemos}><span>View All Demos</span><i className="icon-long-arrow-right"></i></Link>
                                    </div>
                            </div>
                        </li> */}
                    <li>
                        <Link to={`${process.env.PUBLIC_URL}/product/default/27`} className="sf-with-ul test">Learn</Link>
                    </li>

                    <li>
                        <Link to={`${process.env.PUBLIC_URL}/pages/about`} className="sf-with-ul">Plan</Link>
                    </li>

                    <li>
                        <Link to={`${process.env.PUBLIC_URL}/blog/classic`} className="sf-with-ul">Execute</Link>
                    </li>
                </ul>
            </nav>


        );
    }
}

export const mapStateToProps = (state) => ({
    demo: state.demo.current
})

export default connect(mapStateToProps)(MainMenuTwo);