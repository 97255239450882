import React, { useReducer, useEffect } from 'react';

import { Box, Diagram, Grommet, grommet, Stack, Text } from 'grommet-v2';
import { Diamond } from 'grommet-icons';
import { deepMerge } from 'grommet-v2/utils';

// import { data } from './data';

export const data = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21];
//export const data = ["Mutual Funds", "Based on their Structure", "Based on their Investment Objective", "Open-Ended Funds", "Close-Ended Funds", "Equity Funds", "Hybrid/Balanced Funds", "Debt Funds", "Sector Funds", "Thematic Funds" , "Foucused Funds", "Multi-cap Funds", "Dividend Funds", "Index Funds", "ELSS", "Credit Risk Fund", "Monthly Income Plan's", "Diversified Debt Funds", "Gilt Funds" , "Liqiud Funds", "Arbitrage Funds", "Aggressive Balanced Funds", "Dynamic Asset Allocation"];
export const items = ["Mutual Funds Types", "Based on Structure", "Based on Investment Objective", "Open-Ended Funds", "Close-Ended Funds", "Equity Funds", "Hybrid/Balanced Funds", "Debt Funds", "Sector Funds", "Thematic Funds", "Foucused Funds", "Multi-cap Funds", "Dividend Funds", "Index Funds", "Equity Linked Savings Scheme", "Credit Risk Fund", "Monthly Income Plan's", "Diversified Debt Funds", "Gilt Funds", "Liqiud Funds", "Arbitrage Funds", "Aggressive Balanced Funds", "Dynamic Asset Allocation"];


const customTheme = deepMerge(grommet, {
  diagram: {
    extend: `@keyframes
  example {
    to {
      stroke-dashoffset: 100;
    }
  }
  path {
    stroke-dasharray: 1050;
    stroke-dashoffset: 900;  //speed
    animation: example 4s linear forwards;
  }`,
  },
});

const connection = (fromTarget, toTarget, { ...rest } = {}) => ({
  fromTarget,
  toTarget,
  anchor: 'vertical',
  color: 'accent-4',
  thickness: 'xsmall',
  round: true,
  // type: 'curved',
  type: 'rectilinear',
  ...rest,
});

const DiamondContainer = ({ carat, color, cut, align, id, name, textSize }
) => (
    // Diamomd Component 
    <Box
      background="light-2"
      align={align || 'center'}
      alignSelf="center"
      direction="row"
      // gap="medium"
      key={id}
      elevation="xsmall"
    >
      <Box pad="none">
        <Diamond id={id} size="large" color="neutral-3" />
      </Box>
      <Box id={id} pad="small" background="light-1" align={align}>
        <Text size="16px" weight="500">{items[id]}</Text>
      </Box>
    </Box>
  );

const Container = ({ align, node, index }) => (
  <DiamondContainer
    carat={node.carat}
    color={node.color}
    cut={node.cut}
    id={index}
    key={node.name}
    name={node.name}
    textSize="small"
  />
);

export default function Connections() {
  const reducer = draw => !draw;

  const [draw, toogleDraw] = useReducer(reducer, true);

  useEffect(() => {
    const timer = setInterval(() => {
      toogleDraw();
    }, 2000);
    return () => clearInterval(timer);
  }, [toogleDraw]);

  const connections = [];

  if (draw) {
    connections.push(connection('0', '1', { anchor: 'vertical' }));
    connections.push(connection('0', '2', { anchor: 'vertical' }));

    connections.push(connection('1', '3', { anchor: 'vertical' }));
    connections.push(connection('1', '4', { anchor: 'vertical' }));

    connections.push(connection('2', '5', { anchor: 'vertical' }));
    connections.push(connection('2', '6', { anchor: 'vertical' }));
    connections.push(connection('2', '7', { anchor: 'vertical' }));

    connections.push(connection('5', '8', { anchor: 'vertical' }));
    connections.push(connection('5', '9', { anchor: 'vertical' }));
    connections.push(connection('5', '10', { anchor: 'vertical' }));
    connections.push(connection('5', '11', { anchor: 'vertical' }));
    connections.push(connection('5', '12', { anchor: 'vertical' }));
    connections.push(connection('5', '13', { anchor: 'vertical' }));
    connections.push(connection('5', '14', { anchor: 'vertical' }));

    connections.push(connection('6', '15', { anchor: 'vertical' }));
    connections.push(connection('6', '16', { anchor: 'vertical' }));
    connections.push(connection('6', '17', { anchor: 'vertical' }));
    connections.push(connection('6', '18', { anchor: 'vertical' }));
    connections.push(connection('6', '19', { anchor: 'vertical' }));
    connections.push(connection('6', '20', { anchor: 'vertical' }));

    connections.push(connection('7', '21', { anchor: 'vertical' }));
    connections.push(connection('7', '22', { anchor: 'vertical' }));

  }

  return (
    <Grommet theme={customTheme}>
      {/* <Box align="center" border margin="small" border={{ color: "status-critical"}}> */}
      {/* <Box border={{ color: "status-critical"}}>  */}
      {/* <Box pad="xlarge" border> */}
      <Stack>
        <Box alignSelf="center" justify="center" align="center" gap="xlarge" border={{ color: "status-ok" }}>
          <Box alignSelf="center" pad="small">
            {/* margin={{ bottom: 'large' }} */}
            <Container node={data[0]} index={0} />
            {/* <Box pad="small" /> */}
            {/* <Box
                  id="1"
                  width="xsmall"
                  // margin={{ bottom: 'large', top: 'xlarge' }}
                /> */}
          </Box>
          <Box justify="center" direction="row" gap="xlarge">
            {[1, 2].map(id => (
              <Container key={id} node={data[id - 1]} index={id} />
            ))}
          </Box>
          <Box justify="center" direction="row" gap="xlarge">
            {
              [3, 4, 5, 6, 7].map(id => (
                <Container key={id} node={data[id - 1]} index={id} />
              ))
            }
          </Box>
          <Box className="columnGroupStart" direction="row" gap="large" margin={{ left: "600px"}}>
          {/* align="start" justify="end"  */}
            <Box direction="column" gap="medium" border={{ color:"status-critical"}} align="start">
              {/* align="start" justify="start" */}
              {
                [8, 9, 10, 11, 12, 13, 14].map((id) => (
                  <Container key={id} node={data[id - 1]} index={id}/>
                ))
              }
            </Box>
            <Box direction="column" gap="medium" border={{ color:"status-ok"}} align="start">
              {
                [15, 16, 17, 18, 19, 20].map((id) => (
                  <Container key={id} node={data[id - 1]} index={id} />
                ))
              }
            </Box>
            <Box direction="column" gap="medium" border={{ color:"status-warning"}} align="start">
              {
                [21, 22].map((id) => (
                  <Container key={id} node={data[id - 1]} index={id} />
                ))
              }
            </Box>
          </Box>

        </Box>
        <Diagram connections={connections} />
      </Stack>
      {/* </Box> */}
      {/* </Box> */}
    </Grommet>
  );
};
